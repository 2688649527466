import React from "react";
import "./style.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col, Card } from "antd";

import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";

drilldown(Highcharts);
HighchartsExporting(Highcharts);


const ColumnChart = ({ title, y, dataList, drilldownData, widthVal, type, name, phase, setDrilDownReportDataVal }) => {

  var options = {
    chart: {
      type: "column",
      history: "100px",
      events: {
        beforePrint: function () {
          this.setSize(1000, 370, false);
          this.originalTitle = this.options.title.text;

          this.setTitle({ text: this.options.title.text + " - " + phase });
        },
        afterPrint: function () {
          this.setSize(widthVal < 600 && (widthVal - 100), 370, false);
          this.setTitle({ text: this.originalTitle });

        },
        fullscreenOpen: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: false
                }
              }
            },
            title: {
              align: "center",
              text: title + " - " + phase,
            }
          });

        },
        fullscreenClose: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: true
                }
              }
            },
            title: {
              align: "center",
              text: title,
            }
          });

        },
        drilldown: function (e) {
          setDrilDownReportDataVal(e?.seriesOptions?.name)

        },
        drillup: function (e) {
          setDrilDownReportDataVal(0)
        }
      },

    },

    title: {
      align: "center",
      text: title,
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },

    },
    xAxis: {
      type: "category",

    },
    yAxis: {
      min: 0,
      title: {
        text: y,
      },

    },

    legend: {
      enabled: false,
    },
    

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.id}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>',
    },

    series: [
      {
        name: name,
        colorByPoint: true,
        data: dataList,
      },
    ],
    drilldown: {


      breadcrumbs: {
        position: {
          align: "right",
          floating: true
        },
      },
      series: drilldownData,

    },

    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: type === "pie" ? "{point.y}" : "{point.y}",
        },
        point: {
          events: {
              click: function () {
                  // Check if the clicked point has a drilldown property
                  // console.log("iopp--->",this)
                  // if (this.drilldown) {
                  //     // The clicked point is a drilldown point
                  //     console.log('Clicked point is a drilldown point');
                  //     console.log('Drilldown ID:', this.drilldown);
                  // } else {
                  //     // The clicked point is not a drilldown point
                  //     console.log('Clicked point is not a drilldown point');
                  // }
              }
            
          }
      }
      },
    },
    credits: {
      enabled: false
    },

    exporting: {

      enabled: true,
      sourceWidth: 1000,
      chartOptions: {
        xAxis: [{
          max: null,


        }],
        chart: {
          events: {
            load: function () {
              const chart = this;
              const currentDate = new Date().toLocaleDateString('en-GB');
              chart.renderer.text(
                'Date: ' + currentDate,
                chart.plotWidth - 45,
                chart.plotTop - 10,
                false
              )
                .css({
                  color: '#000000',
                  fontSize: '12px',
                  textAlign: 'right'
                })
                .add();
            }
          }

        },
        title: {
          text: title + " - " + phase,
          align: 'center',
          y: 15
        },
      }
    }

  };
  return (
    <>
    { console.log("phase--->",phase)}
      <HighchartsReact
        highcharts={Highcharts} containerProps={{ style: { width: widthVal < 600 && (widthVal - 100), height: 350 } }} options={options} />
    </>
  );
};

export default ColumnChart;