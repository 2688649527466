import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";
import {
  HashRouter,
  Switch,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import Login from "../screens/login/index";

import Layout from "../screens/Layout";
import Dashboard from "../screens/Dashboard/index";
import ChangePassword from "../screens/changePassword/index";
import User from "../screens/user/index";
import UserDetails from "../screens/user/userDetails";
import Report from "../screens/Report/index";
import Message from "../screens/Message";
import SentMsg from "../screens/Message/sentMsg";
import DashboardInv from "../screens/Dashboard/dashboardInv";
import GeoTagMap from "../screens/Map/index";
import WaterMap from "../screens/Water/index";
import AdminList from "../screens/user/adminList";

const RootStack = () => {
  const isAuthed = useSelector((state) =>
    _.get(state, "AuthReducer.isAuthed", false)
  );

  const deptCouList = useSelector(
    (state) => _.get(state, "AppReducer.dataSet", []),
    shallowEqual
  );

  const userInfo = useSelector(
    (state) => _.get(state, "AuthReducer.userData", {}),
    shallowEqual
  );

  const routes = [
    { path: "/", component: <Login /> },
    { path: "/", component: <Dashboard /> },
    { path: "/changepassword", component: <ChangePassword /> },
    { path: "/user", component: <User /> },
    { path: "/userList", component: <UserDetails /> },
    { path: "/TNAU", component: <Report /> },
    { path: "/MessageReport", component: <Message /> },
    { path: "/Message", component: <SentMsg /> },
    { path: "/dashboard", component: <DashboardInv /> },
    {
      path: "/geoMap",
      component: <GeoTagMap count={deptCouList} userInfo={userInfo} />,
    },
    { path: "/adminlist", component: <AdminList /> },
    { path: "/water", component: <WaterMap /> },
  ];

  return <Layout routes={routes} isAuthed={isAuthed} />;
};

export default RootStack;
