import { Select } from "antd";
import { isEmpty } from "lodash";
import { useEffect } from "react";

const { Option } = Select;

const DateSelect = ({
  setDateVal,
  phase,
  subBasinRoles,
  dateVal,
  phaseValue,
}) => {
  const YearData = [];
  if (subBasinRoles && subBasinRoles == "true") {
    YearData.push(<Option value="0">Year</Option>);
  }
  for (
    var i = Number(phase) !== 0 ? Number(phase) - 1 + 2018 : 2018;
    subBasinRoles && subBasinRoles == "true"
      ? i < new Date().getFullYear()
      : i <= new Date().getFullYear();
    i++
  ) {
    if (subBasinRoles && subBasinRoles == "true") {
      YearData.push(
        <Option key={i} value={i}>
          {i}
        </Option>
      );
    } else {
      YearData.push(
        <Option key={i} value={i + "-01-01" + " and " + (i) + "-12-31"}>
          {i}
        </Option>
      );
    }
  }

  useEffect(() => {
    setDateVal(YearData[0].props.value);
  }, [phaseValue]);

  return (
    <div key={YearData[i]}>
      <Select
        style={{
          marginLeft: "10px",
        }}
        value={dateVal}
        onChange={(val) => setDateVal(val)}
      >
        {YearData}
      </Select>
    </div>
  );
};
export default DateSelect;
