import { Table, Breadcrumb, Spin, Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { getUserList, getAdminList } from "./apiAction";
import { TableSerach } from "../component/tableSearch";
import moment from "moment";
import {
  TbInfoSquare,
  TbTrash,
  TbEye,
  TbBrightness2,
  TbArrowBarToDown,
} from "react-icons/tb";
import { userList, userLists } from "../../actions/exportExcelAction";

const columns = [
  {
    title: "ID",
    dataIndex: "SNO",
    sorter: {
      compare: (a, b) => a.SNO - b.SNO,
    },
  },
  {
    title: "Created Date",
    dataIndex: "created_date",
    ...TableSerach("created_date"),
  },
  {
    title: "User Name",
    dataIndex: "username",
    ...TableSerach("username"),
  },
  {
    title: "User Type",
    dataIndex: "user_type",
    render: (value) => {
      if (value == "2") {
        return "Department Admin";
      } else if (value == "3") {
        return "Subbasin Admin";
      }
    },
  },
  {
    title: "Department",
    dataIndex: "department",
    filters: [
      {
        text: "AED",
        value: "AED",
      },
      {
        text: "Agriculture",
        value: "Agriculture",
      },
      {
        text: "Animal Husbandry",
        value: "Animal Husbandry",
      },
      {
        text: "Fisheries",
        value: "Fisheries",
      },
      {
        text: "Horticulture",
        value: "Horticulture",
      },
      {
        text: "Marketing",
        value: "Marketing",
      },
      {
        text: "MDPU",
        value: "MDPU",
      },
      {
        text: "TNAU",
        value: "TNAU",
      },
      {
        text: "WRD",
        value: "WRD",
      },
    ],
    onFilter: (value, record) =>
      record.department !== null && record.department.startsWith(value),
  },
];

const antIcon = <TbBrightness2 className="spinStyle" />;

const AdminList = () => {
  const [adminList, setAdminList] = useState([]);
  const [loadingTime, setLoadiingTime] = useState(false);

  const dispatch = useDispatch();

  const userInfo = useSelector((state) =>
    get(state, "AuthReducer.userData", [])
  );
  useEffect(() => {
    getAdminList(setAdminList, setLoadiingTime);
  }, []);

  return (
    <>
      <Spin
        tip="Fetching data ..."
        spinning={loadingTime}
        size="large"
        indicator={antIcon}
        style={{ color: "#673ab7", fontWeight: "bold" }}
      >
        <Breadcrumb
          separator="/"
          style={{ padding: "20px", fontSize: "large", fontWeight: "lighter" }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Admin User</Breadcrumb.Item>
        </Breadcrumb>
        <Button
          style={{ float: "right", margin: "5px", marginRight: "30px" }}
          disabled={adminList.length == 0 ? true : false}
          onClick={() => {
            userLists(adminList, "Admin");
          }}
        >
          <Tooltip title="Download Excel">
            <TbArrowBarToDown />
          </Tooltip>
        </Button>

        <Table
          style={{ margin: "20px" }}
          size={"small"}
          scroll={{ x: "80vw" }}
          columns={columns}
          dataSource={adminList}
          pagination={{ showSizeChanger: true, showQuickJumper: true }}
        />
      </Spin>
    </>
  );
};

export default AdminList;
