import { Select } from "antd";

const PhaseType = ({ setPhaseType, userInfo }) => {
  
  const phaseRoman = ["I", "II", "III", "IV"];
  const getOptionValue = () => {
    if (userInfo[0].user_type == "4" || userInfo[0].user_type == "3") {
      // <Option value={userInfo[0].phase}>
      //   Phase {phaseRoman[userInfo[0].phase - 1]}
      // </Option>
      return [
        {
          value: "0",
          label: "All Phases",
          className: "my-option",
        },
        {
          value: userInfo[0].phase,
          label: `Phase ${phaseRoman[userInfo[0].phase - 1]}`,
          className: "my-option",
        },
      ];
    } else {
      return [
        {
          value: "0",
          label: "All Phases",
          className: "my-option",
        },
        {
          value: "1",
          label: "Phase I",
          className: "my-option",
        },
        {
          value: "2",
          label: "Phase II",
          className: "my-option",
        },
        {
          value: "3",
          label: "Phase III",
          className: "my-option",
        },
        {
          value: "4",
          label: "Phase IV",
          className: "my-option",
        },
      ];

      //  } (
      //   <>
      //     <Option value="1">Phase I</Option>
      //     <Option value="2">Phase II</Option>
      //     <Option value="3">Phase III</Option>
      //     <Option value="4">Phase IV</Option>
      //   </>
      // )
    }
  };
  return (
    <>
    <Select
      labelInValue
      className="my-select"
      defaultValue={{
        value: "0",
        label: "All Phases",
      }}
      onChange={(val) => setPhaseType(val.value)}
      options={getOptionValue()}
    />
    </>
  );
};
export default PhaseType;
