import { Image, Layout, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "../../../index.css";

import { FaBars, FaRegShareSquare } from "react-icons/fa";
import { useDispatch } from "react-redux";

import images from "../../../constants/image";

import { setLogout } from "../../../actions/apiAction";
import "./style.css";
const { Header } = Layout;

const AppHeader = ({ setCollapsed, collapsedMenu }) => {
  const dispatch = useDispatch();

  const logoutAction = () => {
    dispatch(setLogout());
  };

  return (
    <>
      <Header
        className="header bgHeader customHeaterPadding"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="burggerButtonStyle">
          <span className="cusomMargin">
            {React.createElement(FaBars, {
              className: "trigger",
              onClick: () => setCollapsed(!collapsedMenu),
            })}
          </span>
          {window.innerWidth <= 600 && (
            <span className="cusomMargin">
              <Image
                src={images.ic_tniamplogo}
                width={"50px"}
                preview={false}
              />
              <span className="applicationName">TNIAMP</span>
            </span>
          )}
        </div>
        <div style={{ color: "white", fontWeight: "900" }}>
          TAMIL NADU IRRIGATED AGRICULTURE MODERNIZATION PROJECT
        </div>

        <div onClick={logoutAction} className="logoutIconStyle">
          <center>
            <Tooltip placement="top" title={"Logout"}>
              <strong>
                <Link className="logout-icon" to={"/"}>
                  <FaRegShareSquare />
                </Link>
              </strong>
            </Tooltip>
          </center>
        </div>
      </Header>
    </>
  );
};

export default AppHeader;
