import React from "react";
import { Provider } from "react-redux";
import createStore from "../store";
import RootStack from "./RootStack";

const { store, persistor } = createStore();

const navigator = () => {
  return (
    <Provider store={store}>
      <RootStack />
    </Provider>
  );
};

export default navigator;
