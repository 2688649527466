import React from "react";
import "./style.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";

drilldown(Highcharts);
HighchartsExporting(Highcharts);

const TargetAchievement = ({ widthVal, category, series }) => {
  var options = {
    chart: {
      type: "bar",
      events: {
        fullscreenOpen: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: false,
                },
              },
            },
          });
        },
        fullscreenClose: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: true,
                },
              },
            },
          });
        },
      },
    },
    title: {
      text: "Target Achievement",
      align: "center",
    },
    xAxis: {
      categories: category,
      title: {
        text: "District",
      },
      gridLineWidth: 1,
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Count",
      },
      labels: {
        overflow: "justify",
      },
      gridLineWidth: 0,
    },
    tooltip: {},
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          style: {
            textDecoration: "underline",
            color: "rgb(0 51 153)",
          },
          format: "{point.y}",
        },
        groupPadding: 0.1,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
    },
    credits: {
      enabled: false,
    },
    series: series,
    exporting: {
      enabled: true,
      sourceWidth: 1000,
      chartOptions: {
        xAxis: [
          {
            max: null,
          },
        ],
        chart: {
          events: {
            load: function () {
              const chart = this;
              const currentDate = new Date().toLocaleDateString("en-GB");
              chart.renderer
                .text(
                  "Date: " + currentDate,
                  chart.plotWidth - 20,
                  chart.plotTop - 10,
                  false
                )
                .css({
                  color: "#000000",
                  fontSize: "12px",
                  textAlign: "right",
                })
                .add();
            },
          },
        },
        // title: {
        //   text: title + "  " + phase,
        //   align: "center",
        //   y: 15,
        // },
      },
    },
  };
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{
          style: { width: widthVal < 600 && widthVal - 100, height: 370 },
        }}
        options={options}
      />
    </>
  );
};

export default TargetAchievement;
