import React from "react";
import { Drawer } from "antd";
import "./style.css";
import SideMenu from "./sidebar";
import { useSelector, shallowEqual } from "react-redux";
import { get, isEmpty } from "lodash";

const AppSider = ({ collapsedMenu, setCollapsed }) => {
  const onClose = () => {
    setCollapsed(!collapsedMenu);
  };

  const userInfo = useSelector(
    (state) => get(state, "AuthReducer.userData", []),
    shallowEqual
  );

  return (
    <>
      {window.innerWidth <= 600 ? (
        <Drawer
          placement={"left"}
          closable={false}
          onClose={onClose}
          open={!collapsedMenu}
          width={200}
          key={"left"}
        >
          <SideMenu
            collapsedMenu={!collapsedMenu}
            setCollapsed={setCollapsed}
            userInfo={userInfo}
          />
        </Drawer>
      ) : (
        <SideMenu
          collapsedMenu={collapsedMenu}
          setCollapsed={setCollapsed}
          userInfo={userInfo}
        />
      )}
    </>
  );
};

export default AppSider;
