import { Select } from "antd";
import { isEmpty } from "lodash";

const { Option } = Select;

const DepartmentType = ({ setDepartmentId, departmentList }) => {
  return (
    <div>
      <Select
        style={{
          width: 127,
        }}
        defaultValue={{
          value: "2",
          label: "Agriculture",
        }}
        onChange={(val) => setDepartmentId(val)}
      >
        {!isEmpty(departmentList) &&
          departmentList.map((data, key) => (
            <Option key={key} value={data.ID}>
              {data.NAME}
            </Option>
          ))}
      </Select>
    </div>
  );
};
export default DepartmentType;
