import React from "react";
import "./style.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Col, Card } from "antd";

import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";

drilldown(Highcharts);
HighchartsExporting(Highcharts);

  const deptlabel = {
    1:"TNAU",
    2:"Agriculture",
    3:"Horticulture",
    4:"AED",
    5:"Animal Husbandry",
    6:"WRD",
    7:"Marketing",
    8:"Fisheries",
  }


const ColumnChart = ({ title, y, drilldownData, widthVal, type, phase }) => {

  var options = {
    chart: {
      type: "column",
      events: {
        beforePrint: function () {
          this.setSize(1000, 350, false);
          this.originalTitle = this.options.title.text;

                    this.setTitle({ text: this.options.title.text+ " - " + phase });
        },
        afterPrint: function () {
          this.setSize(widthVal < 600 && (widthVal - 100), 350, false);
          this.setTitle({ text: this.originalTitle });
        },
        fullscreenOpen: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: false
                }
              }
            },
            title: {
              align: "center",
              text: title + " - " + phase,
            },
          });

        },
        fullscreenClose: function () {
          this.setTitle({ text: this.options.title.text.split("-")[0] })
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: true
                }
              },
              title: {
                align: "center",
                text: title,
              },
            }
          });

        },
      },
    },

    title: {
      align: "center",
      text: title,
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },

    },
    xAxis: {
      type: "category",

    },
    yAxis: {
      min: 0,
      title: {
        text: y,
      },

    },

    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
          pointPadding: 0,
          borderWidth: 2
      },
      series: {
      dataLabels: {
        enabled: true,
        format:  "{point.y}",
        style: {
          textDecoration: "underline",
          color: "rgb(0 51 153)",
        },
      },
      },
  },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.id}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>',
    },

    series: drilldownData,
   
    credits: {
      enabled: false
    },

    exporting: {

      enabled: true,
      sourceWidth: "1000",
      chartOptions: {
        xAxis: [{
          max: null,
        }],
        chart: {
          events: {
            load: function () {
              const chart = this; 
              const currentDate = new Date().toLocaleDateString('en-GB');
                chart.renderer.text(
                'Date: ' + currentDate,
                chart.plotWidth - 45,
                chart.plotTop - 10, 
                false
              )
              .css({
                color: '#000000',
                fontSize: '12px',
                textAlign: 'right'
              })
              .add();
            }
          }
         
        },
        title: {
          text: title +" - " + phase,
          align: 'center',
          y: 15
        },
      }
    }

  };
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts} containerProps={{ style: { width: widthVal < 600 && (widthVal - 100), height: 350 } }} options={options} />
    </>
  );
};

export default ColumnChart;