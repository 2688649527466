import React from "react";
import "./style.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";

drilldown(Highcharts);
HighchartsExporting(Highcharts);

const DoubleChart = ({
  title,
  y,
  dataList,
  drilldownData,
  widthVal,
  type,
  phase,
}) => {
  var options = {
    chart: {
      type: "column",
      events: {
        beforePrint: function () {
          this.setSize(1000, 350, false);
          this.originalTitle = this.options.title.text;

          this.setTitle({ text: this.options.title.text + " - " + phase });
        },
        afterPrint: function () {
          this.setSize(widthVal < 600 && widthVal - 100, 350, false);
          this.setTitle({ text: this.originalTitle });
        },
        fullscreenOpen: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: false,
                },
              },
            },
          });
        },
        fullscreenClose: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: true,
                },
              },
            },
          });
        },
      },
    },

    title: {
      align: "center",
      text: title,
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },

    xAxis: {
      type: "category",
    },

    yAxis: {
      min: 0,
      title: {
        text: y,
      },
    },

    legend: {
      enabled: false,
    },

    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: type === "pie" ? "{point.y}" : "{point.y}",
        },
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>',
    },

    series: [
      {
        // name: "Intervention / Component Counts",
        name: "Geotagged Counts",
        colorByPoint: true,
        data: dataList,
      },
    ],

    drilldown: {
      breadcrumbs: {
        position: {
          align: "right",
          floating: true,
        },
      },

      series: drilldownData,
    },

    credits: {
      enabled: false,
    },

    exporting: {
      enabled: true,
      sourceWidth: 1000,
      chartOptions: {
        xAxis: [
          {
            max: null,
          },
        ],
        chart: {
          events: {
            load: function () {
              const chart = this;
              const currentDate = new Date().toLocaleDateString("en-GB");
              chart.renderer
                .text(
                  "Date: " + currentDate,
                  chart.plotWidth - 40,
                  chart.plotTop - 10,
                  false
                )
                .css({
                  color: "#000000",
                  fontSize: "12px",
                  textAlign: "right",
                })
                .add();
            },
          },
        },
        title: {
          text: title + " - " + phase,
          align: "center",
          y: 15,
        },
      },
    },
  };
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{
          style: { width: widthVal < 600 && widthVal - 100, height: 350 },
        }}
        options={options}
      />
    </>
  );
};

export default DoubleChart;
