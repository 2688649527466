import React, { useState } from "react";
import { Slider } from "antd";

const MonthSlider = ({ setMonth }) => {
  const months = [" ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " "];
  const marks = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);

  const handleSliderChange = (value) => {
    setSelectedMonthIndex(value);
    setMonth(String(value + 1).padStart(2, "0"));
  };

  return (
    <div
      style={{
        color: "white",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ marginRight: 10 }}>Month</div>
      <div style={{ width: "100%" }}>
        <Slider
          min={0}
          max={11}
          defaultValue={0}
          value={selectedMonthIndex}
          onChange={handleSliderChange}
          tipFormatter={(value) => marks[value + 1]}
          marks={months.reduce((marks, month, index) => {
            marks[index] = month;
            return marks;
          }, {})}
        />
      </div>
    </div>
  );
};

export default MonthSlider;
