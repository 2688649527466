import axios from "axios";
import api from "../../constants/api";
import { message } from "antd";
import { ceil, round } from "lodash";

export function getReportDataList(
  setReportData,
  apiData,
  setLoadiingTime,
  type,
  apiType,
  count
) {
  setReportData([]);
  var apiLimits = ceil(count / 3000);

  var endpoints = [];

  for (var i = 0; i <= apiLimits; i++) {
    endpoints.push(
      api.tnauReort +
        apiType +
        `?land_id=${apiData.land_id}&created_by=${
          apiData.created_by
        }&phase_id=${apiData.phase_id}&sub_basin_id=${
          apiData.sub_basin_id
        }&district_id=${apiData.district_id}&block_id=${
          apiData.block_id
        }&village_id=${apiData.village_id}&components_id=${
          apiData.components_id
        }&fromdate=${apiData.fromdate}&todate=${
          apiData.todate
        }&sub_components_id=${apiData.sub_components_id}&stages_id=${
          apiData.stages_id
        }&off_set=${i * 3000}`
    );
  }

  setLoadiingTime(true);
  return axios
    .all(endpoints.map((endpoint) => axios.get(endpoint)))
    .then((data) => {
      var dataList = [];

      for (var j = 0; j < data?.length; j++) {
        dataList.push(data[j].data);
      }

      const res = [];
      dataList.forEach((element) => {
        element.forEach((el) => {
          res.push(el);
        });
      });
      setLoadiingTime(false);
      {console.log("report data--->",res.findIndex((val) => val.user_name == null))}
      setReportData(res?.reverse());
    })
    .catch((error) => {
      setLoadiingTime(false);
      setReportData([]);
      message.error("Something went wrong please try again");
    });
}

export function deleteGeoTag(detId, selectedRowKeys, deleteoption, setDelete, handleCancel) {
  return axios
    .post(api.deleteGeoTag, {
      dept_id: detId,
      data_id: selectedRowKeys.toString(),
    })
    .then(({ data }) => {
      console.log("detId--->",detId);
      message.success("successful delete the record");
      setDelete(!deleteoption);
      handleCancel();
      // setTimeout(window.location.reload(false), 5000);
    })

    .catch((error) => {
      // message.error("Something went wrong please try again");
    });
}

export function getdistrict(setdistrictData) {
  return axios
    .get(api.lookupApi + "?type=district")
    .then(({ data }) => {
      setdistrictData(data);
    })
    .catch((error) => {
      // message.error("Something went wrong please try again");
    });
}

export function getBasinData(setBasinList) {
  return axios
    .get(api.lookupApi + "?type=sub_basin")
    .then(({ data }) => {
      setBasinList(data);
    })
    .catch((error) => {
      // message.error("Something went wrong please try again");
    });
}

export function getdistrictData(setDistrictList, id) {
  return axios
    .get(api.subBasin + "?sub_basin_id=" + id)
    .then(({ data }) => {
      setDistrictList(data);
    })
    .catch((error) => {
      // message.error("Something went wrong please try again");
    });
}

export function getImage(setImg1, setImg2, Id, dept) {
  let endpoints = [
    api.getImg1 + `?dept_id=${dept}&data_id=` + Id,
    api.getImg2 + `?dept_id=${dept}&data_id=` + Id,
  ];

  return axios
    .all(endpoints.map((endpoint) => axios.get(endpoint)))
    .then((dataList) => {
      const img1 = dataList[0].data[0]["to_base64(image1)"];
      const img2 = dataList[1].data[0]["to_base64(image2)"];
      setImg1(img1);
      setImg2(img2);
    })
    .catch((error) => {
      // message.error("Something went wrong please try again");
    });
}

export function getDepartmentUsers(departmentId, setUserList) {
  return axios
    .get(api.departmentUser + "?user_id=0&dept_id=" + departmentId)
    .then(({ data }) => {
      setUserList(data);
    })
    .catch((error) => {
      // message.error("Something went wrong please try again");
    });
}

export function getDepartmentComponents(
  type,
  setComponentList,
  componentData,
  componentList = [],
  componentinitial
) {
  return axios
    .get(api.lookupApi + "?type=" + type)
    .then(({ data }) => {
      componentData && componentList.length == 0 && componentData(data);
      componentinitial && componentinitial(data);
      setComponentList(data);
    })
    .catch((error) => {
      // message.error("Something went wrong please try again");
    });
}
