import { Button, Input, Modal, Form } from "antd";
import { useState } from "react";
import { putMsg, sendMail, sendMessage } from "./apiAction";
const { TextArea } = Input;

const AddMsg = ({ handleCancel, isMsgOen, keys }) => {
  const onFinish = (values) => {
    const time =
      new Date().toISOString().split("T")[0] +
      " " +
      new Date().toISOString().split("T")[1].substr(0, 12);
    const apiData1 = {
      user_ids: keys.SNO,
      ack_time: time,
      created_time: time,
      is_ack: 0,
      message_data: values.Message,
      created_by: "admin",
    };
    const apiData = {
      // phone: keys[0].MOBILE.toString(),
    phone: keys.map((val) => val.MOBILE).join(), 
      message: values.Message.toString(),
    };
    putMsg(apiData, handleCancel,sendMessage, apiData1);
    keys.map((value) => {
      sendMail(value.EMAIL,values.Message)
    })
    
    // sendMessage(apiData1);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal
        title={<center style={{ margin: "10px" }}>Message</center>}
        width={500}
        open={isMsgOen}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="Message"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your Message!",
              },
            ]}
          >
            <TextArea
              rows={6}
              style={{ marginTop: "10px" }}
              placeholder="Message"
            />
          </Form.Item>
          <Form.Item>
            <center>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "5px", marginBottom: "5px" }}
              >
                Send
              </Button>
            </center>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default AddMsg;
