import React, { useEffect, useState } from "react";
import "./style.css";
import { Row, Col, Card, Spin, Tooltip, Button, Select } from "antd";
import {
  getDashboardByUsersCount,
  GeoTagDashboard,
  getDashboardByGeoTagCount,
  getDashbordCount,
  getBeneficaryDetails,
  excelbeneficiary,
  excelGeotag,
  workCompletedexcel,
  targetAchievement,
  interventionbyvillageApi,
} from "./apiAction";
import { handleDownloadPDF } from "./pdffuction";

import { getUserList } from "../user/apiAction";
import { getDepartment } from "../login/apiAction";

import { TbBrightness2 } from "react-icons/tb";
import { FiLayers, FiServer, FiUsers } from "react-icons/fi";
import { FaRegFileExcel, FaRegUser, FaRegPaperPlane } from "react-icons/fa";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { get, isEmpty, unset } from "lodash";

import ColumnChart from "./columnChart";
import SingleColumn from "./singleColumn";

import BarChart from "./barChart";
import LineChart from "./lineChart";
import PhaseType from "./phsaeType";
import DepartmentType from "./departmentType";
import Images from "../../constants/image";
import DateSelect from "./dateSelect";
import DashboardCount from "./dashboradCount";
import CommonDashboard from "./CommonDashboard";
import ColumnChartBeneficary from "./columnChartBeneficary";
import { exportExcel } from "../../actions/exportExcelAction";
import { registerExcal } from "../../actions/registerExcal";
import TargetAchievement from "./targetAchievement.jsChart";
import { getDepartmentComponents } from "../Report/apiAction";

var count = "0";
var Geocount = "0";

const Dashboard = () => {
  const antIcon = <TbBrightness2 className="spinStyle" />;

  const phasevalue = {
    0: "All Phases",
    1: "Phase I",
    2: "Phase II",
    3: "Phase III",
    4: "Phase IV",
  };
  let componentType = {
    0: "inv_agri",
    1: "inv_tnau",
    2: "inv_agri",
    3: "inv_horti",
    4: "inv_aed",
    5: "inv_husb",
    6: "inv_wrd",
    7: "inv_marketing",
    8: "inv_fishery",
  };

  const categories = ["Africa", "America", "Asia", "Europe", "Oceania"];
  const barChartData = [
    {
      name: "Year 1990",
      data: [631, 727, 3202, 721, 26],
    },
    {
      name: "Year 2000",
      data: [814, 841, 3714, 726, 31],
    },
    {
      name: "Year 2010",
      data: [1044, 944, 4170, 735, 40],
    },
    {
      name: "Year 2018",
      data: [1276, 1007, 4561, 746, 42],
    },
  ];

  const userInfo = useSelector((state) =>
    get(state, "AuthReducer.userData", [])
  );

  var drilDownReportFlag = userInfo[0].line_dept;
  var drilDownRegister = 0;

  const [userCountData, setUserCountData] = useState([]);
  const [userCountDrillDown, setCountDrilDown] = useState([]);
  const [geoCountData, setGeoCountData] = useState([]);
  const [geoCountDrillDown, setGeoDrilDown] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [WorkProcessData, setWorkProcessData] = useState([]);
  const [dashboardCount, setDashboardCount] = useState([]);
  const [beneficaryData, setBeneficaryData] = useState([]);

  const [departmentId, setgeoTagId] = useState("2");

  const [phaseType, setPhaseType] = useState("0");
  const [phaseTypeGeoTag, setGeoTag] = useState("0");
  const [phaseGeoTag, setPhaseGeoTag] = useState("0");
  const [phaseBeneficary, setBeneficaryPhase] = useState("0");
  const [deptBeneficary, setDeptBeneficary] = useState(
    userInfo[0].line_dept == 0 ? "2" : userInfo[0].line_dept
  );

  const [loadingTime, setLoadiingTime] = useState(false);
  const [loadingTimeInitial, setLoadiingTimeInitial] = useState(false);

  const [dateVal, setDateRange] = useState("2018-04-01 and 2019-3-31");
  const [dateVal1, setDate1] = useState("2018-04-01");
  const [dateVal2, setDate2] = useState("2019-3-31");
  const [workProcess, setWorkprocess] = useState(false);
  const [userList, setUserList] = useState([]);
  const [targetCategory, setTargetcat] = useState([]);
  const [targetSeries, setTargetseries] = useState([]);
  const [componentData, setComponentData] = useState([]);
  const [targetdept, setTargetDept] = useState(2);
  const [componentDesign, setComponentDesign] = useState("All Components");
  const [changeValuecomponent, setChangeValuecomponent] = useState("0");
  const [targetDate, setTargetDateVal] = useState("2023-04-01 and 2024-3-31");
  const [targetData, setTargetdata] = useState([])

  const selectedKeys = [
    "SNO",
    "created_date",
    "department",
    "username",
    "EMAIL",
    "MOBILE",
    "sub_basin",
    "district_name",
    "phase",
    "Dept Id",
  ];

  const dispatch = useDispatch();

  const graphChange = () => {
    console.log("userlist--->",userList)
    if(userList.length > 0) {
    registerExcal(
      userList.map(({ dept_id, ...rest }) => ({ ...rest })),
      selectedKeys,
      phaseType,
      userInfo,
      drilDownRegister
    );
    }
  };

  const PdfDownload = () => {
    handleDownloadPDF(userList, selectedKeys);
  };
  useEffect(() => {
    getUserList(setUserList, setLoadiingTime, userInfo);
    setgeoTagId(userInfo[0].line_dept == 0 ? 2 : userInfo[0].line_dept);
    setTargetDept(userInfo[0].line_dept == 0 ? 2 : userInfo[0].line_dept);
    setTargetDateVal("2023-04-01 and 2024-3-31");
  }, []);

  useEffect(() => {
    getDepartmentComponents(
      componentType[targetdept],
      setComponentData,
      "",
      "",
      componentinitial
    );
  }, [targetdept]);

  useEffect(() => {}, [componentDesign]);

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      getDashboardByUsersCount(
        setUserCountData,
        setCountDrilDown,
        phaseType,
        setLoadiingTime,
        userInfo[0].line_dept
      );
      getDepartment(setDepartmentList);
      count = phaseType;
    }
  }, [phaseType, userInfo]);

  useEffect(() => {
    getDashbordCount(userInfo[0].line_dept, setDashboardCount, setLoadiingTimeInitial);
    getDepartmentComponents(
      componentType[userInfo[0].line_dept],
      setComponentData,
      "",
      "",
      componentinitial
    );
  }, [userInfo]);

  useEffect(() => {
    targetAchievement(
      setTargetcat,
      setTargetseries,
      targetdept,
      changeValuecomponent,
      targetDate,
      setLoadiingTime,
      setTargetdata
    );
  }, [targetdept, changeValuecomponent]);
  useEffect(() => {
    targetAchievement(
      setTargetcat,
      setTargetseries,
      targetdept,
      changeValuecomponent,
      targetDate,
      setLoadiingTime
    );
  }, [targetDate]);

  const componentinitial = (componentData) => {
    const firstele = componentData.filter(
      (filterList) =>
        filterList.PARENT_ID === 0 || filterList.PARENT_ID === null
    );
    componentData.length > 0 && setComponentDesign(firstele[0].Name);
    setChangeValuecomponent(firstele[0].ID);
  };

  const setDepartmentId = (data) => {
    setgeoTagId(data);
    setWorkprocess(!workProcess);
  };

  const setDateVal = (data) => {
    setDateRange(data);
    setWorkprocess(!workProcess);
  };

  const setPhaseTypeGeoTag = (data) => {
    setGeoTag(data);
    setWorkprocess(!workProcess);
  };

  const setDrilDownReportFlagVal = (e) => {
    drilDownReportFlag = e;
  };

  const setDrilldownRegister = (e) => {
    drilDownRegister = e;
  };

  useEffect(() => {
    if (!isEmpty(userInfo) && userInfo[0].line_dept !== 0) {
      GeoTagDashboard(
        setWorkProcessData,
        phaseTypeGeoTag,
        userInfo[0].line_dept,
        dateVal.split("and")[0],
        dateVal.split("and")[1],
        "0",
        setLoadiingTime
      );
      dispatch(getDepartment(setDepartmentList));
    } else {
      GeoTagDashboard(
        setWorkProcessData,
        phaseTypeGeoTag,
        departmentId,
        dateVal.split("and")[0],
        dateVal.split("and")[1],
        "0",
        setLoadiingTime
      );
      dispatch(getDepartment(setDepartmentList));
    }
  }, [workProcess, userInfo]);

  useEffect(() => {
    dispatch(
      getDashboardByGeoTagCount(
        setGeoCountData,
        setGeoDrilDown,
        phaseGeoTag,
        setLoadiingTime,
        userInfo[0].line_dept
      )
    );
    Geocount = phaseGeoTag;
  }, [phaseGeoTag, userInfo]);

  useEffect(() => {
    getBeneficaryDetails(
      setBeneficaryData,
      deptBeneficary,
      phaseBeneficary,
      "0",
      setLoadiingTime
    );
  }, [deptBeneficary, phaseBeneficary]);

  const excelDownloadgeotag = () => {
    excelGeotag(drilDownReportFlag, phaseGeoTag, "0");
  };

  const excelDownloadbeneficiary = () => {
    excelbeneficiary(deptBeneficary, phaseBeneficary, "0");
  };

  const handleComponent = (componentData) => {
    const a = [
      ...new Map(
        componentData
          .filter(
            (filterList) =>
              filterList.PARENT_ID === 0 || filterList.PARENT_ID === null
          )
          .map((data) => {
            return [data["Name"], data];
          })
      ).values(),
    ].map((data, key) => (
      <Option key={key} value={data.Name}>
        {data.Name}
      </Option>
    ));
    return a;
  };

  return (
    <>
      <div>
        <center>
          <Spin
            tip="Fetching data ..."
            spinning={loadingTimeInitial ? loadingTimeInitial : loadingTime}
            size="large"
            indicator={antIcon}
            style={{ color: "#673ab7", fontWeight: "bold", position: "fixed" }}
          >
            <DashboardCount dashboardCount={dashboardCount} />
            {/* <Row style={{ paddingTop: "60px" }}> */}
              {/* <Col md={24}>
                <Card style={{ margin: "15px" }}>
                  <div
                    style={{
                      height: window.innerWidth < 600 ? "" : "400px",
                      width: window.innerWidth < 600 ? "" : "950px",
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Col span={6}>
                        <PhaseType setPhaseType={setPhaseGeoTag} />
                      </Col>

                      <Col span={3}>
                        <img
                          src={"/Excel.png"}
                          alt="Excel"
                          className="excel-design"
                          onClick={() => excelDownloadgeotag()}
                        />
                      </Col>
                    </Row>

                    <div style={{ marginTop: "20px" }}>
                      {!isEmpty(userInfo) &&
                        phaseGeoTag === Geocount &&
                        userInfo[0].line_dept === 0 && (
                          <ColumnChart
                            span={6}
                            title={"Geotagged Counts"}
                            y={"No. of Geotagged counts"}
                            dataList={geoCountData}
                            drilldownData={geoCountDrillDown}
                            widthVal={window.innerWidth}
                            type="pie"
                            name="Geotag Counts"
                            phase={phasevalue[phaseGeoTag]}
                            setDrilDownReportDataVal={(w) =>
                              setDrilDownReportFlagVal(w)
                            }
                          />
                        )}
                      {!isEmpty(userInfo) &&
                        phaseGeoTag === Geocount &&
                        userInfo[0].line_dept !== 0 && (
                          <SingleColumn
                            span={6}
                            title={"Geotagged Counts"}
                            y={"No. of Geotagged counts"}
                            drilldownData={geoCountDrillDown}
                            widthVal={window.innerWidth}
                            type="column"
                            phase={phasevalue[phaseGeoTag]}
                          />
                        )}
                    </div>
                  </div>
                </Card>
              </Col> */}
            {/* </Row> */}
            <Row style={{ paddingTop: "1em" }}>
              <Col md={12}>
                <Card style={{ margin: "15px" }}>
                  <div
                    style={{
                      height: window.innerWidth < 600 ? "" : "400px",
                      width: window.innerWidth < 600 ? "" : "450px",
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Col span={6}>
                        <PhaseType setPhaseType={setPhaseType} userInfo={userInfo} />
                      </Col>

                      <Col span={3}>
                        <img
                          src={"/Excel.png"}
                          alt="Excel"
                          className="excel-design"
                          onClick={() => graphChange()}
                        />
                      </Col>
                    </Row>

                    <div style={{ marginTop: "20px" }}>
                      {!isEmpty(userInfo) &&
                        phaseType === count &&
                        userInfo[0].line_dept === 0 && (
                          <ColumnChart
                            span={6}
                            title={"Registered Users"}
                            y={"No. of Registered Users"}
                            dataList={userCountData}
                            drilldownData={userCountDrillDown}
                            widthVal={window.innerWidth}
                            type="column"
                            name="User counts"
                            phase={phasevalue[phaseType]}
                            setDrilDownReportDataVal={(w) =>
                              setDrilldownRegister(w)
                            }
                          />
                        )}
                      {!isEmpty(userInfo) &&
                        phaseType === count &&
                        userInfo[0].line_dept !== 0 && (
                          <SingleColumn
                            span={6}
                            title={"Registered Users"}
                            y={"No. of Registered Users "}
                            drilldownData={userCountDrillDown}
                            widthVal={window.innerWidth}
                            type="column"
                            phase={phasevalue[phaseType]}
                          />
                        )}
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={12}>
                <Card style={{ margin: "15px" }}>
                  <div
                    style={{
                      height: window.innerWidth < 600 ? "" : "400px",
                      width: window.innerWidth < 600 ? "" : "450px",
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Col span={6}>
                        <PhaseType setPhaseType={setPhaseGeoTag} userInfo={userInfo} />
                      </Col>

                      <Col span={3}>
                        <img
                          src={"/Excel.png"}
                          alt="Excel"
                          className="excel-design"
                          onClick={() => excelDownloadgeotag()}
                        />
                      </Col>
                    </Row>

                    <div style={{ marginTop: "20px" }}>
                      {!isEmpty(userInfo) &&
                        phaseGeoTag === Geocount &&
                        userInfo[0].line_dept === 0 && (
                          <ColumnChart
                            span={6}
                            title={"Geotagged Counts"}
                            y={"No. of Geotagged counts"}
                            dataList={geoCountData}
                            drilldownData={geoCountDrillDown}
                            widthVal={window.innerWidth}
                            type="pie"
                            name="Geotag Counts"
                            phase={phasevalue[phaseGeoTag]}
                            setDrilDownReportDataVal={(w) =>
                              setDrilDownReportFlagVal(w)
                            }
                          />
                        )}
                      {!isEmpty(userInfo) &&
                        phaseGeoTag === Geocount &&
                        userInfo[0].line_dept !== 0 && (
                          <SingleColumn
                            span={6}
                            title={"Geotagged Counts"}
                            y={"No. of Geotagged counts"}
                            drilldownData={geoCountDrillDown}
                            widthVal={window.innerWidth}
                            type="column"
                            phase={phasevalue[phaseGeoTag]}
                          />
                        )}
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
            <Col md={12}>
                <Card
                  style={{
                    margin: "15px",
                  }}
                >
                  <div
                    style={{
                      height: window.innerWidth < 600 ? "" : "400px",
                      width: window.innerWidth < 600 ? "" : "450px",
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Col span={6}>
                        <PhaseType setPhaseType={setPhaseTypeGeoTag} userInfo={userInfo} />
                      </Col>

                      {!isEmpty(userInfo) && userInfo[0].line_dept === 0 && (
                        <Col span={6}>
                          <div style={{ marginLeft: "10px" }}>
                            <DepartmentType
                              departmentList={departmentList.filter((value) => {
                                if (value.NAME != "MDPU") return value;
                              })}
                              setDepartmentId={setDepartmentId}
                            />{" "}
                          </div>
                        </Col>
                      )}

                      <Col span={6}>
                        <div style={{ marginLeft: "20px" }}>
                          <DateSelect
                            setDateVal={setDateVal}
                            phase={phaseTypeGeoTag}
                            dateVal={dateVal}
                            phaseValue={phaseTypeGeoTag}
                          />
                        </div>
                      </Col>

                      <Col span={3}>
                        <div style={{}}>
                          <img
                            src={"/Excel.png"}
                            alt="Excel"
                            className="excel-design"
                            onClick={() =>
                              workCompletedexcel(
                                departmentId,
                                phaseTypeGeoTag,
                                dateVal
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>

                    <div style={{ marginTop: "20px" }}>
                      <LineChart
                        span={6}
                        title={"Work completed/progress"}
                        y={"No. of interventions"}
                        seriesData={WorkProcessData}
                        widthVal={window.innerWidth}
                        phase={phasevalue[phaseTypeGeoTag]}
                        year={dateVal.split("-")[0]}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={12}>
                <Card style={{ margin: "15px" }}>
                  <div
                    style={{
                      height: window.innerWidth < 600 ? "" : "400px",
                      width: window.innerWidth < 600 ? "" : "450px",
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Col span={6}>
                        <div style={{ marginRight: "30px" }}>
                          <PhaseType setPhaseType={setBeneficaryPhase} userInfo={userInfo} />
                        </div>
                      </Col>

                      {!isEmpty(userInfo) && userInfo[0].line_dept === 0 && (
                        <Col span={6}>
                          <div style={{ marginLeft: "10px" }}>
                            <DepartmentType
                              departmentList={departmentList.filter((e) => {
                                if (
                                  e.NAME != "WRD" &&
                                  e.NAME != "Marketing" &&
                                  e.NAME != "MDPU"
                                ) {
                                  return e;
                                }
                              })}
                              setDepartmentId={setDeptBeneficary}
                            />
                          </div>
                        </Col>
                      )}

                      <Col span={5}>
                        <img
                          src={"/Excel.png"}
                          alt="Excel"
                          className="excel-design"
                          onClick={() => excelDownloadbeneficiary()}
                        />
                      </Col>
                    </Row>
                    <div style={{ marginTop: "20px" }}>
                      <ColumnChartBeneficary
                        title={"Beneficiary details"}
                        dataList={beneficaryData}
                        widthVal={window.innerWidth}
                        phase={phasevalue[phaseBeneficary]}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
              {/* <Col md={12}>
                <Card style={{ margin: "15px" }}>
                  <div
                    style={{
                      height: window.innerWidth < 600 ? "" : "400px",
                      width: window.innerWidth < 600 ? "" : "450px",
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {!isEmpty(userInfo) && userInfo[0].line_dept === 0 && (
                        <Col span={6}>
                          <div style={{ marginLeft: "10px" }}>
                            <DepartmentType
                              departmentList={departmentList.filter((value) => {
                                if (value.NAME != "MDPU") return value;
                              })}
                              setDepartmentId={setTargetDept}
                            />
                          </div>
                        </Col>
                      )}
                      <Col span={3}></Col>
                      <Col span={6} name="Component">
                        <Select
                          value={componentDesign}
                          onChange={(value) => {
                            setComponentDesign(value);
                            let chan = componentData.find((item) => {
                              return item.Name === value;
                            });
                            if (chan.ID != undefined)
                              setChangeValuecomponent(chan.ID);
                            else setChangeValuecomponent(0);
                          }}
                          style={{
                            width: window.innerWidth <= 600 ? "80vw" : "10vw",
                          }}
                        >
                          {componentData.length != 0 &&
                            handleComponent(componentData)}
                        </Select>
                      </Col>
                      <Col span={6}>
                        <div style={{ marginLeft: "20px" }}>
                          <DateSelect
                            setDateVal={setTargetDateVal}
                            phase={0}
                            dateVal={targetDate}
                          />
                        </div>
                      </Col>
                      <Col span={3}>
                        <img
                          src={"/Excel.png"}
                          alt="Excel"
                          className="excel-design"
                          onClick={() => exportExcel(targetData,targetdept)}
                        />
                      </Col>
                    </Row>
                    <div style={{ marginTop: "20px" }}>
                      <TargetAchievement
                        widthVal={window.innerWidth}
                        category={targetCategory}
                        series={targetSeries}
                      />
                    </div>
                  </div>
                </Card>
              </Col> */}
            </Row>
          </Spin>
        </center>
      </div>
    </>
  );
};

export default Dashboard;
