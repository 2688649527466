import React, { useState } from "react";
import { Modal } from "antd";

export function DeleteAlert({ open, onOk, onCancel }) {
  return (
    <>
      <Modal
        title="Confirm the deleted report"
        style={{
          top: 20,
        }}
        okButtonProps={{ className: "reportOkDelete" }}
        cancelButtonProps={{ className: "reportCancelDelete" }}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
      ></Modal>
    </>
  );
}
