import React from 'react';
import { Image } from "antd";

export const Images = ({ img }) => {

    return (
        <>
            <Image
                src={"data:image/png;base64," + img}
                width={100}
                height={70}
            />
        </>
    )
}