import { Table, Breadcrumb, Spin, Button, Tooltip, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { changeDepartment, getUserList } from "./apiAction";
import { TableSerach } from "../component/tableSearch";
import moment from "moment";
import {
  TbInfoSquare,
  TbTrash,
  TbEye,
  TbBrightness2,
  TbArrowBarToDown,
} from "react-icons/tb";
import { userLists } from "../../actions/exportExcelAction";
import { EditTwoTone } from "@ant-design/icons";
import { getDepartment } from "../login/apiAction";

const antIcon = <TbBrightness2 className="spinStyle" />;

const UserDetails = () => {
  const [userList, setUserList] = useState([]);
  const [loadingTime, setLoadiingTime] = useState(false);
  const [department, setDepartment] = useState([]);
  const [deptID, setDeptID] = useState(2);
  const [isEdit, setEdit] = useState(false);
  const [userId, setUserID] = useState();

  const dispatch = useDispatch();

  const userInfo = useSelector((state) =>
    get(state, "AuthReducer.userData", [])
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "SNO",
      sorter: {
        compare: (a, b) => a.SNO - b.SNO,
      },
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      ...TableSerach("created_date"),
    },
    {
      title: "User Name",
      dataIndex: "username",
      ...TableSerach("username"),
    },
    {
      title: "Department",
      dataIndex: "department",
      filters: [
        {
          text: "AED",
          value: "AED",
        },
        {
          text: "Agriculture",
          value: "Agriculture",
        },
        {
          text: "Animal Husbandry",
          value: "Animal Husbandry",
        },
        {
          text: "Fisheries",
          value: "Fisheries",
        },
        {
          text: "Horticulture",
          value: "Horticulture",
        },
        {
          text: "Marketing",
          value: "Marketing",
        },
        {
          text: "MDPU",
          value: "MDPU",
        },
        {
          text: "TNAU",
          value: "TNAU",
        },
        {
          text: "WRD",
          value: "WRD",
        },
      ],
      onFilter: (value, record) => record.department.startsWith(value),
    },
    {
      title: "Subbasin",
      dataIndex: "sub_basin",
      ...TableSerach("sub_basin"),
    },
    {
      title: "Action",
      width: "10%",
      render: (c) => {
        return (
          <>
            <EditTwoTone
              onClick={() => {
                setDeptID(c.dept_id);
                setUserID(c.SNO);
                setEdit(true);
              }}
            />{" "}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    getUserList(setUserList, setLoadiingTime, userInfo);
    dispatch(getDepartment(setDepartment));
  }, []);
  return (
    <>
      <Spin
        tip="Fetching data ..."
        spinning={loadingTime}
        size="large"
        indicator={antIcon}
        style={{ color: "#673ab7", fontWeight: "bold" }}
      >
        <Breadcrumb
          separator="/"
          style={{ padding: "20px", fontSize: "large", fontWeight: "lighter" }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Individual User</Breadcrumb.Item>
        </Breadcrumb>

        <Button
          style={{ float: "right", margin: "5px", marginRight: "30px" }}
          disabled={userList.length == 0 ? true : false}
          onClick={() => {
            userLists(userList, "User");
          }}
        >
          <Tooltip title="Download Excel">
            <TbArrowBarToDown />
          </Tooltip>
        </Button>

        <Table
          style={{ margin: "20px" }}
          size={"small"}
          scroll={{ x: "80vw" }}
          columns={columns}
          dataSource={userList}
          pagination={{ showSizeChanger: true, showQuickJumper: true }}
        />
      </Spin>
      <Modal
        open={isEdit}
        title="Change Department"
        onOk={() => {
          changeDepartment(
            deptID,
            userId,
            getUserList,
            setUserList,
            setLoadiingTime,
            userInfo
          );
          setEdit(false);
        }}
        onCancel={() => {
          setEdit(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <h4>Department : </h4>
          <Select
            style={{
              width: 160,
            }}
            value={deptID}
            onChange={(val) => setDeptID(val)}
          >
            {department.length > 0 &&
              department.map((data, key) => (
                <Option key={key} value={data.ID}>
                  {data.NAME}
                </Option>
              ))}
          </Select>
        </div>
      </Modal>
    </>
  );
};

export default UserDetails;
