import React, { useEffect, useState } from "react";
import { writeFile, utils } from 'xlsx';
import "./style.css";
import { Row, Col, Card, Spin, Typography, Divider, Select } from "antd";
import { TbBrightness2 } from "react-icons/tb";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { isEmpty } from "lodash";
import PieChart from "./PieChart";
import PhaseType from "./phsaeType";
import DateSelect from "./dateSelect";
import {
  getdistrict,
  getDepartmentComponents,
  getReportDataList,
  getBasinData,
} from "../Report/apiAction";
import {
  getSubbasinUserId,
  getSubbasinComponent,
  getSubbasinComponentStages,
  getDashboardByUsersCount,
  getSubbasinWorkprogress,
  getSubbasinBeneficary,
  getBeneficaryDetails,
  GeoTagDashboard,
  excelbeneficiary,
  workCompletedexcel,
  interventionbyvillageApi,
  getDashbordCount,
} from "./apiAction";
import { getDepartment } from "../login/apiAction";
import ColumnChart from "./columnChart";
import SingleColumn from "./singleColumn";
import DoubleChart from "./DoubleChart";
import FilterDashboard from "./FilterDashboard";
import ColumnChartBeneficary from "./columnChartBeneficary";
import LineChart from "./lineChart";
import DepartmentType from "./departmentType";
import { exportExcel } from "../../actions/exportExcelAction";
import VariablepieChart from "./variablepie";
import DashboardCount from "./dashboradCount";

const { Title } = Typography;
const { Option } = Select;
let componentType = {
  1: "inv_tnau",
  2: "inv_agri",
  3: "inv_horti",
  4: "inv_aed",
  5: "inv_husb",
  6: "inv_wrd",
  7: "inv_marketing",
  8: "inv_fishery",
};

var count = "0";
let subBasinId;

// margin-bottom: 0.5em; */
//     color: rgba(0, 0, 0, 0.88);
//     font-weight: 600;
//     font-size: 16px;
//     line-height: 1.5;

const CommonDashboard = ({ setReportData, reportType, userInfo }) => {
  const antIcon = <TbBrightness2 className="spinStyle" />;

  const [basinData, setBasinData] = useState([]);
  const [districtData, setdistrictData] = useState([]);
  const [componentData, setComponentData] = useState([]);
  const [componentDesign, setComponentDesign] = useState("All Components");
  const [subBasinData, setSubBasinData] = useState([]);
  const [subbasinComponentData, setSubbasinComponentData] = useState([]);
  const [subbasinComponentStagesData, setSubbasinComponenStagestData] =
    useState([]);
  const [phaseType, setPhaseType] = useState("0"); // 1 graph phase
  const [phaseSelect, SetPhaseSelect] = useState("0"); // 2 graph phase
  const [dateVal, setDateVal] = useState("0");
  const [workProcessdate, setWorkProcessdate] = useState(
    "2018-04-01 and 2019-3-31"
  );
  //const [changeValue,setChangevalue] = useState(["All Subbasin","All Component"]);
  const [changeValuesubbasin, setChangeValuesubbasin] =
    useState("All Subbasin");
  const [changeValuecomponent, setChangeValuecomponent] = useState("0");
  const [userCountData, setUserCountData] = useState([]);
  const [userCountDrillDown, setCountDrilDown] = useState([]);
  const [loadingTime, setLoadiingTime] = useState(false);
  const [phaseSubbasinBeneficary, setBeneficaryPhase] = useState("0");
  const [beneficaryData, setBeneficaryData] = useState([]);
  const [phaseGeoTag, setPhaseGeoTag] = useState("0");
  const [phaseWorkprogress, setGeoTag] = useState("0");
  const [WorkProcessData, setWorkProcessData] = useState([]);
  const [workprogressDeptid, setWorkprogressDepartmentId] = useState("2");
  const [deptidSubbasinBeneficary, setDeptBeneficary] = useState("2");
  const [departmentList, setDepartmentList] = useState([]);

  const [phaseVillage, setPhaseVillage] = useState("0");
  const [villageCount, setVillageCount] = useState([]);
  const [dashboardCount, setDashboardCount] = useState([]);

  const dispatch = useDispatch();

  subBasinId = userInfo[0].district;
  const filterBasedPharse = (data) => {
    setPhaseType(data);
  };
  const phasevalue = {
    0: "All Phases",
    1: "Phase I",
    2: "Phase II",
    3: "Phase III",
    4: "Phase IV",
  };

  // component data set graph
  useEffect(() => {
    getBasinData(setBasinData);
    getDepartmentComponents(
      componentType[userInfo[0].line_dept],
      setComponentData
    );
    getDashbordCount(
      userInfo[0].line_dept,
      setDashboardCount,
      setLoadiingTime,
      userInfo[0].ID
    );
  }, []);

  useEffect(() => {
    getSubbasinUserId(
      setSubBasinData,
      userInfo[0].ID,
      phaseType,
      dateVal,
      userInfo[0].line_dept,
      setLoadiingTime
    );
  }, [phaseType, dateVal]);

  // 2 Graph
  useEffect(() => {
    dispatch(
      getSubbasinComponent(
        setSubbasinComponentData,
        userInfo[0].line_dept,
        changeValuecomponent,
        phaseSelect,
        userInfo[0].ID,
        setLoadiingTime
      )
    );
  }, [changeValuecomponent, phaseSelect]);

  useEffect(() => {
    GeoTagDashboard(
      setWorkProcessData,
      phaseWorkprogress,
      userInfo[0].line_dept,
      workProcessdate.split("and")[0],
      workProcessdate.split("and")[1],
      userInfo[0].ID,
      setLoadiingTime
    );
  }, [phaseWorkprogress, workprogressDeptid, workProcessdate]);

  // 4 graph

  useEffect(() => {
    getBeneficaryDetails(
      setBeneficaryData,
      userInfo[0].line_dept,
      phaseSubbasinBeneficary,
      userInfo[0].ID,
      setLoadiingTime
    );
  }, [phaseSubbasinBeneficary]);

  useEffect(() => {
    getdistrict(setdistrictData);
    dispatch(getDepartment(setDepartmentList));
  }, []);

  const subbasinDownloadexcel = () => {
    excelbeneficiary(
      userInfo[0].line_dept,
      phaseSubbasinBeneficary,
      userInfo[0].ID,
      setLoadiingTime
    );
  };

  const excelDownloadgeotag = () => {
    // Transform the data
    const transformedData = subbasinComponentData.map((item) => ({
      "Subcomponent name": item.name,
      "Count": item.y,
    }));

    // Create a worksheet
  const ws = utils.json_to_sheet(transformedData);

  // Create a workbook
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Subbasin Component Data');

  // Function to trigger the download
    const fileName = 'subbasin_component_data.xlsx';
    writeFile(wb, fileName);
  };

  return (
    <div>
      <center>
        <Spin
          tip="Fetching data ..."
          spinning={loadingTime}
          size="large"
          indicator={antIcon}
          style={{ color: "#673ab7", fontWeight: "bold" }}
        >
          <DashboardCount dashboardCount={dashboardCount} />
          <Row style={{ paddingTop: "1em" }}>
            <Col md={12}>
              <Card style={{ margin: "15px" }}>
                <div
                  style={{
                    height: window.innerWidth < 600 ? "" : "400px",
                    width: window.innerWidth < 600 ? "" : "450px",
                  }}
                >
                  <Row
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Col span={6}>
                      <PhaseType setPhaseType={setPhaseType} userInfo ={userInfo} />
                    </Col>

                    <Col span={4}>
                      <DateSelect
                        setDateVal={setDateVal}
                        phase={phaseType}
                        subBasinRoles={"true"}
                        dateVal={dateVal}
                        phaseValue={phaseType}
                      />
                    </Col>
                    <Col span={3}>
                      <img
                        src={"/Excel.png"}
                        alt="Excel"
                        className="excel-design"
                        onClick={() => {
                          subBasinData.length > 0 && exportExcel(subBasinData, userInfo[0].line_dept)
                        }
                        }
                      />
                    </Col>
                  </Row>

                  <div style={{ marginTop: "20px" }}>
                    <PieChart
                      // title={"District Users"}
                      title={"Registered Users"}
                      dataList={subBasinData}
                      widthVal={window.innerWidth}
                      type="pie"
                      phase={phasevalue[phaseType]}
                    />
                  </div>
                </div>
              </Card>
            </Col>

            <Col md={12}>
              <Card style={{ margin: "15px" }}>
                <div
                  style={{
                    height: window.innerWidth < 600 ? "" : "400px",
                    width: window.innerWidth < 600 ? "" : "500px",
                  }}
                >
                  <Row
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Col span={6}>
                      <PhaseType setPhaseType={SetPhaseSelect} userInfo={userInfo}/>
                    </Col>

                    <Col span={6} name="Component">
                      <Select
                        placeholder="All Component"
                        value={componentDesign}
                        onChange={(value) => {
                          setComponentDesign(value);
                          let chan = componentData.find((item) => {
                            return item.Name === value;
                          });
                          chan == undefined
                            ? setChangeValuecomponent("0")
                            : setChangeValuecomponent(chan.ID);
                        }}
                        style={{
                          width: window.innerWidth <= 600 ? "80vw" : "10vw",
                        }}
                      >
                        <Option value={"All Components"} style={{ width: 150 }}>
                          {"All Components"}
                        </Option>
                        {componentData.length != 0 &&
                          [
                            ...new Map(
                              componentData
                                .filter(
                                  (filterList) =>
                                    filterList.PARENT_ID === 0 ||
                                    filterList.PARENT_ID === null
                                )
                                .map((data) => [data["Name"], data])
                            ).values(),
                          ].map((data, key) => (
                            <Option key={key} value={data.Name}>
                              {data.Name}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                    <Col span={3}>
                      <img
                        src={"/Excel.png"}
                        alt="Excel"
                        className="excel-design"
                        onClick={() => subbasinComponentData.length > 0 && excelDownloadgeotag(subbasinComponentData)}
                      />
                    </Col>
                  </Row>
                  <div style={{ marginTop: "20px" }}>
                    <DoubleChart
                      // title={"Intervention / Component Count"}
                      title={"Geotagged Counts"}
                      // y={"Count"}
                      y={"No. of geotagged counts"}
                      dataList={subbasinComponentData}
                      widthVal={window.innerWidth}
                      type="column"
                      phase={phasevalue[phaseSelect]}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card style={{ margin: "15px" }}>
                <div
                  style={{
                    height: window.innerWidth < 600 ? "" : "400px",
                    width: window.innerWidth < 600 ? "" : "450px",
                  }}
                >
                  <Row
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Col span={6}>
                      <PhaseType setPhaseType={setGeoTag} userInfo={userInfo}/>
                    </Col>

                    <Col span={6}>
                      <DateSelect
                        setDateVal={setWorkProcessdate}
                        phase={phaseWorkprogress}
                        dateVal={workProcessdate}
                        phaseValue={phaseWorkprogress}
                      />
                    </Col>

                    <Col span={3} disabled={true}>
                      <img
                        src={"/Excel.png"}
                        alt="Excel"
                        className="excel-design"
                        onClick={() =>
                          WorkProcessData.length != 0 &&
                          workCompletedexcel(
                            userInfo[0].line_dept,
                            phaseWorkprogress,
                            workProcessdate,
                            userInfo[0].ID
                          )
                        }
                      />
                    </Col>
                  </Row>

                  <div style={{ marginTop: "20px" }}>
                    <LineChart
                      title={"Work completed/progress"}
                      y={"No. of interventions"}
                      seriesData={WorkProcessData}
                      widthVal={window.innerWidth}
                      phase={phasevalue[phaseWorkprogress]}
                      year = {workProcessdate.split("-")[0]}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col md={12}>
              <Card style={{ margin: "15px" }}>
                <div
                  style={{
                    height: window.innerWidth < 600 ? "" : "400px",
                    width: window.innerWidth < 600 ? "" : "450px",
                  }}
                >
                  <Row
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Col span={6}>
                      <PhaseType setPhaseType={setBeneficaryPhase} userInfo={userInfo}/>
                    </Col>
                    <Col span={3}>
                      <img
                        src={"/Excel.png"}
                        alt="Excel"
                        className="excel-design"
                        onClick={() => subbasinDownloadexcel()}
                      />
                    </Col>
                  </Row>
                  <div style={{ marginTop: "20px" }}>
                    <ColumnChartBeneficary
                      title={"Beneficiary details"}
                      dataList={beneficaryData}
                      widthVal={window.innerWidth}
                      phase={phasevalue[phaseSubbasinBeneficary]}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Spin>
      </center>
    </div>
  );
};

export default CommonDashboard;
