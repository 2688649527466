import axios from "axios";
import api from "../../constants/api";
import { message } from "antd";

export function getDepartment(setDeartmentList) {
  return async (dispatch) => {
    return await axios
      .get(api.lookupApi, { params: { type: "department" } })
      .then(({ data }) => {
        setDeartmentList(data);
      })
      .catch((error) => {
        // message.error('Something went wrong please try again');
      });
  };
}

export function getDepartmentUser(setDeartmentUserList, id) {
  return async (dispatch) => {
    return await axios
      .get(api.departmentUser, {
        params: {
          user_id: 0,
          dept_id: id,
          sub_basin_id: 0,
        },
      })
      .then(({ data }) => {
        setDeartmentUserList(data);
      })
      .catch((error) => {
        // message.error('Something went wrong please try again');
      });
  };
}

export function loginApi(userData) {
  return async (dispatch) => {
    return await axios
      .post(api.login, userData)
      .then(({ data }) => {
        data[0].department = userData.department;

        if (data[0]?.line_dept === 9) {
          data[0].is_admin = 1;
          data[0].line_dept = 0;
          data[0].user_name = data[0].NAME;
          data[0].user_type = "1";
          data[0].SERIAL_NO = "0"
          sessionStorage.setItem("ald", 0);
        } else {
          sessionStorage.setItem("ald", data[0].line_dept);
        }


        const userDataList = data.concat({ password: userData.password });



        dispatch({ type: "isLoggedin" });
        sessionStorage.setItem("art", data[0].user_type);

        sessionStorage.setItem("Sid", data[0].SUB_BASIN_ID);
        sessionStorage.setItem("IUid", data[0].SERIAL_NO);

        dispatch({ type: "SET_USER_DATA", data: userDataList });
        message.success("You have successfully logged In");
      })

      .catch((error) => {
        // message.error("User Not Found");
        message.open({

          type: "error",

          key: "loginerr",

          maxCount: 1,

          content: error.response.data,

        });
      });
  };
}
