import React, { useState } from "react";

import { Layout } from "antd";
import { get } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import ContentPage from "./layout/content";
import AppHeader from "./layout/header";
import AppSider from "./layout/sidebar";
import Login from "./login";

import { HashRouter } from "react-router-dom";

const Layoutpage = ({ routes, isAuthed }) => {
  const [collapsed, setCollapsed] = useState(true);

  const userInfo = useSelector(
    (state) => get(state, "AuthReducer.userData", {}),
    shallowEqual
  );

  return (
    <div>
      {isAuthed ? (
        <HashRouter>
          <Layout className="dashboard-height">
            <AppSider
              userInfo={userInfo}
              collapsedMenu={collapsed}
              setCollapsed={setCollapsed}
            />
            <Layout className="site-layout">
              <AppHeader
                setCollapsed={setCollapsed}
                collapsedMenu={collapsed}
                style={{ position: "fixed" }}
              />
              <ContentPage routes={routes} isAuthed={isAuthed} />
            </Layout>
          </Layout>
        </HashRouter>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default Layoutpage;
