import { includes } from "lodash";
import { writeFile, utils } from "xlsx";
let enum_dept = {
  1: "TNAU",
  2: "Agriculture",
  3: "Horticulture",
  4: "AED",
  5: "Animal Husbandry",
  6: "WRD",
  7: "Marketing",
  8: "Fisheries",
};
let enumheading = {
  Department: "Department",
  block_name: "Block Name",
  category: "Category",
  count: "Count",
  district_name: "District Name",
  gender: "Gender",
  phase: "Phase",
  sub_basin_name: "Subbasin Name",
  village_name: "Village Name",
  EMAIL: "Email",
  MOBILE: "Mobile",
  SNO: "ID",
  created_date: "Created date",
  sub_basin: "Subbasin Name",
  department: "Department",
  user_type: "User type",
  username: "User Name",
  // village: "Village Name",
  name: "District Name",
  y: "Count",
  ID: "ID",
  Stages: "Stages",
  Sub_Component: "Sub Component",
  area: "Area",
  component: "Component",
  farmer_name: "Farmer Name",
  photo_lat: "Photo Lat",
  photo_lon: "Photo Lon",
  remarks: "Remarks",
  // status: "Status",
  survey_no: "Survey No",
  tank_name: "Tank Name",
  user_mobile_number: "User Mobile No.",
  yield: "Yield",
  variety: "Variety",
  user_name: "Username",
  no_of_calves: "No.of Calves",
  no_of_cows: "No.of Cows",
  no_of_farmers: "No.of Farmers",
  others_female_no: "Female Others No.",
  others_male_no: "Male Others No.",
  sc_st_female_no: "Female SC/ST No.",
  sc_st_male_no: "Male SC/ST No.",
  venue: "Venue",
  Latitude: "Latitude",
  Longitude: "Longitude",
  Name_of_WUA: "Name of WUA",
  No_of_Members: "No.of Members",
  Sluice_Number: "Sluice Number",
  block: "Block",
  length_m: "Length m",
  Mobile_Number: "Mobile Number",
  created_by: "Created By",
  Capacity_Mts: "Capacity Mts",
  Category_of_Godown: "Category of Godown",
  Contribution: "Contribution",
  Country_and_State_Name: "Country and State Name",
  Date_From: "Date From",
  Date_To: "Date To",
  Date_of_Completion: "Date of Completion",
  Duration_of_Visit: "Duration of Visit",
  Duration_of_training: "Duration of training",
  Email_Id: "Email Id",
  Government_share: "Government Share",
  Infrastructure_available: "Infrastructure Available",
  Location_of_the_Consulting_agency: "Location of The Consulting Agency",
  Name_HQandFPC: "Name HQandFPC",
  Name_of_the_consulting_agency: "Name of the Consulting Agency",
  Name_of_training: "Name of Training",
  No_of_beneficieries: "No of Beneficieries",
  Number_of_traineesm: "Number of Traineesm",
  Numbers: "Numbers",
  Places_visited: "Places Visited",
  Quantity_stored_Mts: "Quantity Stored Mts",
  crop: "Crop",
  mobile: "Mobile No. ",
  name_of_ceo: "Name of CEO",
  no_of_village: "No.of Village",
  nof_mem: "No.of Members",
  nooffigs: "No.of Figs",
  season: "Season",
  Remarks: "Remarks",
  Category: "Category",
  Farm_pond_constructed_by: "Farm Pond Constructed By",
  Feed_Quantity: "Feed Quantity",
  Name_of_the_tank: "Name of The Tank",
  Quanity_of_fish_harvested_Kg: "Quantity of Fish Harvested Kg",
  Water_spread_area_EWSAHa: "Water Spread Area EWSAHa",
  beneficiary: "Beneficiary",
  beneficiary_name: "Beneficiary Name",
  block_name: "Block Name",
  harvested: "Harvested",
  lessee: "Lessee",
  no_of_seed: "No.of Seed",
  nodal_officer: "Nodal Officer",
  species_stoked: "Species Stoked",
  mobile_number: "Mobile Number",
  ack_time: "Acknowledged Time",
  message: "Message",
  acknowledge: "Acknowledge",
  user_id: "User ID",
  staf_id: "Staff ID",
  User_name: "User name",
  sub_basein_name: "Subbasin",
  comp_name: "Component",
  sub_comp_name: "Sub Component",
  stage_names: "Stage",
  tag_count: "Count",
  s_no: "ID",
  month: "Month",
  date: "Date",
  phase_id: "Phase",
  Area: "Area",
  // Village: "Village",
  // createdBy: "CreatedBy",
  // updatedBy: "UpdatedBy",
  // updatedDate: "UpdatedDate",
  // updated_by: "Updated_by",
  // updated_date: "Updated_date",
  lat: "Latitude",
  lon: "Longitude",
  txn_id: "Transaction id",
  txn_date: "Transaction date",
  // photo1: "Photo1",
  // photo2: "Photo2",
  // createdDate: "CreatedDate",
  intervention_type: "Intervention Type",
  other_intervention: "Other Intervention",
  quantity_procured: "Quantity Procured",
  seed_area: "Seed Area",
  group_name: "Group Name",
  date_account_open: "Date Account Open",
  date_revolving_fund_release: "Date Revolving Fund Release",
  fish_culture: "Fish Culture",
  categorys: "Categorys",
  female_no1: "Female No",
  male_no1: "Male No",
  ls_point: "ls point",
  category2: "Category",
  female_no2: "Female No",
  fpc_name: "FPC Name",
  incorp_date: "Incorp Date",
  incorporation_date: "Incorporation Date",
  incorporation_num: "Incorporation Num",
  male_no2: "Male No",
  names: "Names",
  nof_female: "No. of Female",
  nof_male: "No. of Male",
  nof_village: "No. of Village",
  remarks1: "Remarks",
  serial_no: "Serial No",
  nof_category: "No. of Category",
  Categorys: "Categorys",
  dept_id: "Dept id",
  target: "Target",
  training_name: "Training Name",
  sub_stages: "Sub Stages",
  volume_of_water: "Volume of Water",
  no_of_stocks_req: "NO. of Stocks Required",
  revenue_genrator: "Revenue Genrator",
  tr_exp_categoty: "Tr Exp Categoty",
  tr_exp_female_no: "Tr Exp Female No.",
  tr_exp_male_no: "Tr Exp Male NO.",
  participatant_no: "participatant_no",
};
export function exportExcel(data, detId) {
  let worksheet;
  worksheet = utils.json_to_sheet(
    data.map((row) => {
      return Object.fromEntries(
        Object.entries(row).filter(
          ([key, value]) =>
            key != "dept_id" &&
            key != "photo1" &&
            key != "photo2" &&
            key != "createdDate" &&
            key != "createdBy" &&
            key != "updatedBy" &&
            key != "updatedDate" &&
            key != "updated_by" &&
            key != "updated_date" &&
            key != "status" &&
            key != "created_by" &&
            key != "txn_id" &&
            key != "categorys" &&
            key != "female_no1" &&
            key != "male_no1" &&
            key != "village" &&
            key != "date" &&
            key != "lat" &&
            key != "lon" &&
            key != "category2" &&
            key != "female_no2" &&
            key != "male_no2" &&
            key != "names" &&
            key != "remarks1" &&
            key != "category2" &&
            key != "Categorys" &&
            key != "txn_date" 
        )
      );
    })
  );

  const columnlength = Object.keys(data[0]).includes(
    "dept_id",
    "photo1",
    "photo2",
    "createdDate",
    "createdBy",
    "updatedBy",
    "updatedDate",
    "updated_by",
    "updated_date",
    "status",
    "created_by",
    "txn_date",
    "txn_id",
    "categorys",
    "female_no1",
    "male_no1",
    "village",
    "date",
    "lon",
    "lat",
    "category2",
    "female_no2",
    "male_no2",
    "names",
    "remarks1",
    "category2",
    "Categorys"
  )
    ? Object.keys(data[0]).length - 27
    : Object.keys(data[0]).length-1;

  for (let i = 0; i < columnlength; i++) {
    let cell;
    if (i < 26) {
      cell = worksheet[`${String.fromCharCode(65 + i)}1`];
      worksheet[`${String.fromCharCode(65 + i)}1`].v =
        enumheading[worksheet[`${String.fromCharCode(65 + i)}1`].v];
    } else if (i < 52) {
      cell =
        worksheet[
          `${String.fromCharCode(65)}${String.fromCharCode(65 + (i - 26))}1`
        ];
      worksheet[
        `${String.fromCharCode(65)}${String.fromCharCode(65 + (i - 26))}1`
      ].v =
        enumheading[
          worksheet[
            `${String.fromCharCode(65)}${String.fromCharCode(65 + (i - 26))}1`
          ].v
        ];
    } else if (i < 78) {
      cell =
        worksheet[
          `${String.fromCharCode(66)}${String.fromCharCode(65 + (i - 52))}1`
        ];
      worksheet[
        `${String.fromCharCode(66)}${String.fromCharCode(65 + (i - 52))}1`
      ].v =
        enumheading[
          worksheet[
            `${String.fromCharCode(66)}${String.fromCharCode(65 + (i - 52))}1`
          ]?.v
        ];
    } else {
      cell =
        worksheet[
          `${String.fromCharCode(67)}${String.fromCharCode(65 + (i - 78))}1`
        ];
      worksheet[
        `${String.fromCharCode(67)}${String.fromCharCode(65 + (i - 78))}1`
      ].v =
        enumheading[
          worksheet[
            `${String.fromCharCode(67)}${String.fromCharCode(65 + (i - 78))}1`
          ]?.v
        ];
    }

    cell.fill = {
      type: "pattern",
      patternType: "solid",
      fgColor: { rgb: "FFFF00" },
    };
  }
  for (let i = 0; i < columnlength; i++) {
    if (worksheet[utils.encode_cell({ c: i, r: 0 })]?.v) {
      const columnWidth =
        worksheet[utils.encode_cell({ c: i, r: 0 })].v.toString().length;
      worksheet[utils.encode_cell({ c: i, r: 0 })].s = {
        alignment: { wrapText: true },
        font: { sz: 12, bold: true },
        fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
      };
      worksheet["!cols"] = worksheet["!cols"] || [];
      worksheet["!cols"][i] = { wch: columnWidth + 5 };
    }
  }
  const workbook = utils.book_new();

  detId == ""
    ? utils.book_append_sheet(workbook, worksheet, "Tank")
    : utils.book_append_sheet(workbook, worksheet, enum_dept[detId]);
  writeFile(workbook, "Report.xlsx");
}

export function multipleSheetExcel(data) {
  let worksheet = utils.json_to_sheet(
    data.map((row) => {
      return Object.fromEntries(
        Object.entries(row).filter(([key, value]) => key != "dept_id")
      );
    })
  );
  const columnlength = Object.keys(data[0]).length;
  for (let i = 0; i < columnlength; i++) {
    if (worksheet[utils.encode_cell({ c: i, r: 0 })]?.v) {
      const columnWidth =
        worksheet[utils.encode_cell({ c: i, r: 0 })].v.toString().length;
      worksheet[utils.encode_cell({ c: i, r: 0 })].s = {
        alignment: { wrapText: true },
        font: { sz: 12, bold: true },
        fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
      };
      worksheet["!cols"] = worksheet["!cols"] || [];
      worksheet["!cols"][i] = { wch: columnWidth + 2 };
    }
  }
  const workbook = utils.book_new();
  data.forEach((sheet) => {
    const worksheet = utils.aoa_to_sheet(sheet.data);
    utils.book_append_sheet(workbook, worksheet, sheet.name);
  });
  writeFile(workbook, "Report.xlsx");
}

export const userLists = (data, sheetName) => {
  if (sheetName == "Message") {
    data.forEach((val) => {
      val.department = enum_dept[val.department];
      val.user_type =
        val.user_type == "4"
          ? "Individual"
          : val.user_type == "3"
          ? "Subbasin Admin"
          : val.user_type == "2"
          ? "Department Admin"
          : "";
    });
  } else if (sheetName == "Admin") {
    data.forEach(
      (val) =>
        (val.user_type =
          val.user_type == "2" ? "Department Admin" : "Subbasin Admin")
    );
  }

  let worksheet = utils.json_to_sheet(
    data.map((row) => {
      return Object.fromEntries(
        Object.entries(row).filter(([key, value]) => key != "dept_id")
      );
    })
  );
  const columnlength = Object.keys(data[0]).includes("dept_id")
    ? Object.keys(data[0]).length - 1
    : Object.keys(data[0]).length;
  for (let i = 0; i < columnlength; i++) {
    let cell = worksheet[`${String.fromCharCode(65 + i)}1`];
    worksheet[`${String.fromCharCode(65 + i)}1`].v =
      enumheading[worksheet[`${String.fromCharCode(65 + i)}1`].v];
    if (worksheet[utils.encode_cell({ c: i, r: 0 })]?.v) {
      const columnWidth =
        worksheet[utils.encode_cell({ c: i, r: 0 })].v.toString().length;
      worksheet[utils.encode_cell({ c: i, r: 0 })].s = {
        alignment: { wrapText: true },
        font: { sz: 12, bold: true },
        fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
      };
      worksheet["!cols"] = worksheet["!cols"] || [];
      worksheet["!cols"][i] = { wch: columnWidth + 5 };
    }
  }
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, sheetName);
  writeFile(workbook, sheetName + "details.xlsx");
};
