import React from "react";
import "./style.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";

drilldown(Highcharts);
HighchartsExporting(Highcharts);

const BarChart = ({ title, y, seriesData, widthVal, phase, year }) => {
  const options = {
    chart: {
      events: {
        beforePrint: function () {
          this.setSize(1000, 370, false);
          this.originalTitle = this.options.title.text;

          this.setTitle({ text: this.options.title.text + " - " + phase + " - " +year });
        },
        afterPrint: function () {
          this.setSize(widthVal < 600 && widthVal - 100, 370, false);
          this.setTitle({ text: this.originalTitle });
        },
        fullscreenOpen: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: false,
                },
              },
            },
            title: {
              text: title + " - " + phase + " - " +year,
              align: "center",
            },
          });
        },
        fullscreenClose: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: true,
                },
              },
            },
            title: {
              text: title,
              align: "center",
            },
          });
        },
      },
    },
    title: {
      text: title,
      align: "center",
    },

    yAxis: {
      title: {
        text: y,
      },
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },

    series: seriesData,
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    exporting: {
      enabled: true,
      sourceWidth: 1000,
      chartOptions: {
        xAxis: [
          {
            max: null,
          },
        ],
        chart: {
          events: {
            load: function () {
              const chart = this;
              const currentDate = new Date().toLocaleDateString("en-GB");
              chart.renderer
                .text(
                  "Date: " + currentDate,
                  chart.plotWidth - 45,
                  chart.plotTop - 10,
                  false
                )
                .css({
                  color: "#000000",
                  fontSize: "12px",
                  textAlign: "right",
                })
                .add();
            },
          },
        },
        title: {
          text: title + " - " + phase + " - " +year,
          align: "center",
          y: 15,
        },
      },
    },
  };
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{
          style: { width: widthVal < 600 && widthVal - 100, height: 350 },
        }}
      />
    </>
  );
};

export default BarChart;
