import { Table, Breadcrumb, Spin, Button } from "antd";
import React, { useEffect, useState } from "react";
import { messageUser } from "./apiAction";
import { TableSerach } from "../component/tableSearch";
import AddMsg from "./addMsg";

import moment from "moment";
import { TbInfoSquare, TbTrash, TbEye, TbBrightness2 } from "react-icons/tb";
import { isEmpty } from "lodash";

const columns = [
  {
    title: "ID",
    dataIndex: "SNO",
    key: "SNO",
    sorter: {
      compare: (a, b) =>
        moment(a.created_date).unix() - moment(b.created_date).unix(),
    },
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
    filters: [
      {
        text: "AED",
        value: "AED",
      },
      {
        text: "Agriculture",
        value: "Agriculture",
      },
      {
        text: "Animal Husbandry",
        value: "Animal Husbandry",
      },
      {
        text: "Fisheries",
        value: "Fisheries",
      },
      {
        text: "Horticulture",
        value: "Horticulture",
      },
      {
        text: "Marketing",
        value: "Marketing",
      },
      {
        text: "MDPU",
        value: "MDPU",
      },
      {
        text: "TNAU",
        value: "TNAU",
      },
      {
        text: "WRD",
        value: "WRD",
      },
    ],
    onFilter: (value, record) => record.department.startsWith(value),
  },
  {
    title: "Name",
    dataIndex: "username",
    key: "username",

    ...TableSerach("username"),
  },
  {
    title: "Sub Basin",
    dataIndex: "sub_basin",
    key: "sub_basin",

    ...TableSerach("sub_basin"),
  },
  {
    title: "Mobile",
    dataIndex: "MOBILE",
    key: "MOBILE",

    ...TableSerach("MOBILE"),
  },
  {
    title: "Email",
    dataIndex: "EMAIL",
    key: "EMAIL",

    ...TableSerach("EMAIL"),
  },
];

const antIcon = <TbBrightness2 className="spinStyle" />;

const MessageList = () => {
  const [MessageUserList, setMessageUsers] = useState([]);
  const [loadingTime, setLoadiingTime] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectRow,setSelectRow] = useState([]) ; 
  const [isMsgOen, setIsMsgOen] = useState(false);

  useEffect(() => {
    messageUser(setMessageUsers, setLoadiingTime);
  }, []);

  const handleCancel = () => {
    setIsMsgOen(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectRow(selectedRows);
    },
  };

  return (
    <>
    {console.log("selectRow--->",selectRow)}
      <Spin
        tip="Fetching data ..."
        spinning={loadingTime}
        size="large"
        indicator={antIcon}
        style={{ color: "#673ab7", fontWeight: "bold" }}
      >
        <Breadcrumb
          separator="/"
          style={{ padding: "20px", fontSize: "large", fontWeight: "lighter" }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Send Message</Breadcrumb.Item>
        </Breadcrumb>
        <Button
          disabled={!isEmpty(selectedRowKeys) ? false : true}
          style={{ float: "right", margin: "5px", marginRight: "20px" }}
          onClick={() => setIsMsgOen(true)}
        >
          Send Message
        </Button>
        <Table
          rowKey={(record) => record.MOBILE}
          rowSelection={rowSelection}
          style={{ margin: "20px" }}
          size={"small"}
          columns={columns}
          dataSource={MessageUserList}
          pagination={{ showSizeChanger: true, showQuickJumper: true }}
        />
        <AddMsg
          handleCancel={handleCancel}
          isMsgOen={isMsgOen}
          keys={selectRow}
        />
      </Spin>
    </>
  );
};

export default MessageList;
