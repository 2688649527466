import { Layout } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { get, isEmpty } from "lodash";

const { Content } = Layout;

const AppContent = ({ routes, isAuthed }) => {
  const userInfo = useSelector(
    (state) => get(state, "AuthReducer.userData", []),
    shallowEqual
  );

  const getRoutes = () => {
    if (isAuthed && !isEmpty(userInfo)) {
      return (
        <Routes>
          <Route
            path="/"
            element={
              userInfo[0].user_type === "1" || userInfo[0].user_type === "2"
                ? routes[1].component
                : routes[8].component
            }
          ></Route>
          <Route path="/changepassword" element={routes[2].component}></Route>
          <Route path="/user" element={routes[3].component}></Route>
          <Route path="/userList" element={routes[4].component}></Route>
          <Route path="/adminlist" element={routes[10].component}></Route>
          <Route path="/TNAU" element={routes[5].component}></Route>
          <Route path="/Horticulture" element={routes[5].component}></Route>
          <Route path="/AED" element={routes[5].component}></Route>
          <Route path="/AnimalHusbandry" element={routes[5].component}></Route>
          <Route path="/WRD" element={routes[5].component}></Route>
          <Route path="/Marketing" element={routes[5].component}></Route>
          <Route path="/Fisheries" element={routes[5].component}></Route>
          <Route path="/agri" element={routes[5].component}></Route>
          <Route path="/MessageReport" element={routes[6].component}></Route>

          <Route path="/Message" element={routes[7].component}></Route>
          <Route path="/dashboard" element={routes[8].component}></Route>
          <Route path="/geoMap" element={routes[9].component}></Route>
          <Route path="/water" element={routes[11].component}></Route>
        </Routes>
      );
    }
  };
  return (
    <>
      <Content style={{ overflow: "auto", height: "90vh" }}>
        {getRoutes()}
      </Content>
    </>
  );
};

export default AppContent;
