import {
  Button,
  Checkbox,
  Form,
  Input,
  Card,
  Breadcrumb,
  Modal,
  Select,
} from "antd";
import { putChangePassword } from "./apiAction";
import { useSelector, shallowEqual } from "react-redux";
import { get, isEmpty } from "lodash";

import React, { useEffect, useState } from "react";
import { getDepartment } from "../login/apiAction";
import { useDispatch } from "react-redux";

const ChangePassword = () => {
  const userInfo = useSelector(
    (state) => get(state, "AuthReducer.userData", []),
    shallowEqual
  );

  const [department, setDepartment] = useState([]);
  const [deptID, setDeptID] = useState(userInfo[0].LINE_DEPT);
  const [isModel, setModel] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const onFinish = (values) => {
    const apiData = {
      user_id: !isEmpty(userInfo) && userInfo[0].user_type != "4" ? userInfo[0].id :userInfo[0].ID,
      password: values.ConfirmPassword,
      user_role_id: userInfo[0].user_type,
    };
    putChangePassword(apiData);
    form.setFieldValue("password", "");
    form.setFieldValue("newPassword", "");
    form.setFieldValue("ConfirmPassword", "");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleModal = () => {
    setDeptID(userInfo[0].LINE_DEPT);
    setModel(!isModel);
  };
  useEffect(() => {
    dispatch(getDepartment(setDepartment));
  }, []);
  return (
    <>
      {/* {userInfo[0].user_type == "4" ?  <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          gap: "40em",
        }}
      >
        <Breadcrumb
          separator="/"
          style={{ padding: "20px", fontSize: "large", fontWeight: "lighter" }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Change Password</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <Button
            style={{
              height: "4em",
              textAlign: "center",
              verticalAlign: "middle",
              lineHeight: "normal",
            }}
            onClick={handleModal}
          >
            Request Change
            <span style={{ display: "block" }}>Department</span>
          </Button>
        </div>
      </div>
      : */}
      <Breadcrumb
        separator="/"
        style={{ padding: "20px", fontSize: "large", fontWeight: "lighter" }}
      >
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Change Password</Breadcrumb.Item>
      </Breadcrumb>
      {/* } */}
      <div
        style={{
          width: window.innerWidth < 600 ? "85vw" : "",
          marginLeft: window.innerWidth < 600 ? "" : "250px",
          marginRight: window.innerWidth < 600 ? "" : "250px",
        }}
      >
        <center>
          <Card
            title="Change Password"
            style={{
              width: window.innerWidth < 600 ? "100vw" : "",

              margin: window.innerWidth < 600 ? "" : "30px",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
          >
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: window.innerWidth < 600 ? "" : 600,
                marginTop: "20px",
              }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="User Name"
                name="username"
                initialValue={!isEmpty(userInfo) && userInfo[0].user_name}
                hasFeedback
              >
                <Input
                  style={{
                    borderColor: "#32be8f",
                    borderRadius: 1,
                    borderTopWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    boxShadow: "none",
                  }}
                  disabled={true}
                  //   type="text"
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        (!isEmpty(userInfo) &&
                          getFieldValue("password") === userInfo[1].password)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please input your Current Password !!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  style={{
                    borderColor: "#32be8f",
                    borderRadius: 1,
                    borderTopWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    boxShadow: "none",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="New Password"
                name="newPassword"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your New password!",
                  },
                ]}
              >
                <Input.Password
                  style={{
                    borderColor: "#32be8f",
                    borderRadius: 1,
                    borderTopWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    boxShadow: "none",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="ConfirmPassword"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your Confirm password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  style={{
                    borderColor: "#32be8f",
                    borderRadius: 1,
                    borderTopWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    boxShadow: "none",
                  }}
                />
              </Form.Item>

              <Form.Item
                style={{ marginLeft: window.innerWidth < 600 ? "" : "150px" }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </center>
      </div>
      <Modal
        open={isModel}
        title="Change Department"
        onOk={handleModal}
        onCancel={handleModal}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <h4>Current Department : </h4>
            <input type="text" value="TNAU" disabled></input>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <h4>Request Department : </h4>
            <Select
              style={{
                width: 160,
              }}
              value={deptID}
              onChange={(val) => setDeptID(val)}
            >
              {department.length > 0 &&
                department.map((data, key) => (
                  <Option key={key} value={data.ID}>
                    {data.NAME}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ChangePassword;
