import { multipleSheetExcel, exportExcel } from "./exportExcelAction";
import { writeFile, utils } from "xlsx";
export function registerExcal(
  data,
  selectedKeys,
  phaseType,
  userInfo,
  drilDownRegister
) {
  let value = [];
  let TnauName;
  let AgricultureName;
  let HorticultureName;
  let AEDName;
  let AnimalName;
  let WRDName;
  let MarketingName;
  let FisheriesName;

  if (phaseType == 0) {
    TnauName = data.filter((item, i) => {
      if (item.department === "TNAU") {
        return item;
      }
    });

    AgricultureName = data.filter((item, i) => {
      if (item.department === "Agriculture") {
        return item;
      }
    });

    HorticultureName = data.filter((item, i) => {
      if (item.department === "Horticulture") {
        return item;
      }
    });

    AEDName = data.filter((item, i) => {
      if (item.department === "AED") {
        return item;
      }
    });

    AnimalName = data.filter((item, i) => {
      if (item.department === "Animal Husbandry") {
        return item;
      }
    });

    WRDName = data.filter((item, i) => {
      if (item.department === "WRD") {
        return item;
      }
    });

    MarketingName = data.filter((item, i) => {
      if (item.department === "Marketing") {
        return item;
      }
    });

    FisheriesName = data.filter((item, i) => {
      if (item.department === "Fisheries") {
        return item;
      }
    });
  } else {
    TnauName = data.filter((item, i) => {
      if (item.department === "TNAU" && item.phase == phaseType) {
        return item;
      }
    });

    AgricultureName = data.filter((item, i) => {
      if (item.department === "Agriculture" && item.phase == phaseType) {
        return item;
      }
    });

    HorticultureName = data.filter((item, i) => {
      if (item.department === "Horticulture" && item.phase == phaseType) {
        return item;
      }
    });

    AEDName = data.filter((item, i) => {
      if (item.department === "AED" && item.phase == phaseType) {
        return item;
      }
    });

    AnimalName = data.filter((item, i) => {
      if (item.department === "Animal Husbandry" && item.phase == phaseType) {
        return item;
      }
    });

    WRDName = data.filter((item, i) => {
      if (item.department === "WRD" && item.phase == phaseType) {
        return item;
      }
    });

    MarketingName = data.filter((item, i) => {
      if (item.department === "Marketing" && item.phase == phaseType) {
        return item;
      }
    });

    FisheriesName = data.filter((item, i) => {
      if (item.department === "Fisheries" && item.phase == phaseType) {
        return item;
      }
    });
  }

  if (userInfo[0].line_dept == 0 && drilDownRegister == 0) {
    const selectedKey = {
      a: "S.No",
      b: "Created Date",
      c: "User Name",
      d: "Mobile",
      e: "Email",
      f: "Department",
      g: "Subbasin",
      h: "Phase",
    };
    const sheetData = [
      {
        name: "TNAU",
        data: [
          Object.values(selectedKey),
          ...TnauName.map(({ ...rest }) => Object.values(rest)),
        ],
      },

      {
        name: "Agriculture",
        data: [
          Object.values(selectedKey),
          ...AgricultureName.map(({ ...rest }) => Object.values(rest)),
        ],
      },

      {
        name: "Horticulture",
        data: [
          Object.values(selectedKey),
          ...HorticultureName.map(({ ...rest }) => Object.values(rest)),
        ],
      },

      {
        name: "AED",
        data: [
          Object.values(selectedKey),
          ...AEDName.map(({ ...rest }) => Object.values(rest)),
        ],
      },

      {
        name: "Animal Husbandary",
        data: [
          Object.values(selectedKey),
          ...AnimalName.map(({ ...rest }) => Object.values(rest)),
        ],
      },

      {
        name: "WRD",
        data: [
          Object.values(selectedKey),
          ...WRDName.map(({ ...rest }) => Object.values(rest)),
        ],
      },

      {
        name: "Marketing",
        data: [
          Object.values(selectedKey),
          ...MarketingName.map(({ ...rest }) => Object.values(rest)),
        ],
      },

      {
        name: "Fisheries",
        data: [
          Object.values(selectedKey),
          ...FisheriesName.map(({ ...rest }) => Object.values(rest)),
        ],
      },
    ];
    multipleSheetExcel(sheetData, selectedKeys);
  } else {
    if (userInfo[0].line_dept == 1 || drilDownRegister == 1) {
      value =
        phaseType != 0
          ? data.filter((item, i) => {
              if (item.department === "TNAU" && item.phase == phaseType) {
                return item;
              }
            })
          : data.filter((item, i) => {
              if (item.department === "TNAU") {
                return item;
              }
            });
    } else if (userInfo[0].line_dept == 2 || drilDownRegister == 2) {
      value =
        phaseType != 0
          ? data.filter((item, i) => {
              if (
                item.department === "Agriculture" &&
                item.phase == phaseType
              ) {
                return item;
              }
            })
          : data.filter((item, i) => {
              if (item.department === "Agriculture") {
                return item;
              }
            });
    } else if (userInfo[0].line_dept == 3 || drilDownRegister == 3) {
      value =
        phaseType != 0
          ? data.filter((item, i) => {
              if (
                item.department === "Horticulture" &&
                item.phase == phaseType
              ) {
                return item;
              }
            })
          : data.filter((item, i) => {
              if (item.department === "Horticulture") {
                return item;
              }
            });
    } else if (userInfo[0].line_dept == 4 || drilDownRegister == 4) {
      value =
        phaseType != 0
          ? data.filter((item, i) => {
              if (item.department === "AED" && item.phase == phaseType) {
                return item;
              }
            })
          : data.filter((item, i) => {
              if (item.department === "AED") {
                return item;
              }
            });
    } else if (userInfo[0].line_dept == 5 || drilDownRegister == 5) {
      value =
        phaseType != 0
          ? data.filter((item, i) => {
              if (
                item.department === "Animal Husbandry" &&
                item.phase == phaseType
              ) {
                return item;
              }
            })
          : data.filter((item, i) => {
              if (item.department === "Animal Husbandry") {
                return item;
              }
            });
    } else if (userInfo[0].line_dept == 6 || drilDownRegister == 6) {
      value =
        phaseType != 0
          ? data.filter((item, i) => {
              if (item.department === "WRD" && item.phase == phaseType) {
                return item;
              }
            })
          : data.filter((item, i) => {
              if (item.department === "WRD") {
                return item;
              }
            });
    } else if (userInfo[0].line_dept == 7 || drilDownRegister == 7) {
      value =
        phaseType != 0
          ? data.filter((item, i) => {
              if (item.department === "Marketing" && item.phase == phaseType) {
                return item;
              }
            })
          : data.filter((item, i) => {
              if (item.department === "Marketing") {
                return item;
              }
            });
    } else if (userInfo[0].line_dept == 8 || drilDownRegister == 8) {
      value =
        phaseType != 0
          ? data.filter((item, i) => {
              if (item.department === "Fisheries" && item.phase == phaseType) {
                return item;
              }
            })
          : data.filter((item, i) => {
              if (item.department === "Fisheries") {
                return item;
              }
            });
    }
    if (value.length != 0) {
      exportExcel(
        value,
        userInfo[0].line_dept == 0 ? drilDownRegister : userInfo[0].line_dept
      );
    }
  }
}
