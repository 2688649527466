import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";

drilldown(Highcharts);
HighchartsExporting(Highcharts);

const PieChart = ({ title, y, dataList, widthVal, type, phase }) => {
  var options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: type,
      events: {
        beforePrint: function () {
          this.setSize(1000, 370, false);
          this.originalTitle = this.options.title.text;

          this.setTitle({ text: this.options.title.text + " - " + phase });
        },
        afterPrint: function () {
          this.setSize(widthVal < 600 && widthVal - 100, 370, false);
          this.setTitle({ text: this.originalTitle });
        },
        fullscreenOpen: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: false,
                },
              },
            },
            title: {
              text: title + " - " + phase,
              align: "center",
            },
          });
        },
        fullscreenClose: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: true,
                },
              },
            },
            title: {
              text: title,
              align: "center",
            },
          });
        },
      },
    },

    title: {
      text: title,
      align: "center",
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>({point.y:.0f})</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y:.0f}",
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "District Users",
        colorByPoint: true,
        data: dataList,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    exporting: {
      enabled: true,
      sourceWidth: 1000,
      chartOptions: {
        xAxis: [
          {
            max: null,
          },
        ],
        chart: {
          events: {
            load: function () {
              const chart = this;
              const currentDate = new Date().toLocaleDateString("en-GB");
              chart.renderer
                .text(
                  "Date: " + currentDate,
                  chart.plotWidth - 100,
                  chart.plotTop - 10,
                  false
                )
                .css({
                  color: "#000000",
                  fontSize: "12px",
                  textAlign: "right",
                })
                .add();
            },
          },
        },
        title: {
          text: title + "  " + phase,
          align: "center",
          y: 15,
        },
      },
    },
  };
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{
          style: { width: widthVal < 600 && widthVal - 100, height: 370 },
        }}
      />
    </div>
  );
};

export default PieChart;
