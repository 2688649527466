import React, { Component } from "react";
import phase1 from "./phase/phase 1.json";
import phase2 from "./phase/Phase 2.json";
import phase3 from "./phase/PHASE-3.json";
import phase4 from "./phase/phase 4.json";
import OlMap from "ol/Map";
import OlView from "ol/View";
import CheckboxTree from "./check";
// import './styles/map.css'
import { Button, Switch } from "antd";

// import OlLayerTile from "ol/layer/Tile";
import TileLayer from "ol/layer/Tile";
// import TileWMS from "ol/source/TileWMS";
import GeoJSON from "ol/format/GeoJSON";
import VectorImageLayer from "ol/layer/VectorImage.js";
import ImageWMS from "ol/source/ImageWMS";
import dateFormat from "dateformat";
import markerImg1 from "./images/marker1.png";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import { useDispatch, useSelector, shallowEqual, connect } from "react-redux";
import DepartmentType from "../Dashboard/departmentType";
import { getDepartment } from "./apiAction";

// import OlSourceOSM from "ol/source/OSM";
import Zoom from "ol/control/Zoom";
import { defaults } from "ol/interaction";
import { doubleClick } from "ol/events/condition";
import Select from "ol/interaction/Select";
import { Fill, Stroke, Style, Text, Icon } from "ol/style";
import windowDimensions from "react-window-dimensions";
import PropType from "prop-types";
import { createStringXY } from "ol/coordinate";
import axios from "axios";
import { isEmpty, get } from "lodash";

import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import MousePosition from "ol/control/MousePosition";
import { Vector as VectorSource } from "ol/source";
import { ScaleLine, FullScreen } from "ol/control";

import "ol/ol.css";
import "./styles/map.css";
import { transform } from "ol/proj";
import XYZ from "ol/source/XYZ";
import "./styles/ol-popup.css";
import Popup from "ol-popup";

import { Tooltip, Row, Col, Card, Checkbox, Spin, Image } from "antd";
import Filter from "../Report/filter";

import { TbZoomOutArea, TbBrightness2 } from "react-icons/tb";

import { getReportDataList, getImage } from "../Report/apiAction";

import MonthSlider from "./timeSeriesCard";
import Checkboxs from "./checkbox";
import Chart from "./Chart";

// import { userInfo } from "./apiAction";

const center = transform([78.37, 10.62], "EPSG:4326", "EPSG:3857");

const monthData = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

// const currentLocation = useLocation().pathname;

// var uniqueArray;

const generateStyle = (strokeClr, fillClr, text, txtFillClr) =>
  new Style({
    stroke: new Stroke({
      color: strokeClr,
      width: 3,
    }),
    fill: new Fill({
      color: fillClr,
    }),
    text: new Text({
      text: text,
      fill: new Fill({
        color: txtFillClr,
      }),
    }),
  });

const normaliseBorder = (feature) => {
  return generateStyle(
    "yellow",
    "rgba(255, 255, 0, 0)",
    feature.values_.BLKNAME,
    "#000"
  );
};

let popup;

const antIcon = <TbBrightness2 className="spinStyle" />;

class GeoMap extends Component {
  constructor(props) {
    super(props);
    // this.visibleList=[];
    (this.phase1 = [
      "Cauvery Delta",
      "Gadananadhi",
      "Kousiganadhi",
      "Krishnagiri to Pambar",
      "Lower Vaigai",
      "Lower Bhavani",
      "Lower Palar",
      "Lower Tamiraparani",
      "Lower Vellar",
      "Manjalar",
      "Nagariyar",
      "Ponnaniyar",
      "Sathaiyar",
      "Sirumalaiyar",
      "Suruliyar",
      "Varahanadhi",
      "Kallar",
      " Upper Bhavani",
    ]),
      (this.phase2 = [
        "Agaramaru",
        "Aliyar_Pennaiyar",
        "Ayiaar",
        "Chittar_Tamiraparani",
        "Karaipottanar",
        "Lower Coleroon",
        "Lower Pennaiyar",
        "Mettur Reservoir to Noyel confluence",
        "Nandhiyar",
        "Nandiyar_Kulaiyar",
        "Pachaiyar",
        "Pambar",
        "Pungar",
        "Tirumanimuttar",
        "Uppar",
        "Vaniyar",
      ]),
      (this.state = {
        zoom: 1,
        level: -1,
        rotationVal: 0,
        fullExtent: false,
        count: 0,
        mapMarkerDetails: [],
        setLoadiing: false,
        UInfo: [],
        deptTypePath: "",
        detId: "2",
        deptList: [],
        setDepartment: "",
        userRole: "",
        featureDetails: {},
        img2: "",
        img1: "",
        layer: [],
        uniqueArray: [],
        phase1select: false,
        phase2select: false,
        phase3select: false,
        phase4select: false,
        waterDetails: [],
        setGraphArea: [],
        setGraphVolume: [],
        year: "2020",
        month: "01",
        FN: false,
      });
    this.draw = null;
  }

  configureMap = () => {
    // const b = Object.entries(a).map((value)=> {
    //     return [value[0],value[1].map((taluk) => {
    //         return taluk.Taluk_code
    //     })]
    // })
    let borderSource = new VectorSource();
    this.borderLayer = new VectorLayer({
      source: borderSource,
      style: (f) => normaliseBorder(f),
    });

    this.borderLayer.setZIndex(0);

    this.tamilNadu = new ImageLayer({
      source: new ImageWMS({
        url: "https://agrex-demo.farmwiseai.com/geoserver/Puvi/wms",
        params: {
          LAYERS: `Puvi:district_boundary`,
        },

        transition: 0,
      }),
    });

    this.tamilNadu.setZIndex(10);

    this.watertYear = new ImageLayer({
      source: new ImageWMS({
        url: "https://agrex-demo.farmwiseai.com/geoserver/Tniamp/wms",
        params: {
          LAYERS: `Tniamp:01_01_2019`,
        },

        transition: 0,
      }),
    });

    this.watertYear.setZIndex(11);

    this.watertOutlay = new ImageLayer({
      source: new ImageWMS({
        url: "https://agrex-demo.farmwiseai.com/geoserver/Tniamp/wms",
        params: {
          LAYERS: `Tniamp:tn_WB_base`,
        },

        transition: 0,
      }),
    });

    this.watertOutlay.setZIndex(12);

    this.view = new OlView({
      center,

      zoom: this.state.zoom,
      rotation: this.state.rotationVal,
    });

    var worldImagery = new TileLayer({
      source: new XYZ({
        attributions: [
          "Powered by Esri",
          "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
        ],
        attributionsCollapsible: false,
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        maxZoom: 18,
      }),
    });

    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: "EPSG:4326",

      className: "custom-mouse-position",
      target: document.getElementById("mouse-position"),
    });

    const vectorSource1 = new VectorSource({
      features: [],
    });

    this.vectorLayer1 = new VectorLayer({
      source: vectorSource1,
    });
    this.vectorLayer1.setZIndex(101);

    this.olmap = new OlMap({
      interactions: defaults({
        doubleClickZoom: false,
      }),

      target: undefined,
      layers: [
        worldImagery,
        this.vectorLayer1,

        this.borderLayer,
        this.tamilNadu,
        this.watertYear,
        this.watertOutlay,
        // this.tniampSubBasinPhase1,
        // this.tniampSubBasinPhase2,
        // this.tniampSubBasinPhase3,
        // this.tniampSubBasinPhase4,
      ],
      controls: [
        new Zoom({
          className: "zoom",
        }),
        new ScaleLine(),
        new FullScreen(),
        mousePositionControl,
      ],

      view: this.view,
    });

    this.tamilNadu.setVisible(true);
    this.watertYear.setVisible(true);
    this.watertOutlay.setVisible(true);

    //this.WMSWater_Layer.setVisible(false);
  };

  showPop = () => {
    this.olmap.on("click", (event) => {
      this.setState({ waterDetails: [], setGraphArea: [], setGraphVolume: [] });
      // this.olmap.forEachFeatureAtPixel(event.pixel, (feature) => {
      const viewResolution = this.olmap.getView().getResolution();

      const url = this.watertYear.getSource().getFeatureInfoUrl(
        event.coordinate,

        viewResolution,

        "EPSG:3857",

        { INFO_FORMAT: "application/json" }
      );

      if (url) {
        fetch(url)
          .then((response) => response.json())

          .then((body) => {
            if (body["features"].length > 0) {
              this.graphApi(
                this.state.year,
                body["features"][0]?.properties?.id
              );
              console.log("water--->",body["features"][0]?.properties)
              this.setState({ waterDetails: body["features"][0]?.properties });
            }
          });
      }
    });
    // });
  };

  updateMap() {
    if (this.olmap) {
      // this.handleRemoveAllLayers();
      this.olmap.getView().setZoom(this.state.zoom);
    }
  }

  componentDidUpdate() {
    // console.log("this.state.layer.length--->",this.state.layer.length);
  }

  componentWillUnmount() {
    this.olmap.setTarget(undefined);
  }

  select = new Select({
    condition: doubleClick,
  });

  componentDidMount() {
    this.configureMap();
    // this.setState({watersubbasin:[]})
    this.olmap.setTarget("draw-map");
    this.olmap.on("moveend", () => {
      let zoom = this.olmap.getView().getZoom();
      this.setState({ zoom });
    });

    if (this.state.count === 0) {
      this.state.count = 1;

      axios
        .get(
          `https://agrex-demo.farmwiseai.com/geoserver/Puvi/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Puvi%3Adistrict_boundary&outputFormat=application%2Fjson`
        )
        .then((data) => {
          this.setBoundarySourceData(data.data, 1);

          setTimeout(() => {
            this.showPop();
            popup = new Popup();
            this.olmap.addOverlay(popup);
          }, 1000);
        });
    }
  }

  // visibleList = [];

  setBoundarySourceData = (data, type) => {
    let boundarySourceDistrict = new VectorSource({
      features: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }).readFeatures(data),
    });
    // this.olmap.getLayers().array_[1].setSource(boundarySourceDistrict);

    setTimeout(() => {
      this.olmap
        .getView()
        .fit(boundarySourceDistrict.getExtent(), { duration: 1000 });
    }, 1000);
  };

  rotationAction = () => {
    this.setState({ rotationVal: Math.PI / 2 });
  };

  subBasinLayerset = (val) => {
    if (val?.setCheckKey) {
      var layer1 = val?.setCheckKey.filter(function (v) {
        return !/[0-9]/g.test(v);
      });
      var data = layer1.toString();

      // if (layer1.length == 0) layer1 = "Null"

      var waterFilterData =
        "Sub_Basin IN (" + "'" + data.replace(/,/g, "','") + "'" + ")";


      this.watertYear.getSource().updateParams({
        CQL_FILTER: waterFilterData,
      });

      this.watertOutlay.getSource().updateParams({
        CQL_FILTER: waterFilterData,
      });

    }
  };

  tamilnaduLayer = (val) => {
    this.tamilNadu.setVisible(val.target.checked);
  };

  setMonth = (value) => {
    this.setState({ month: value });
    // this.setState({ waterDetails: [], setGraphArea: [], setGraphVolume: [] })

    var fnVal = this.state.FN === true ? "02" : "01";

    var data = fnVal + "_" + value + "_" + this.state.year;
    this.watertYear.getSource().updateParams({
      LAYERS: `Tniamp:${data}`,
    });
  };

  setFn = (val) => {
    this.setState({ FN: val });
    // this.setState({ waterDetails: [], setGraphArea: [], setGraphVolume: [] })

    var fnVal = val === true ? "02" : "01";
    var data = fnVal + "_" + this.state.month + "_" + this.state.year;

    this.watertYear.getSource().updateParams({
      LAYERS: `Tniamp:${data}`,
    });
  };

  waterYear = (val) => {
    this.setState({ year: val });
    // this.setState({ waterDetails: [], setGraphArea: [], setGraphVolume: [] })

    var fnVal = this.state.FN === true ? "02" : "01";
    var data = fnVal + "_" + this.state.month + "_" + val;
    this.watertYear.getSource().updateParams({
      LAYERS: `Tniamp:${data}`,
    });
  };

  graphApi = (yearVal, id) => {
    axios
      .get(
        `https://05ux2fdt32.execute-api.ap-south-1.amazonaws.com/dev/api/water/volume?year=${yearVal}&unique_id=${id}`
      )
      .then((data) => {
        var graphApiResArea = data.data.map((data) => {
          return {
            x: monthData[data.water_month - 1],
            y: (data["area_m2"] / 10000).toFixed(2),
          };
        });
        var graphApiResVolume = data.data.map((data) => {
          return {
            x: monthData[data.water_month - 1],
            y: data["volume"].toFixed(2),
          };
        });

        //    var graphApiResMonth = data.data.map((data) => ("01 "+ monthData[data.water_month - 1] + " "+ (this.state.setDateVal).toString() ));

        this.setState({ setGraphArea: graphApiResArea });
        this.setState({ setGraphVolume: graphApiResVolume });

        // this.setState({ setGraphArea: graphApiResMonth });
      });
  };

  render() {
    this.updateMap();
    return (
      <>
        <Spin
          tip="Fetching data ..."
          spinning={this.state.setLoadiing}
          size="large"
          indicator={antIcon}
          style={{ color: "#673ab7", fontWeight: "bold" }}
        >
          <div
            className="draw-map"
            id="draw-map"
            style={{ width: "100%", height: `${this.props.height - 65}px` }}
          ></div>
          <div className="extentZoom ol-control">
            <Tooltip title="Zoom to full Extent" placement="left">
              <button Tooltip="Zoom to full Extent">
                <TbZoomOutArea
                  onClick={() => this.setState({ fullExtent: true })}
                />
              </button>
            </Tooltip>
          </div>
          <div className="MousePosition ol-control">
            <div id="mouse-position"></div>
          </div>

          <Card
            style={{
              width: "20vw",
              position: "absolute",
              top: "3%",
              right: "2%",
              display: "block",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              height: "230px",
              overflow: "scroll",
            }}
          >
            <Checkbox
              onChange={this.tamilnaduLayer}
              defaultChecked
              style={{ marginLeft: "1.4rem", color: "white" }}
            >
              TN Districts
            </Checkbox>
            <div style={{ height: "230px", scrollY: "scroll" }}>
              <CheckboxTree
                setCheckLayer={(val) => {
                  this.subBasinLayerset(val);
                }}
              />
            </div>
          </Card>
          {!isEmpty(this.state.setGraphArea) && (
            <Card
              style={{
                width: "32vw",
                position: "absolute",
                bottom: "3%",
                right: "2%",
                display: "block",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div>
                <center style={{ color: "white" }}>
                  Area and Volume for Water Bodies
                </center>
              </div>
              <Chart
                Area={this.state.setGraphArea}
                Volume={this.state.setGraphVolume}
                Year= {this.state.year}
                Tankname = {this.state.waterDetails?.Tank_name}
              />
            </Card>
          )}

          {/* <TimeseriesCard ref={timeSeriesElement} from={2019} to={2023} defaultsValYear={2019}
            handleYearMonth={handleYearMonth} /> */}
          <Card
            style={{
              width: "32vw",
              position: "absolute",
              bottom: "3%",
              left: "2%",
              display: "block",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              {" "}
              Year
              {["2019", "2020", "2021", "2022", "2023"].map((value) => {
                return (
                  <>
                    <input
                      className="inputradio"
                      style={{ fontSize: "40px" }}
                      type="radio"
                      name="year"
                      value={value}
                      checked={value == this.state.year}
                      onChange={(e) => {
                        this.waterYear(e.target.value);
                      }}
                    />
                    <label>{value}</label>
                  </>
                );
              })}
            </div>
            <div style={{ marginBottom: 10 }}>
              <label style={{ marginRight: 10 }}>FN</label>
              <Switch
                style={{ backgroundColor: "#0098a7" }}
                onChange={this.setFn}
              />
            </div>
            <MonthSlider setMonth={this.setMonth} />
          </Card>
          <div className="waterDetails ol-control">
            {!isEmpty(this.state.waterDetails) && (
              <Card
                style={{
                  width: "25vw",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
                title={
                  <center style={{ color: "white" }}>
                    {this.state.waterDetails?.Tank_name}
                  </center>
                }
              >
                <Row style={{ marginTop: "0.6rem" }}>
                  <Col md={10}>District</Col>
                  <Col md={14}>{this.state.waterDetails?.dtname}</Col>
                </Row>
                <Row style={{ marginTop: "0.6rem" }}>
                  <Col md={10}>Village</Col>
                  <Col md={14}>{this.state.waterDetails?.VILL_NAME}</Col>
                </Row>
                <Row style={{ marginTop: "0.6rem" }}>
                  <Col md={10}>Area</Col>
                  <Col md={14}>
                    {this.state.waterDetails?.Area_m2_N &&
                      (this.state.waterDetails?.Area_m2_N/10000).toFixed(2)}{" "}
                    (ha)
                  </Col>
                </Row>
                <Row style={{ marginTop: "0.6rem" }}>
                  <Col md={10}>Volume</Col>
                  <Col md={14}>
                    {this.state.waterDetails?.Volume &&
                      this.state.waterDetails?.Volume.toFixed(2)}{" "}
                    (Cu.m)
                  </Col>
                </Row>
                <Row style={{ marginTop: "0.6rem" }}>
                  <Col md={10}>Tank Id</Col>
                  <Col md={14}>{this.state.waterDetails?.id}</Col>
                </Row>
              </Card>
            )}
          </div>
        </Spin>
      </>
    );
  }
}
GeoMap.propTypes = {
  height: PropType.number,
};

export default windowDimensions()(GeoMap);
