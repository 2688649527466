import axios from "axios";
import api from "../../constants/api";
import { message } from "antd";
import {
  exportExcel,
  multipleSheetExcel,
} from "../../actions/exportExcelAction";

function columnChartOption(
  apiRes1,
  apiRes2,
  setUserCountData,
  setCountDrilDown
) {
  const countDataList = apiRes1.data
    .filter((countDept) => countDept.NAME !== null && countDept.NAME != "MDPU")
    .map((countDept) => {
      return {
        name: countDept.NAME,
        y: countDept["count"],
        drilldown: countDept.NAME,
      };
    });

  setUserCountData([]);
  setCountDrilDown([]);

  const drilldownAED = apiRes2
    .filter((filter1) => filter1.dept_id === 4)
    .map((AEDData) => [AEDData.NAME, AEDData.count]);
  const drilldownAgri = apiRes2
    .filter((filter1) => filter1.dept_id === 2)
    .map((AEDData) => [AEDData.NAME, AEDData.count]);
  const drilldownHusb = apiRes2
    .filter((filter1) => filter1.dept_id === 5)
    .map((AEDData) => [AEDData.NAME, AEDData.count]);
  const drilldownFish = apiRes2
    .filter((filter1) => filter1.dept_id === 8)
    .map((AEDData) => [AEDData.NAME, AEDData.count]);
  const drilldownHORTI = apiRes2
    .filter((filter1) => filter1.dept_id === 3)
    .map((AEDData) => [AEDData.NAME, AEDData.count]);
  const drilldownMarketing = apiRes2
    .filter((filter1) => filter1.dept_id === 7)
    .map((AEDData) => [AEDData.NAME, AEDData.count]);
  const drilldownMDU = apiRes2
    .filter((filter1) => filter1.dept_id === 9)
    .map((AEDData) => [AEDData.NAME, AEDData.count]);
  const drilldownTNAU = apiRes2
    .filter((filter1) => filter1.dept_id === 1)
    .map((AEDData) => [AEDData.NAME, AEDData.count]);
  const drilldownWRD = apiRes2
    .filter((filter1) => filter1.dept_id === 6)
    .map((AEDData) => [AEDData.NAME, AEDData.count]);
  const drilldownList = [
    {
      id: "AED",

      name: 4,
      data: drilldownAED,
    },
    {
      id: "Agriculture",

      name: 2,
      data: drilldownAgri,
    },
    {
      id: "Animal Husbandry",

      name: 5,
      data: drilldownHusb,
    },
    {
      id: "Fisheries",

      name: 8,
      data: drilldownFish,
    },
    {
      id: "Horticulture",

      name: 3,
      data: drilldownHORTI,
    },
    {
      id: "Marketing",

      name: 7,
      data: drilldownMarketing,
    },
    // {
    //   id: "MDPU",

    //   id: "MDPU",
    //   data: drilldownMDU,
    // },
    {
      id: "TNAU",

      name: 1,
      data: drilldownTNAU,
    },
    {
      id: "WRD",

      name: 6,
      data: drilldownWRD,
    },
  ];

  setUserCountData(countDataList);
  setCountDrilDown(drilldownList);
}

export function getDashboardByUsersCount(
  setUserCountData,
  setCountDrilDown,
  phaseType,
  setLoadiingTime,
  deptId
) {
  let endpoints = [
    api.dashboardUserByDept + "?phase_id=" + phaseType,
    api.dashboardUserBySubbasin + `?phase_id=${phaseType}&dept_id=${deptId}`,
  ];

  setLoadiingTime(true);
  return axios
    .all(endpoints.map((endpoint) => axios.get(endpoint)))
    .then((dataList) => {
      columnChartOption(
        dataList[0],
        dataList[1].data,
        setUserCountData,
        setCountDrilDown
      );

      setLoadiingTime(false);
    })
    .catch((error) => {
      setLoadiingTime(false);
    });
}

export function getSubbasinUserId(
  setSubbasinUserCountData,
  subBasinId,
  phaseType,
  create_date,
  deptId,
  setLoadiingTime
) {
  setLoadiingTime(true);
  return axios
    .get(
      api.registerusergraph + `?subbasin_id=${subBasinId}&phase_id=${phaseType}&create_date=${create_date}&dept_id=${deptId}`
      // `https://05ux2fdt32.execute-api.ap-south-1.amazonaws.com/dev/api/get_sub_basin_count` +
      //   `?subbasin_id=${subBasinId}&phase_id=${phaseType}&create_date=${create_date}&dept_id=${deptId}`
    )
    .then(({ data }) => {
      const subBasinData = data.map((basinData) => {
        return {
          name: basinData.Name,
          y: basinData["subbasin_count"],
        };
      });
      setSubbasinUserCountData(subBasinData);
      setLoadiingTime(false);
    })
    .catch((error) => {
      setLoadiingTime(false);
    });
}

export function getDashboardByGeoTagCount(
  setGeoCountData,
  setGeoDrilDown,
  phaseGeoTag,
  setLoadiingTime,
  dept
) {
  setLoadiingTime(true);
  let endpoints;

  if (dept == 0) {
    endpoints = [
      api.dashboardGeoTagByDept + "?phase_id=" + phaseGeoTag,
      api.dashboardGeoBySubbasin + `?phase_id=${phaseGeoTag}&dept_id=1`,
      api.dashboardGeoBySubbasin + `?phase_id=${phaseGeoTag}&dept_id=2`,
      api.dashboardGeoBySubbasin + `?phase_id=${phaseGeoTag}&dept_id=3`,
      api.dashboardGeoBySubbasin + `?phase_id=${phaseGeoTag}&dept_id=4`,
      api.dashboardGeoBySubbasin + `?phase_id=${phaseGeoTag}&dept_id=5`,
      api.dashboardGeoBySubbasin + `?phase_id=${phaseGeoTag}&dept_id=6`,
      api.dashboardGeoBySubbasin + `?phase_id=${phaseGeoTag}&dept_id=7`,
      api.dashboardGeoBySubbasin + `?phase_id=${phaseGeoTag}&dept_id=8`,
    ];
  } else {
    endpoints = [
      api.dashboardGeoTagByDept + "?phase_id=" + phaseGeoTag,
      api.dashboardGeoBySubbasin + `?phase_id=${phaseGeoTag}&dept_id=${dept}`,
    ];
  }
  return async (dispatch) => {
    return axios
      .all(endpoints.map((endpoint) => axios.get(endpoint)))
      .then((dataList) => {
        var drilldownData;
        if (dept == 0) {
          drilldownData = dataList[1].data
            .concat(dataList[2].data)
            .concat(dataList[3].data)
            .concat(dataList[4].data)
            .concat(dataList[5].data)
            .concat(dataList[6].data)
            .concat(dataList[7].data)
            .concat(dataList[8].data);
        } else {
          drilldownData = dataList[1].data;
        }
        dispatch({ type: "SET_SUMMARY_DATA", data: dataList[0].data });
        sessionStorage.setItem("dataCount", JSON.stringify(dataList[0].data));

        columnChartOption(
          dataList[0],
          drilldownData,
          setGeoCountData,
          setGeoDrilDown
        );
        setLoadiingTime(false);
      })
      .catch((error) => {
        setLoadiingTime(false);
      });
  };
}

export function interventionbyvillageApi(
  setUserCountData,
  phase,
  deptId,
  userName,
  subbainid
) {
  return axios
    .get(
      api.interventionbyvillage +
        `?dept_id=${deptId}&sub_bas_id=${subbainid}&phase_id=${phase}&user_name=${userName}`
    )
    .then(({ data }) => {
      const countDataList = data.map((countDept) => {
        return {
          village: countDept.village_name,
          y: countDept["tag_count"],
        };
      });
      setUserCountData(countDataList);
    })
    .catch((error) => {
      // message.error("Something went wrong please try again");
    });
}

export function interventionByuser(setUserCountData, phase, deptId, userName) {
  return async (dispatch) => {
    return axios
      .get(
        api.interventionByuser +
          `?dept_id=${deptId}&phase_id=${phase}&user_name=${userName}`
      )
      .then(({ data }) => {
        const countDataList = data.map((countDept) => {
          return {
            name: countDept.intervention_name,
            y: countDept["tag_count"],
          };
        });

        setUserCountData(countDataList);
        dispatch({ type: "SET_SUMMARY_DATA", data: data[0].tag_count });
      })
      .catch((error) => {
        // message.error("Something went wrong please try again");
      });
  };
}

export function GeoTagDashboard(
  setWorkProcessData,
  phaseTypeGeoTag,
  departmentId,
  dateVal1,
  dateVal2,
  sub_basin_id,
  setLoadiingTime
) {
  setLoadiingTime(true);
  return axios
    .get(
      api.GeoTagDashboard +
        `?dept_id=${departmentId}&phase_id=${phaseTypeGeoTag}&from_date=${dateVal1}&to_date=${dateVal2}&sub_basin_id=${sub_basin_id}`
    )
    .then(({ data }) => {
      const dateFinder = (tag_count, month, manthArray) => {
        switch (month) {
          case "January":
            manthArray.January = tag_count;
            break;
          case "February":
            manthArray.February = tag_count;
            break;
          case "March":
            manthArray.March = tag_count;

            break;
          case "April":
            manthArray.April = tag_count;

            break;
          case "May":
            manthArray.May = tag_count;

            break;
          case "June":
            manthArray.June = tag_count;

            break;
          case "July":
            manthArray.July = tag_count;

            break;
          case "August":
            manthArray.August = tag_count;

            break;
          case "September":
            manthArray.September = tag_count;

            break;
          case "October":
            manthArray.October = tag_count;

            break;
          case "November":
            manthArray.November = tag_count;

            break;
          case "December":
            manthArray.December = tag_count;

            break;
          default:
        }
      };

      const interventionName = [
        ...new Map(data.map((dd) => [dd["intervention"], dd])).values(),
      ].map((d) => d.intervention).filter((intervention) => intervention != null);

      var finalDataSet = [];

      try {
        for (var iter = 0; iter < interventionName.length; iter++) {
          const months0 = {
            January: 0,
            February: 0,
            March: 0,
            April: 0,
            May: 0,
            June: 0,
            July: 0,
            August: 0,
            September: 0,
            October: 0,
            November: 0,
            December: 0,
          };
          data
            .filter(
              (filterVal) => filterVal.intervention === interventionName[iter]
            )
            .map((d) => {
              return dateFinder(d.tag_count, d.month, months0);
            });

          finalDataSet.push({
            name: interventionName[iter],
            data: Object.values(months0),
          });
        }
      } catch (err) {
        setLoadiingTime(false);
      }
      setWorkProcessData(finalDataSet);
      setTimeout(() => {
        setLoadiingTime(false);
      }, 1000);
    })
    .catch((error) => {
      setLoadiingTime(false);
    });
}

export const getDashbordCount = (deptId, setDashboardCount, setLoadiingTime,subbainid = 0) => {
  setLoadiingTime && setLoadiingTime(true)
  return axios
    .get(api.dashboardCount + `?dept_id=${deptId}&sub_basin_id=${subbainid}`)
    .then(({ data }) => {
      console.log("data",data)
      setDashboardCount(data);
 

      setLoadiingTime && setLoadiingTime(false)
    })
    .catch((error) => {
      setLoadiingTime && setLoadiingTime(false)
      // message.error("Something went wrong please try again");
    });
}

export function getBeneficaryDetails(
  setBeneficaryData,
  deptId,
  phaseBeneficary,
  subbasin_id,
  setLoadiingTime
) {
  return axios
    .get(
      api.beneficary +
        `?dept_id=${deptId}&phase_id=${phaseBeneficary}&sub_basin_id=${subbasin_id}`
    )
    .then(({ data }) => {
      if (data[0] && data[0].NULL !== null) {
        const enumdept = {
          1: "tnau",
          2: "agri",
          3: "horti",
          4: "aed",
          5: "ani",
          6: "",
          7: "",
          8: "fish",
        };
        setLoadiingTime(true);
        const dateFinder = (count, gender, genderArray) => {
          switch (gender) {
            case "male":
              genderArray.male = count;
              break;
            case "female":
              genderArray.female = count;
              break;
            default:
          }
        };
        const categoryname = [
          ...new Map(data.map((dd) => [dd["category"], dd])).values(),
        ].map((d) => d.category);
        var finalDataSet = [];

        try {
          for (var iter = 0; iter < categoryname.length; iter++) {
            const gender = {
              male: 0,
              female: 0,
            };

            data
              .filter((filterVal) => filterVal.category == categoryname[iter])
              .map((d) => {
                return dateFinder(
                  d["count(" + enumdept[deptId] + ".id)"],
                  String(d.gender).toLowerCase(),
                  gender
                );
              });
            String(categoryname[iter]) != "" &&
              String(categoryname[iter]) != "0" &&
              String(categoryname[iter]) != "2" &&
              finalDataSet.push({
                name:
                  categoryname[iter] == "others"
                    ? "Others"
                    : String(categoryname[iter]).toUpperCase(),
                data: Object.values(gender),
              });
              console.log("finalDataSet",finalDataSet)
          }
        } catch (err) {
          console.log(err);
        }
        setBeneficaryData(finalDataSet);
        setTimeout(() => {
          setLoadiingTime(false);
        }, 1000);
      } else setBeneficaryData([]);
    });
}

export function getSubbasinComponent(
  setSubbasinComponentData,
  deptId,
  componentType,
  phaseSelect,
  subbasinType,
  setLoadiingTime
) {
  setLoadiingTime(true);
  return async (dispatch) => {
    return axios
      .get(
        api.subbasinComponent +
          `?dept_id=${deptId}&comp_id=${componentType}&phase_id=${phaseSelect}&sub_basin_id=${subbasinType}`
      )
      .then(({ data }) => {
        let totalCount = 0;
        console.log("data--->",data)
        const subBasinData = data.map((countDept) => {
          totalCount = totalCount + countDept["count"];
          return {
            name: countDept.sub_comp,
            y: countDept["count"],
          };
          // }
        });
        // console.log("subBasinData--->",subBasinData)
        const subBasinDatanull = subBasinData.filter((val) => val.name != null);
        dispatch({ type: "SET_SUMMARY_DATA", data: totalCount + 100 });
        setSubbasinComponentData(subBasinDatanull);
        setLoadiingTime(false);
      })
      .catch((error) => {
        setLoadiingTime(false);
      });
  };
}

export function getSubbasinWorkprogress(
  setWorkProcessData,
  phaseTypeGeoTag,
  departmentId,
  dateVal1,
  dateVal2,
  sub_basin_id,
  setLoadiingTime
) {
  return axios
    .get(
      api.subbasinworkprogress +
        `?dept_id=${departmentId}&phase_id=${phaseTypeGeoTag}&from_date=${dateVal1}&to_date=${dateVal2}&sub_basin_id=${sub_basin_id}`
    )
    .then(({ data }) => {
      const dateFinder = (tag_count, month, manthArray) => {
        switch (month) {
          case "January":
            manthArray.January = tag_count;
            break;
          case "February":
            manthArray.February = tag_count;
            break;
          case "March":
            manthArray.March = tag_count;

            break;
          case "April":
            manthArray.April = tag_count;

            break;
          case "May":
            manthArray.May = tag_count;

            break;
          case "June":
            manthArray.June = tag_count;

            break;
          case "July":
            manthArray.July = tag_count;

            break;
          case "August":
            manthArray.August = tag_count;

            break;
          case "September":
            manthArray.September = tag_count;

            break;
          case "October":
            manthArray.October = tag_count;

            break;
          case "November":
            manthArray.November = tag_count;

            break;
          case "December":
            manthArray.December = tag_count;

            break;
          default:
        }
      };

      const interventionName = [
        ...new Map(data.map((dd) => [dd["intervention"], dd])).values(),
      ].map((d) => d.intervention);

      var finalDataSet = [];

      try {
        for (var iter = 0; iter < interventionName.length; iter++) {
          const months0 = {
            January: 0,
            February: 0,
            March: 0,
            April: 0,
            May: 0,
            June: 0,
            July: 0,
            August: 0,
            September: 0,
            October: 0,
            November: 0,
            December: 0,
          };
          data
            .filter(
              (filterVal) => filterVal.intervention === interventionName[iter]
            )
            .map((d) => {
              return dateFinder(d.tag_count, d.month, months0);
            });

          finalDataSet.push({
            name: interventionName[iter],
            data: Object.values(months0),
          });
        }
      } catch (err) {
        setLoadiingTime(false);
      }
      setWorkProcessData(finalDataSet);
      setTimeout(() => {
        setLoadiingTime(false);
      }, 1000);
    })
    .catch((error) => {
      setLoadiingTime(false);
    });
}

export function getSubbasinBeneficary(
  setBeneficaryData,
  subbasin_id,
  phaseSubbasinBeneficary,
  deptId,
  setLoadiingTime
) {
  return axios
    .get(
      api.subbasinbeneficary +
        `?dept_id=${deptId}&phase_id=${phaseSubbasinBeneficary}&sub_basin_id=${subbasin_id}`
    )
    .then(({ data }) => {
      if (data[0] && data[0].NULL !== null) {
        const enumdept = {
          1: "tnau",
          2: "agri",
          3: "horti",
          4: "aed",
          5: "ani",
          6: "",
          7: "",
          8: "fish",
        };
        setLoadiingTime(true);
        const dateFinder = (count, gender, genderArray) => {
          switch (gender) {
            case "male":
              genderArray.male = count;
              break;
            case "female":
              genderArray.female = count;
              break;
            case "null":
              genderArray.null = count;
              break;
            default:
          }
        };
        const categoryname = [
          ...new Map(data.map((dd) => [dd["category"], dd])).values(),
        ].map((d) => d.category);

        var finalDataSet = [];

        try {
          for (var iter = 0; iter < categoryname.length; iter++) {
            const gender = {
              male: 0,
              female: 0,
              null: 0,
            };

            data
              .filter((filterVal) => filterVal.category == categoryname[iter])
              .map((d) => {
                return dateFinder(
                  d["count(" + enumdept[deptId] + ".id)"],
                  d.gender,
                  gender
                );
              });
            finalDataSet.push({
              name:
                categoryname[iter] == "others"
                  ? "Others"
                  : String(categoryname[iter]).toUpperCase(),
              data: Object.values(gender),
            });
          }
        } catch (err) {
          console.log(err);
        }

        setBeneficaryData(finalDataSet);
        setTimeout(() => {
          setLoadiingTime(false);
        }, 1000);
      } else setBeneficaryData([]);
    });
}

export function excelbeneficiary(dept_id, phase_id, sub_basin_id) {
  return axios
    .get(
      api.excelbeneficiary +
        `?dept_id=${dept_id}&phase_id=${phase_id}&sub_basin_id=${sub_basin_id}`
    )
    .then(({ data }) => {
      const exceldata = data.filter(
        (val) => val.gender != "" && val.gender != "0" && val.gender != "1"
      );
      exceldata.length > 0 && exportExcel(exceldata, dept_id);
    });
}

export function excelGeotag(dept_id, phase_id, subbasin_id) {
  let endpoints;

  if (dept_id == 0) {
    endpoints = [
      api.excelgeotag + `?phase_id=${phase_id}&dept_id=1&sub_basin_id=0`,
      api.excelgeotag + `?phase_id=${phase_id}&dept_id=2&sub_basin_id=0`,
      api.excelgeotag + `?phase_id=${phase_id}&dept_id=3&sub_basin_id=0`,
      api.excelgeotag + `?phase_id=${phase_id}&dept_id=4&sub_basin_id=0`,
      api.excelgeotag + `?phase_id=${phase_id}&dept_id=5&sub_basin_id=0`,
      api.excelgeotag + `?phase_id=${phase_id}&dept_id=6&sub_basin_id=0`,
      api.excelgeotag + `?phase_id=${phase_id}&dept_id=7&sub_basin_id=0`,
      api.excelgeotag + `?phase_id=${phase_id}&dept_id=8&sub_basin_id=0`,
    ];
  } else {
    endpoints = [
      api.excelgeotag +
        `?phase_id=${phase_id}&dept_id=${dept_id}&sub_basin_id=0`,
    ];
  }

  return axios
    .all(endpoints.map((endpoint) => axios.get(endpoint)))
    .then((dataList) => {
      if (dataList.length > 1) {
        const selectedKeys = {
          a: "Department",
          b: "Count",
          c: "Phase",
          d: "Village Name",
          e: "Block Name",
          f: "District Name",
          g: "Subbasin Name",
        };
        const selectedKeys1 = {
          a: "Department",
          // c: "Category",
          d: "Count",
          e: "Phase",
          f: "Village Name",
          g: "Block Name",
          h: "District Name",
          i: "Subbasin Name",
        };
        const selectedKeys2 = {
          a: "Department",
          // aa: "Training Name",
          b: "Count",
          c: "Phase",
          d: "Village Name",
          e: "Block Name",
          f: "District Name",
          g: "Subbasin Name",
        };

        dataList.map((value, index) => {
          index == 4
            ? value.data.unshift(selectedKeys1)
            : index == 6
            ? value.data.unshift(selectedKeys2)
            : value.data.unshift(selectedKeys);
        });
        const sheetData = [
          {
            name: "TNAU",
            data: dataList[0].data.map(({ ...rest }) => Object.values(rest)),
          },
          {
            name: "Agriculture",
            data: dataList[1].data.map(({ ...rest }) => Object.values(rest)),
          },
          {
            name: "Horticulture",
            data: dataList[2].data.map(({ ...rest }) => Object.values(rest)),
          },
          {
            name: "AED",
            data: dataList[3].data.map(({ ...rest }) => Object.values(rest)),
          },
          {
            name: "Animal Husbandary",
            data: dataList[4].data.map(({ ...rest }) => Object.values(rest)),
          },
          {
            name: "WRD",
            data: dataList[5].data.map(({ ...rest }) => Object.values(rest)),
          },
          {
            name: "Marketing",
            data: dataList[6].data.map(({ ...rest }) => Object.values(rest)),
          },
          {
            name: "Fisheries",
            data: dataList[7].data.map(({ ...rest }) => Object.values(rest)),
          },
        ];
        multipleSheetExcel(sheetData);
      } else {
        console.log("dataList[0].data--->",dataList[0].data);

        dataList[0].data.length > 0 && exportExcel(dataList[0].data, dept_id);
      }
    });
}

export function workCompletedexcel(dept_id, phase_id, date, subbasin_id = "0") {
  const datevalue = date.split("and");
  axios
    .get(
      api.workcompletedgraph +
        `?dept_id=${dept_id}&phase_id=${phase_id}&from_date=${datevalue[0]}&to_date=${datevalue[1]}&sub_basin_id=${subbasin_id}`
    )
    .then((res) => {
      res.data.length > 0 && exportExcel(res.data.filter((val) => val.comp_name !=null), dept_id);
    });
}

export function targetAchievement(
  setCategory,
  setSeries,
  deptid,
  compid,
  year,
  setLoadiingTime,
  setTargetdata
) {
  setLoadiingTime(true);
  axios
    .get(
      api.targetachievement +
        `?dept_id=${deptid}&comp_id=${compid}&yr=${year.split("-")[0]}`
    )
    .then(({ data }) => {
      setTargetdata(data);
      let target = [],
        achieve = [],
        categoryname = [];

      data.map((val) => {
        target.push(val.target);
        achieve.push(val.tag_count);
        categoryname.push(val.district_name);
      });
      let series = [
        {
          name: "Target",
          data: target,
        },
        {
          name: "Achieve",
          data: achieve,
        },
      ];
      setCategory(categoryname);
      setSeries(series);
      setLoadiingTime(false);
    })
    .catch((error) => {
      setLoadiingTime(false);
    });
}
