import React from "react";
import "./style.css";
import { Row, Col, Card } from "antd";
import { FiLayers, FiServer, FiUsers } from "react-icons/fi";
import { FaRegUser, FaRegPaperPlane } from "react-icons/fa";
import { SlSymbolMale, SlSymbleFemale } from "react-icons/sl";
import images from "../../constants/image";

import { isEmpty } from "lodash";

const DashboardCount = ({ dashboardCount }) => {
  return (
    <>
      {!isEmpty(dashboardCount) && (
        <Row
          style={{
              backgroundColor: "#6433D7",
            // height: "110px",
            fontFamily:
              "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
          }}
        >
          <Col md={4}>
            <Card
              style={{
                // margin: "15px",
                margin: "0 15px",
                height: "120px",
                // top: "30px",
                top: "0.5em",
                fontFamily: "Arial",
                backgroundColor: "#536DFE",
                border: "aliceblue",
                borderRadius: "0px",
              }}
            >
              <Row>
                <Col md={16} style={{ bottom: "-10px", color: "white" }}>
                  <div style={{ fontSize: "xx-large", textAlign: "start" }}>
                    {dashboardCount[0].user_count}
                  </div>
                  <div style={{ fontSize: "small", textAlign: "start" }}>
                    Registered Users
                  </div>
                </Col>
                <Col md={8}>
                  <h1>
                    <FiUsers style={{ fontSize: "40px", color: "white" }} />
                  </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={5}>
            <Card
              style={{
                // margin: "15px",
                margin: "0 15px",
                height: "120px",
                // top: "30px",
                top: "0.5em",
                backgroundColor: "#009688",
                border: "aliceblue",
                borderRadius: "0px",
              }}
            >
              <Row>
                <Col md={16} style={{ bottom: "-10px", color: "white" }}>
                  <div style={{ fontSize: "xx-large", textAlign: "start" }}>
                    {dashboardCount[0].geo_tag}
                  </div>
                  <div style={{ fontSize: "small", textAlign: "start" }}>
                    Total Geotagged Counts
                  </div>
                </Col>
                <Col md={8} style={{ paddingTop: "1.3em" }}>
                  <img src={images.ic_geotag_logo} width={"40px"}></img>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={5}>
            <Card
              style={{
                // margin: "15px",
                margin: "0 15px",
                height: "120px",
                // top: "30px",
                top: "0.5em",
                backgroundColor: "#7CB342",
                border: "aliceblue",
                borderRadius: "0px",
              }}
            >
              <Row>
                <Col md={12} style={{ bottom: "-10px", color: "white" }}>
                  <div style={{ fontSize: "xx-large", textAlign: "start" }}>
                    {dashboardCount[0].current_month_geo_tag_count}
                  </div>
                </Col>

                <Col md={12}>
                  <FiServer
                    style={{
                      fontSize: "40px",
                      color: "white",
                      marginTop: "20px",
                    }}
                  />
                </Col>
                <div
                  style={{
                    fontSize: "small",
                    textAlign: "start",
                    marginTop: "-5px",
                    color: "white",
                  }}
                >
                  Current Month Geotagged Counts
                </div>
              </Row>
            </Card>
          </Col>
          <Col md={5}>
            <Card
              style={{
                // margin: "15px",
                margin: "0 15px",
                height: "120px",
                // top: "30px",
                top: "0.5em",
                backgroundColor: "#F7A35C",
                border: "aliceblue",
                borderRadius: "0px",
              }}
            >
              <Row>
                <Col md={12} style={{ bottom: "-10px", color: "white" }}>
                  <div style={{ fontSize: "xx-large", textAlign: "start" }}>
                    {dashboardCount[0].beneficary_male_count == null
                      ? 0
                      : dashboardCount[0].beneficary_male_count}
                  </div>
                </Col>
                <Col md={12}>
                  <SlSymbolMale
                    style={{
                      fontSize: "40px",
                      color: "white",
                      marginTop: "20px",
                    }}
                  />
                </Col>
                <div
                  style={{
                    fontSize: "small",
                    textAlign: "start",
                    marginTop: "-5px",
                    color: "white",
                  }}
                >
                  Beneficiary Male
                </div>
              </Row>
            </Card>
          </Col>
          <Col md={5}>
            <Card
              style={{
                // margin: "15px",
                margin: "0 15px",
                height: "120px",
                // top: "30px",
                top: "0.5em",
                backgroundColor: "#F33A6A",
                border: "aliceblue",
                borderRadius: "0px",
              }}
            >
              <Row>
                <Col md={12} style={{ bottom: "-10px", color: "white" }}>
                  <div style={{ fontSize: "xx-large", textAlign: "start" }}>
                    {dashboardCount[0].beneficary_female_count == null
                      ? 0
                      : dashboardCount[0].beneficary_female_count}
                  </div>
                </Col>
                <Col md={12}>
                  <SlSymbleFemale
                    style={{
                      fontSize: "40px",
                      color: "white",
                      marginTop: "20px",
                    }}
                  />
                </Col>
                <div
                  style={{
                    fontSize: "small",
                    textAlign: "start",
                    marginTop: "-5px",
                    color: "white",
                  }}
                >
                  Beneficiary female
                </div>
              </Row>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DashboardCount;
