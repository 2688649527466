import { Button, Segmented, Form, Input, Card, Breadcrumb, Select } from "antd";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";

import { getBasinData } from "../Report/apiAction";
import { getDepartment } from "../login/apiAction";
import { addUser } from "./apiAction";
import { useForm } from "antd/es/form/Form";

const { Option } = Select;

const User = () => {
  const [userType, setUserType] = useState("Department Admin");
  const [basinList, setBasinList] = useState([]);
  const [departmentList, setDeartmentList] = useState([]);
  const [phase,setPhase] = useState("0");

  const [departmentUserList, setDeartmentUserList] = useState([]);
  const dispatch = useDispatch();
  const [form] = useForm();

  useEffect(() => {
    setPhase("0");
    dispatch(getDepartment(setDeartmentList));
  }, []);

  const onFinish = (values) => {
    const subBasinId = basinList
      .filter((filData) => filData.phase==values.phase &&filData.NAME === values.subbasin)
      .map((data) => data.ID);
    const departmentId = departmentList
      .filter((filData) => filData.NAME === values.department)
      .map((data) => data.ID);
    const time =
      new Date().toISOString().split("T")[0] +
      " " +
      new Date().toISOString().split("T")[1].substr(0, 12);

    const apiData = {
      created_time: time,
      lin_dept: departmentId[0],
      pass_word: values.password,
      updated_at_time: time,
      user_name: values.username,
      // district_id: values.subbasin ? values.subbasin : "0",//!isEmpty(subBasinId) && subBasinId[0],
      district_id: subBasinId?.length > 0 ?subBasinId[0]:"0",
      user_type: userType === "Department Admin" ? 2 : 3,
    };
    console.log("apiData---->",apiData)
    addUser(apiData);
    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Breadcrumb
        separator="/"
        style={{ padding: "20px", fontSize: "large", fontWeight: "lighter" }}
      >
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>User</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          width: window.innerWidth < 600 ? "85vw" : "",
          marginLeft: window.innerWidth < 600 ? "" : "250px",
          marginRight: window.innerWidth < 600 ? "" : "250px",
        }}
      >
        <center>
          <Card
            style={{
              width: window.innerWidth < 600 ? "100vw" : "",

              margin: window.innerWidth < 600 ? "" : "30px",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
          >
            <Form
            form={form}
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: window.innerWidth < 600 ? "" : 600,
                marginTop: "10px",
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Segmented
                style={{ marginBottom: "20px" }}
                options={["Department Admin", "SubBasin"]}
                value={userType}
                onChange={setUserType}
              />
              <Form.Item
                name="department"
                label="Department"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
              >
                <Select showSearch>
                  {departmentList.map((data) => (
                    <Option value={data.NAME}>{data.NAME}</Option>
                  ))}
                </Select>
              </Form.Item>
              {userType === "SubBasin" && (<><Form.Item
                  name="phase"
                  label="Phase"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  hasFeedback
                 
                >
                  <Select  onChange = {(value) => {
                    console.log("val--->",value);setPhase(value);getBasinData(setBasinList);}}>
                  <Option value="1">Phase I</Option>
                  <Option value="2">Phase II</Option>
                  <Option value="3">Phase III</Option>
                  <Option value="4">Phase IV</Option>
                    {/* {!isEmpty(basinList) &&
                      [
                        ...new Map(
                          basinList.map((data) => [data["NAME"], data])
                        ).values(),
                      ].map((data) => (
                        <Option value={data.NAME}>{data.NAME}</Option>
                      ))} */}
                  </Select>
                </Form.Item>
                {console.log("phase----->",phase)}
                <Form.Item
                  name="subbasin"
                  label="Subbasin"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  hasFeedback
                >
                  <Select showSearch>
                    {!isEmpty(basinList) &&
                      [
                        ...new Map(
                          basinList.map((data) => [data["NAME"], data])
                        ).values(),
                      ].map((data) => (
                        <Option value={data.NAME}>{data.NAME}</Option>
                      ))
                      // [
                      //   ...new Map(
                      //     basinList
                      //       .filter(
                      //         (filterData) =>
                      //           Number(filterData.phase) === Number(phase)
                      //       )
                      //       .map((data) => [data["NAME"], data])
                      //   ).values(),
                      // ].map((data) => (
                      //   <Option value={data.ID}>{data.NAME}</Option>
                      // ))
                      }
                  </Select>
                </Form.Item>
                </>
              )}
              <Form.Item
                label="User Name"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your User Name!",
                  },
                ]}
                hasFeedback
              >
                <Input
                  style={{
                    borderColor: "#32be8f",
                    borderRadius: 1,
                    borderTopWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    boxShadow: "none",
                  }}
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  style={{
                    borderColor: "#32be8f",
                    borderRadius: 1,
                    borderTopWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    boxShadow: "none",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="ConfirmPassword"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your Confirm password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  style={{
                    borderColor: "#32be8f",
                    borderRadius: 1,
                    borderTopWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    boxShadow: "none",
                  }}
                />
              </Form.Item>

              <Form.Item
                style={{ marginLeft: window.innerWidth < 600 ? "" : "150px" }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </center>
      </div>
    </>
  );
};
export default User;
