import axios from "axios";
import api from "../../constants/api";
import { message } from "antd";

export function putChangePassword(apiData) {
  return axios
    .post(api.changePwd, apiData)
    .then(({ data }) => {
      message.success("Successfully Changed your Password");
    })
    .catch((error) => {
      message.error("Something went wrong please try again");
    });
}
