import { Collapse, Form, DatePicker, Button, Select } from "antd";

import React, { useEffect, useState } from "react";
import {
  getdistrictData,
  getDepartmentUsers,
  getDepartmentComponents,
  getReportDataList,
  getBasinData,
} from "./apiAction";
import dateFormat from "dateformat";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import moment from "moment";

import { _, isEmpty, get } from "lodash";
import dayjs from "dayjs";

const { Panel } = Collapse;
const { Option } = Select;

const Filter = ({
  reportType,
  setReportData,
  setLoadiingTime,
  width,
  setLayer,
  componentData,
}) => {
  const [districtList, setDistrictList] = useState([]);
  const [basinList, setBasinList] = useState([]);

  const [userList, setUserList] = useState([]);
  const [fieldUser, setFieldUser] = useState("Created By");
  const [componentList, setComponentList] = useState([]);

  const [basinDistrictList, setbasinDistrictList] = useState([]);
  const [basinBlockList, setbasinBlockList] = useState([]);
  const [basinVillageList, setbasinVillageList] = useState([]);
  const [phaseData, setPhaseDaata] = useState("0");
  const [getSubbasinId, setSubbasinId] = useState("0");
  const [fieldSubbasin, setFieldSubbasin] = useState("All Subbasin");
  const [getDistrictId, setDistrictId] = useState("0");
  const [fieldDistrict, setFieldDistrict] = useState("All District");
  const [getBlockId, setBlockId] = useState("0");
  const [fieldBlock, setFieldBlock] = useState("All Block");
  const [getVillageId, setVillageId] = useState("0");
  const [fieldVillage, setFieldVillage] = useState("All Village");
  const [getComponentsId, setComponentsId] = useState("0");
  const [fieldComponent, setFieldComponent] = useState("All Components");
  const [getSubComponentsId, setSubComponentsId] = useState("0");
  const [fieldSubComponent, setFieldSubComponent] =
    useState("All Sub-Components");
  const [getStageId, setStageId] = useState("0");
  const [fieldStage, setFieldStage] = useState("All Stage");
  const [getUserId, setUserId] = useState("0");
  const [getFrom, setFrom] = useState("2008-01-01");
  const [getTo, setTo] = useState(dateFormat(new Date(), "yyyy-mm-dd"));
  const [toChange, setToChange] = useState(false);
  const [clear, setClear] = useState(false);

  const filterBasedPharse = (data) => {
    setSubbasinId("0");
    setFieldSubbasin("All Subbasin");
    setFieldDistrict("All District");
    setDistrictId("0");
    setFieldBlock("All Block");
    setBlockId("0");
    setVillageId("0");
    setFieldVillage("All Village");

    setLayer && setLayer(data);
    setPhaseDaata(data);
  };

  const userInfo = useSelector(
    (state) => get(state, "AuthReducer.userData", []),
    shallowEqual
  );

  const deptCouList = useSelector(
    (state) => get(state, "AppReducer.dataSet", []),
    shallowEqual
  );

  const fromDisableDate = (current) => {
    let customDate = moment().format("YYYY-MM-DD");
    return current && current > moment(customDate, "YYYY-MM-DD").add(1, "days");
  };

  const toDisableDate = (current) => {
    let customDate = moment().format("YYYY-MM-DD");
    return (
      current < moment(getFrom, "YYYY-MM-DD").add(0, "days") ||
      current > moment(customDate, "YYYY-MM-DD").add(1, "days")
    );
  };

  const filterBasedUser = (data) => {
    setFieldUser(data.split("-")[0]);
    const userId = userList
      .filter((filterData) => filterData.user_id === Number(data.split("-")[1]))
      .map((data) => data);
    if (!isEmpty(userId)) {
      setUserId(userId[0].SERIAL_NO);
    } else {
      setUserId("0");
    }
  };
  const onFinish = (values = "") => {
    setReportData([]);
    const apiData = {
      land_id: "0",
      created_by:
        userInfo[0].user_type == "4"
          ? userInfo[0].SERIAL_NO
          : userInfo[0].subbasin
          ? userInfo[0].subbasin
          : getUserId,
      phase_id: phaseData,
      sub_basin_id:
        userInfo[0].user_type == "4"
          ? getSubbasinId != 0
            ? getSubbasinId
            : // userInfo[0].subbasin
              0
          : userInfo[0].SUB_BASIN_ID
          ? userInfo[0].ID //ID used
          : getSubbasinId,
      district_id: getDistrictId,
      block_id: getBlockId,
      village_id: getVillageId,
      components_id: getComponentsId,
      sub_components_id: getSubComponentsId,
      stages_id: getStageId,
      off_set: "0",
      fromdate: getFrom,
      todate: getTo,
    };

    switch (reportType) {
      case "/TNAU":
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          reportType,
          "/tnau",
          // deptCouList[6]?.count
          deptCouList.length > 0 ? deptCouList[6]?.count : deptCouList
        );
        break;
      case "/agri":
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          reportType,
          "/agri",
          // deptCouList[1]?.count
          deptCouList.length > 0 ? deptCouList[1]?.count : deptCouList
        );
        break;

      case "/Horticulture":
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          reportType,
          "/horticulture",
          // deptCouList[4]?.count
          deptCouList.length > 0 ? deptCouList[4]?.count : deptCouList
        );
        break;

      case "/AED":
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          reportType,
          "/tnaed",
          // deptCouList[0]?.count
          deptCouList.length > 0 ? deptCouList[0]?.count : deptCouList
        );
        break;

      case "/AnimalHusbandry":
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          reportType,
          "/animalhusbandary",
          // deptCouList[2]?.count
          deptCouList.length > 0 ? deptCouList[2]?.count : deptCouList
        );
        break;

      case "/WRD":
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          reportType,
          "/wrd",
          // deptCouList[7]?.count
          deptCouList.length > 0 ? deptCouList[7]?.count : deptCouList
        );
        break;
      case "/Marketing":
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          reportType,
          "/marketing",
          // deptCouList[5]?.count
          deptCouList.length > 0 ? deptCouList[5]?.count : deptCouList
        );
        break;

      case "/Fisheries":
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          reportType,
          "/fisheries",
          // deptCouList[3]?.count
          deptCouList.length > 0 ? deptCouList[3]?.count : deptCouList
        );
        break;

      default:
    }
    componentData && componentData(componentList);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const apiCalling = () => {
    switch (reportType) {
      case "/TNAU":
        getBasinData(setBasinList);
        getDepartmentUsers(1, setUserList);
        getDepartmentComponents("inv_tnau", setComponentList);
        break;
      case "/agri":
        getBasinData(setBasinList);
        getDepartmentUsers(2, setUserList);
        getDepartmentComponents(
          "inv_agri",
          setComponentList,
          componentData,
          componentList
        );
        break;
      case "/Horticulture":
        getBasinData(setBasinList);
        getDepartmentUsers(3, setUserList);
        getDepartmentComponents("inv_horti", setComponentList);
        break;
      case "/AED":
        getBasinData(setBasinList);
        getDepartmentUsers(4, setUserList);
        getDepartmentComponents("inv_aed", setComponentList);
        break;
      case "/AnimalHusbandry":
        getBasinData(setBasinList);
        getDepartmentUsers(5, setUserList);
        getDepartmentComponents("inv_husb", setComponentList);
        break;

      case "/WRD":
        getBasinData(setBasinList);
        getDepartmentUsers(6, setUserList);
        getDepartmentComponents("inv_wrd", setComponentList);
        break;
      case "/Marketing":
        getBasinData(setBasinList);
        getDepartmentUsers(7, setUserList);
        getDepartmentComponents("inv_marketing", setComponentList);
        break;
      case "/Fisheries":
        getBasinData(setBasinList);
        getDepartmentUsers(8, setUserList);
        getDepartmentComponents("inv_fishery", setComponentList);
        break;

      default:
        console.log("default");
    }
  };

  useEffect(() => {
    apiCalling();
    setPhaseDaata("0");
    setUserId("0");
    setSubbasinId("0");
    setDistrictId("0");
    setBlockId("0");
    setVillageId("0");
    setComponentsId("0");
    setSubComponentsId("0");
    setStageId("0");
    setFrom("2008-01-01");
    setTo(dateFormat(new Date(), "yyyy-mm-dd"));
    setFieldSubbasin("All Subbasin");
    setFieldDistrict("All District");
    setFieldBlock("All Block");
    setFieldVillage("All Village");
    setFieldComponent("All Components");
    setFieldSubComponent("All Sub-Components");
    setFieldStage("All Stage");
    setFieldUser("Created By");
    setToChange(false);

    // setClear(false);
  }, [reportType]);

  useEffect(() => {
    clear && onFinish();
    setClear(false);
  }, [clear]);

  const FromDate = (date, dateString) => {
    setFrom(dateString);
  };

  const ToDate = (date, dateString) => {
    setTo(dateString);
    setToChange(true);
  };

  const filterBasedSubbasin = (data) => {
    setFieldDistrict("All District");
    setDistrictId("0");
    setFieldBlock("All Block");
    setBlockId("0");
    setVillageId("0");
    setFieldVillage("All Village");
    setFieldSubbasin(data);
    const subBasinId = basinList
      .filter(
        (dataVal) =>
          dataVal.NAME === data && dataVal.phase === Number(phaseData)
      )
      .map((dataList) => dataList);
    if (!isEmpty(subBasinId)) {
      setSubbasinId(subBasinId[0].ID);
      getdistrictData(setDistrictList, subBasinId[0].ID);
    } else {
      setSubbasinId("0");
    }
  };

  const filterBasedComponents = (data) => {
    setFieldSubComponent("All Sub-Components");
    setSubComponentsId("0");
    setFieldStage("All Stage");
    setStageId("0");
    setFieldComponent(data);
    const comId = componentList
      .filter((filterData) => filterData.Name === data)
      .map((data) => data);
    if (!isEmpty(comId)) {
      setComponentsId(comId[0].ID);
    } else {
      setComponentsId("0");
    }
  };

  const filterBasedSubComponents = (data) => {
    setFieldStage("All Stage");
    setStageId("0");
    setFieldSubComponent(data);
    const comId = componentList
      .filter((filterData) => filterData.ID === data)
      .map((data) => data);
    if (!isEmpty(comId)) {
      setSubComponentsId(comId[0].ID);
    } else {
      setSubComponentsId("0");
    }
  };

  const filterBasedStages = (data) => {
    setFieldStage(data);
    const comId = componentList
      .filter((filterData) => filterData.ID === data)
      .map((data) => data);
    if (!isEmpty(comId)) {
      setStageId(comId[0].ID);
    } else {
      setStageId("0");
    }
  };

  const filterBasedDistrict = (data) => {
    setFieldBlock("All Block");
    setBlockId("0");
    setVillageId("0");
    setFieldVillage("All Village");
    setFieldDistrict(data);
    const districtId = districtList
      .filter((dataVal) => dataVal.district_name === data)
      .map((dataList) => dataList);
    if (!isEmpty(districtId)) {
      setDistrictId(districtId[0].district_id);
    } else {
      setDistrictId("0");
    }
    const blockData = districtList
      .filter(
        (dataVal) =>
          dataVal.district_name === data &&
          dataVal.sub_basin_ID === getSubbasinId
      )
      .map((dataList) => dataList);

    setbasinBlockList(blockData);
  };

  // Block
  const filterBasedBlock = (data) => {
    setVillageId("0");
    setFieldVillage("All Village");
    setFieldBlock(data);

    const blockId = districtList
      .filter((dataVal) => dataVal.block_name === data)
      .map((dataList) => dataList);
    if (!isEmpty(blockId)) {
      setBlockId(blockId[0].block_id);
    } else {
      setBlockId("0");
    }

    const villageData = districtList
      .filter(
        (dataVal) =>
          dataVal.block_name === data &&
          dataVal.sub_basin_ID === getSubbasinId &&
          dataVal.district_id === getDistrictId
      )
      .map((data) => data);
    setbasinVillageList(villageData);
  };

  const filterBasedVillage = (data) => {
    // setVillageId(data)
    setFieldVillage(data);
    const villageId = districtList
      .filter(
        (dataVal) =>
          dataVal.sub_basin_ID === getSubbasinId &&
          dataVal.district_id === getDistrictId &&
          dataVal.block_id === getBlockId &&
          dataVal.village_name === data
      )
      .map((data) => data);
    if (!isEmpty(villageId)) {
      setVillageId(villageId[0].village_id);
    } else {
      setVillageId("0");
    }
  };

  const phaseRoman = ["I", "II", "III", "IV"];

  return (
    <>
      <Form
        name="basic"
        layout={window.innerWidth < 600 ? "" : "inline"}
        style={{
          marginTop: "10px",
          marginRight: "15px",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="CreatedBy"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            <Select
              onChange={filterBasedUser}
              showSearch
              value={fieldUser}
              disabled={
                !isEmpty(userInfo) &&
                (userInfo[0].user_type === "4" ? true : false)
              }
            >
              <Option value="0">Created By</Option>

              {!isEmpty(userList) &&
              userList[0].subbasin &&
              userInfo[0].ID != null
                ? [
                    ...new Map(
                      userList
                        .filter(
                          (fdata) =>
                            userInfo[0].ID &&
                            userInfo[0].ID != null &&
                            userInfo[0].ID == fdata.subbasin
                        )
                        .map((data) => [data["user_id"], data])
                    ).values(),
                  ].map((data) => (
                    <Option value={data.user_name+ "-" + data.user_id}>{data.user_name}</Option>
                  ))
                : [
                    ...new Map(
                      userList.map((data) => [data["user_id"], data])
                    ).values(),
                  ].map((data) => {
                    return <Option value={data.user_name+ "-" + data.user_id}>{data.user_name}</Option>
})}
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="AllPhases"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            <Select showSearch onChange={filterBasedPharse} value={phaseData}>
              <Option value="0">All Phases</Option>
              {userInfo[0].user_type == "4" || userInfo[0].user_type == "3" ? (
                <Option value={userInfo[0].phase}>
                  Phase {phaseRoman[userInfo[0].phase - 1]}
                </Option>
              ) : (
                <>
                  <Option value="1">Phase I</Option>
                  <Option value="2">Phase II</Option>
                  <Option value="3">Phase III</Option>
                  <Option value="4">Phase IV</Option>
                </>
              )}
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="AllSubbasin"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            <Select
              onChange={filterBasedSubbasin}
              showSearch
              value={fieldSubbasin}
            >
              <Option value={"0"}>{"All Subbasin"}</Option>

              {!isEmpty(basinList) && userInfo[0].SUB_BASIN_ID == null
                ? userInfo[0].subbasin
                  ? [
                      ...new Map(
                        basinList
                          .filter(
                            (filterData) =>
                              Number(filterData.phase) === Number(phaseData)
                          )
                          .map((data) => [data["NAME"], data])
                      ).values(),
                    ].map((data) => (
                      <Option value={data.NAME}>{data.NAME}</Option>
                    ))
                  : [
                      ...new Map(
                        basinList
                          .filter(
                            (filterData) =>
                              Number(filterData.phase) === Number(phaseData)
                          )
                          .map((data) => [data["NAME"], data])
                      ).values(),
                    ].map((data) => (
                      <Option value={data.NAME}>{data.NAME}</Option>
                    ))
                : [
                    ...new Map(
                      basinList
                        .filter(
                          (filterData) =>
                            Number(filterData.phase) === Number(phaseData) &&
                            userInfo[0].SUB_BASIN_ID &&
                            userInfo[0].ID == filterData.ID
                        )
                        .map((data) => [data["NAME"], data])
                    ).values(),
                  ].map((data) => (
                    <Option value={data.NAME}>{data.NAME}</Option>
                  ))}
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="AllDistrict"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            <Select
              onChange={filterBasedDistrict}
              showSearch
              value={fieldDistrict}
            >
              <Option value={"0"}>{"All District"}</Option>
              {!isEmpty(districtList) &&
                [
                  ...new Map(
                    districtList.map((data) => [data["district_name"], data])
                  ).values(),
                ].map((data) => (
                  <Option value={data.district_name}>
                    {data.district_name}
                  </Option>
                ))}
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="AllBlock"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            <Select onChange={filterBasedBlock} showSearch value={fieldBlock}>
              <Option value={"0"}>{"All Block"}</Option>
              {!isEmpty(basinBlockList) &&
                [
                  ...new Map(
                    basinBlockList.map((data) => [data["block_name"], data])
                  ).values(),
                ].map((data) => (
                  <Option value={data.block_name}>{data.block_name}</Option>
                ))}
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="AllVillage"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            <Select
              placeholder="All Village"
              onChange={filterBasedVillage}
              showSearch
              value={fieldVillage}
            >
              <Option value={"0"}>{"All Village"}</Option>
              {!isEmpty(basinVillageList) &&
                [
                  ...new Map(
                    basinVillageList.map((data) => [data["village_name"], data])
                  ).values(),
                ].map((data) => (
                  <Option value={data.village_name}>{data.village_name}</Option>
                ))}
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="Components"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            <Select
              placeholder="Components"
              onChange={filterBasedComponents}
              showSearch
              value={fieldComponent}
            >
              <Option value={"0"}>{"All Components"}</Option>
              {!isEmpty(componentList) &&
                [
                  ...new Map(
                    componentList
                      .filter(
                        (filterList) =>
                          filterList.PARENT_ID === 0 ||
                          filterList.PARENT_ID === null
                      )
                      .map((data) => [data["Name"], data])
                  ).values(),
                ].map((data) => <Option value={data.Name}>{data.Name}</Option>)}
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="subComponents"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            <Select
              placeholder="sub Components"
              onChange={filterBasedSubComponents}
              showSearch
              value={fieldSubComponent}
            >
              <Option value={"0"}>{"All Sub-Components"}</Option>
              {!isEmpty(componentList) &&
                [
                  ...new Map(
                    componentList
                      .filter(
                        (filterList) => filterList.PARENT_ID === getComponentsId
                      )
                      .map((data) => [data["Name"], data])
                  ).values(),
                ].map((data) => <Option value={data.ID}>{data.Name}</Option>)}
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="Stages"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            <Select
              placeholder="Stages"
              onChange={filterBasedStages}
              showSearch
              value={fieldStage}
            >
              <Option value={"0"}>{"All Stage"}</Option>
              {!isEmpty(componentList) &&
                [
                  ...new Map(
                    componentList
                      .filter(
                        (filterList) =>
                          filterList.PARENT_ID === getSubComponentsId
                      )
                      .map((data) => [data["Name"], data])
                  ).values(),
                ].map((data) => <Option value={data.ID}>{data.Name}</Option>)}
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="From"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            {getFrom == "2008-01-01" ? (
              <DatePicker
                placeholder="From"
                onChange={FromDate}
                disabledDate={fromDisableDate}
              />
            ) : (
              <DatePicker
                value={dayjs(getFrom)}
                onChange={FromDate}
                disabledDate={fromDisableDate}
              />
            )}
          </div>
        </Form.Item>
        <Form.Item
          name="To"
          style={{
            width: window.innerWidth < 600 ? "" : width,
            marginTop: "10px",
          }}
        >
          <div>
            {!toChange ? (
              <DatePicker
                placeholder="To"
                onChange={ToDate}
                disabledDate={toDisableDate}
              />
            ) : (
              <DatePicker
                value={dayjs(getTo)}
                onChange={ToDate}
                disabledDate={toDisableDate}
              />
            )}
          </div>
        </Form.Item>

        <Form.Item
          style={{
            marginTop: "10px",
            // marginLeft: "30px"
          }}
        >
          <div style={{ display: "flex", gap: "0.8em" }}>
            <Button
              type="primary"
              onClick={() => {
                setPhaseDaata("0");
                setUserId("0");
                setSubbasinId("0");
                setDistrictId("0");
                setBlockId("0");
                setVillageId("0");
                setComponentsId("0");
                setSubComponentsId("0");
                setStageId("0");
                setFrom("2008-01-01");
                setTo(dateFormat(new Date(), "yyyy-mm-dd"));
                setFieldSubbasin("All Subbasin");
                setFieldDistrict("All District");
                setFieldBlock("All Block");
                setFieldVillage("All Village");
                setFieldComponent("All Components");
                setFieldSubComponent("All Sub-Components");
                setFieldStage("All Stage");
                setFieldUser("Created By");
                setToChange(false);
                setClear(true);
                // setFieldUser("Created By");
                // setUserId("0");
                // setPhaseDaata("0");
                // setSubbasinId("0");
                // setFieldSubbasin("All Subbasin");
                // setFieldDistrict("All District");
                // setDistrictId("0");
                // setFieldBlock("All Block");
                // setBlockId("0");
                // setVillageId("0");
                // setFieldVillage("All Village");
                // setComponentsId("0");

                // setFieldSubComponent("All Sub-Components");
                // setSubComponentsId("0");
                // setFieldStage("All Stage");
                // setStageId("0");
              }}
              style={{ width: "4.7em" }}
            >
              Clear
            </Button>
            <Button type="primary" htmlType="submit" style={{ width: "5.1em" }}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default Filter;
