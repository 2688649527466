import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spin } from "antd";
import { TbBrightness2 } from "react-icons/tb";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { get, isEmpty } from "lodash";
import {
  getDashboardByUsersCount,
  GeoTagDashboard,
  interventionbyvillageApi,
  interventionByuser,
  getBeneficaryDetails,
} from "./apiAction";
import { getDepartment } from "../login/apiAction";
import ColumnChart from "./columnChart";
import DoubleChart from "./DoubleChart";
import VariablepieChart from "./variablepie";
import LineChart from "./lineChart";
import PhaseType from "./phsaeType";
import DepartmentType from "./departmentType";
import CommonDashboard from "./CommonDashboard";
import ColumnChartBeneficary from "./columnChartBeneficary";
import "./style.css";
import { exportExcel } from "../../actions/exportExcelAction";

const Dashboard = () => {
  const antIcon = <TbBrightness2 className="spinStyle" />;

  const [userCountData, setUserCountData] = useState([]);
  const [userCountDrillDown, setCountDrilDown] = useState([]);
  const [geoCountData, setGeoCountData] = useState([]);
  const [geoCountDrillDown, setGeoDrilDown] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [WorkProcessData, setWorkProcessData] = useState([]);
  const [departmentId, setgeoTagId] = useState("1");
  const [phaseType, setPhaseType] = useState("0");
  const [phaseTypeGeoTag, setGeoTag] = useState("0");
  const [phaseGeoTag, setPhaseGeoTag] = useState("0");
  const [loadingTime, setLoadiingTime] = useState(false);
  const [dateVal, setDateRange] = useState("");
  const [dateVal1, setDate1] = useState("2018-04-01");
  const [dateVal2, setDate2] = useState("2019-3-31");
  const [beneficaryData, setBeneficaryData] = useState([]);
  const [phaseBeneficary, setBeneficaryPhase] = useState("0");

  const dispatch = useDispatch();

  const phasevalue = {
    0: "All Phases",
    1: "Phase I",
    2: "Phase II",
    3: "Phase III",
    4: "Phase IV",
  };

  const userInfo = useSelector(
    (state) => get(state, "AuthReducer.userData", []),
    shallowEqual
  );

  useEffect(() => {
    dispatch(
      interventionByuser(
        setUserCountData,
        phaseType,
        userInfo[0].line_dept,
        userInfo[0].user_name
      )
    );
  }, [phaseType]);

  useEffect(() => {
    interventionbyvillageApi(
      setGeoCountData,
      phaseGeoTag,
      userInfo[0].line_dept,
      userInfo[0].user_name
    );
  }, [phaseGeoTag]);

  return (
    <>
      <div>
        <center>
          <Spin
            tip="Fetching data ..."
            spinning={loadingTime}
            size="large"
            indicator={antIcon}
            style={{ color: "#673ab7", fontWeight: "bold" }}
          >
            {userInfo.length != 0 &&
            userInfo[0].district != null &&
            userInfo[0].user_type === "3" ? (
              <CommonDashboard userInfo={userInfo} />
            ) : (
              <>
                <Row>
                  <Col md={24}>
                    <Card style={{ margin: "15px" }}>
                      <div
                        style={{
                          height: window.innerWidth < 600 ? "" : "485px",
                          width: window.innerWidth < 600 ? "" : "950px",
                        }}
                      >
                        <Row
                          style={{
                            justifyContent: "center",
                          }}
                        >
                          <Col span={6}>
                            <PhaseType setPhaseType={setPhaseType} userInfo={userInfo}/>
                          </Col>
                        </Row>
                        <div style={{ marginTop: "20px" }}>
                          <DoubleChart
                            // title={"Intervention / Component Count"}
                            title = {"Geotagged Counts"}
                            // y={"Count"}
                            y={"No. of geotagged counts"}
                            dataList={userCountData}
                            drilldownData={userCountDrillDown}
                            widthVal={window.innerWidth}
                            type="column"
                            phase={phasevalue[phaseType]}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </Spin>
        </center>
      </div>
    </>
  );
};

export default Dashboard;
