import React, { Component } from "react";
import OlMap from "ol/Map";
import OlView from "ol/View";
import TileLayer from "ol/layer/Tile";
import GeoJSON from "ol/format/GeoJSON";
import ImageWMS from "ol/source/ImageWMS";
import dateFormat from "dateformat";
import image9 from "./images/icon 9.png";
import image1 from "./images/icon 1.png";
import image3 from "./images/icon 3.png";
import image4 from "./images/icon 4.png";
import image5 from "./images/icon 5.png";
import image6 from "./images/icon 6.png";
import image7 from "./images/icon 7.png";
import image8 from "./images/icon 8.png";
import image10 from "./images/icon 10.png";
import image11 from "./images/icon 11.png";
import image12 from "./images/icon 12.png";
import image14 from "./images/icon 14.png";
import image15 from "./images/icon 15.png";
import image17 from "./images/icon 17.png";
import image18 from "./images/icon 18.png";
import image19 from "./images/icon 19.png";
import image20 from "./images/icon 20.png";
import image21 from "./images/icon 21.png";
import image22 from "./images/icon 22.png";
import image23 from "./images/icon 23.png";
import image24 from "./images/icon 24.png";
import image25 from "./images/icon 25.png";
import image26 from "./images/icon 26.png";
import image27 from "./images/icon 27.png";
import image28 from "./images/icon 28.png";
import image29 from "./images/icon 29.png";
import image30 from "./images/icon 30.png";
import image31 from "./images/icon 31.png";
import image32 from "./images/icon 32.png";
import image33 from "./images/icon 33.png";
import image34 from "./images/icon 34.png";
import image36 from "./images/icon 36.png";
import image37 from "./images/icon 37.png";
import image38 from "./images/icon 38.png";
import image40 from "./images/icon 40.png";
import image41 from "./images/icon 41.png";
import image42 from "./images/icon 42.png";
import image43 from "./images/icon 43.png";
import image44 from "./images/icon 44.png";
import image45 from "./images/icon 45.png";
import image46 from "./images/icon 46.png";
import image47 from "./images/icon 47.png";
import image48 from "./images/icon 48.png";
import image49 from "./images/icon 49.png";
import image50 from "./images/icon 50.png";
import image51 from "./images/icon 51.png";
import image52 from "./images/icon 52.png";
import image54 from "./images/icon 54.png";
import image55 from "./images/icon 55.png";
import image56 from "./images/icon 56.png";
import image59 from "./images/icon 59.png";
import image60 from "./images/icon 60.png";
import image61 from "./images/icon 61.png";
import image62 from "./images/icon 62.png";
import image63 from "./images/icon 63.png";
import image64 from "./images/icon 64.png";
import image65 from "./images/icon 65.png";
import image67 from "./images/icon 67.png";
import image68 from "./images/icon 68.png";
import image69 from "./images/icon 69.png";
import image70 from "./images/icon 70.png";
import image72 from "./images/icon 72.png";
import image73 from "./images/icon 73.png";
import image74 from "./images/icon 74.png";
import image75 from "./images/icon 75.png";
import image76 from "./images/icon 76.png";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import { useDispatch, useSelector, shallowEqual, connect } from "react-redux";
import DepartmentType from "../Dashboard/departmentType";
import { getDepartment } from "./apiAction";
import Zoom from "ol/control/Zoom";
import { defaults } from "ol/interaction";
import { doubleClick } from "ol/events/condition";
import Select from "ol/interaction/Select";
import { Fill, Stroke, Style, Text, Icon } from "ol/style";
import windowDimensions from "react-window-dimensions";
import PropType from "prop-types";
import { createStringXY } from "ol/coordinate";
import axios from "axios";
import { isEmpty, get } from "lodash";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import MousePosition from "ol/control/MousePosition";
import { Vector as VectorSource } from "ol/source";
import { ScaleLine, FullScreen } from "ol/control";
import "ol/ol.css";
import "./styles/map.css";
import { transform } from "ol/proj";
import XYZ from "ol/source/XYZ";
import "./styles/ol-popup.css";
import Popup from "ol-popup";
import { Tooltip, Row, Col, Card, Checkbox, Spin, Button, Image } from "antd";
import Filter from "../Report/filter";
import { TbZoomOutArea, TbBrightness2, TbArrowBarToDown } from "react-icons/tb";
import {
  getReportDataList,
  getImage,
  getDepartmentComponents,
} from "../Report/apiAction";
import { Images } from "./Image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Overlay } from "ol";

const center = transform([78.37, 10.62], "EPSG:4326", "EPSG:3857");
let boundarySourceDistrict;
const List = [
  "districts",
  "PhaseISubBasin",
  "PhaseIISubBasin",
  "PhaseIIISubBasin",
  "PhaseIVSubBasin",
];
let value;
const deptPathName = [
  "/TNAU",
  "/agri",
  "/Horticulture",
  "/AED",
  "/AnimalHusbandry",
  "/WRD",
  "/Marketing",
  "/Fisheries",
  "",
];
let enumimage;
const generateStyle = (strokeClr, fillClr, text, txtFillClr) =>
  new Style({
    stroke: new Stroke({
      color: strokeClr,
      width: 3,
    }),
    fill: new Fill({
      color: fillClr,
    }),
    text: new Text({
      text: text,
      fill: new Fill({
        color: txtFillClr,
      }),
    }),
  });
const normaliseBorder = (feature) => {
  return generateStyle(
    "yellow",
    "rgba(255, 255, 0, 0)",
    feature.values_.BLKNAME,
    "#000"
  );
};
let popup;
let enumdepttype = {
  1: "inv_tnau",
  2: "inv_agri",
  3: "inv_horti",
  4: "inv_aed",
  5: "inv_husb",
  6: "inv_wrd",
  7: "inv_marketing",
  8: "inv_fishery",
};
const antIcon = <TbBrightness2 className="spinStyle" />;

class GeoMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 1,
      level: -1,
      rotationVal: 0,
      fullExtent: false,
      count: 0,
      mapMarkerDetails: [],
      setLoadiing: [],
      UInfo: [],
      deptTypePath: "",
      deptComponents: "",
      detId: "2",
      deptList: [],
      setDepartment: "",
      userRole: "",
      featureDetails: {},
      img2: "",
      img1: "",
      layer: [],
      selecedLayer: [
        "districts",
        "PhaseISubBasin",
        "PhaseIISubBasin",
        "PhaseIIISubBasin",
        "PhaseIVSubBasin",
      ],
      setComponent: [],
      counts: this.props.count,
      showReportData: false,
    };
    this.draw = null;
    this.element1Ref = React.createRef();
    this.element2Ref = React.createRef();
    this.containerRef = React.createRef();
  }

  configureMap = () => {
    let borderSource = new VectorSource();
    this.borderLayer = new VectorLayer({
      source: borderSource,
      style: (f) => normaliseBorder(f),
      crossOrigin: "anonymous",
    });
    this.borderLayer.setZIndex(0);
    this.tamilNadu = new ImageLayer({
      source: new ImageWMS({
        url: "https://agrex-demo.farmwiseai.com/geoserver/Puvi/wms",
        params: {
          LAYERS: `Puvi:district_boundary`,
        },
        transition: 0,
        crossOrigin: "anonymous",
      }),
    });
    this.tamilNadu.setZIndex(1);
    this.tniampSubBasinPhase1 = new ImageLayer({
      source: new ImageWMS({
        url: "https://agrex-demo.farmwiseai.com/geoserver/Tniamp/wms",
        params: {
          LAYERS: `Tniamp:tniamp_sub_basin_ph1`,
        },
        transition: 0,
        crossOrigin: "anonymous",
      }),
    });
    this.tniampSubBasinPhase1.setZIndex(2);
    this.tniampSubBasinPhase2 = new ImageLayer({
      source: new ImageWMS({
        url: "https://agrex-demo.farmwiseai.com/geoserver/Tniamp/wms",
        params: {
          LAYERS: `Tniamp:tniamp_sub_basin_ph2`,
        },
        transition: 0,
        crossOrigin: "anonymous",
      }),
    });
    this.tniampSubBasinPhase2.setZIndex(3);
    this.tniampSubBasinPhase3 = new ImageLayer({
      source: new ImageWMS({
        url: "https://agrex-demo.farmwiseai.com/geoserver/Tniamp/wms",
        params: {
          LAYERS: `Tniamp:tniamp_phase3`,
        },

        transition: 0,
        crossOrigin: "anonymous",
      }),
    });
    this.tniampSubBasinPhase3.setZIndex(4);
    this.tniampSubBasinPhase4 = new ImageLayer({
      source: new ImageWMS({
        url: "https://agrex-demo.farmwiseai.com/geoserver/Tniamp/wms",
        params: {
          LAYERS: `Tniamp:tniamp_phase4`,
        },
        transition: 0,
        crossOrigin: "anonymous",
      }),
    });
    this.tniampSubBasinPhase4.setZIndex(5);
    this.view = new OlView({
      center,
      zoom: this.state.zoom,
      rotation: this.state.rotationVal,
    });
    var worldImagery = new TileLayer({
      source: new XYZ({
        attributions: [
          "Powered by Esri",
          "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
        ],
        attributionsCollapsible: false,
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        maxZoom: 18,
        crossOrigin: "anonymous",
      }),
    });
    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: "EPSG:4326",

      className: "custom-mouse-position",
      target: document.getElementById("mouse-position"),
    });
    const vectorSource1 = new VectorSource({
      features: [],
    });
    this.vectorLayer1 = new VectorLayer({
      source: vectorSource1,
    });
    this.vectorLayer1.setZIndex(101);

    this.olmap = new OlMap({
      interactions: defaults({
        doubleClickZoom: false,
      }),
      target: undefined,
      layers: [
        worldImagery,
        this.vectorLayer1,

        this.borderLayer,
        this.tamilNadu,
        this.tniampSubBasinPhase1,
        this.tniampSubBasinPhase2,
        this.tniampSubBasinPhase3,
        this.tniampSubBasinPhase4,
      ],
      controls: [
        new Zoom({
          className: "zoom",
        }),
        new ScaleLine(),
        new FullScreen(),
        mousePositionControl,
      ],
      view: this.view,
    });
    this.tamilNadu.setVisible(true);
    this.tniampSubBasinPhase1.setVisible(true);
    this.tniampSubBasinPhase2.setVisible(true);
    this.tniampSubBasinPhase3.setVisible(true);
    this.tniampSubBasinPhase4.setVisible(true);
    var customContextMenu = new Overlay({
      element: document.getElementById("custom-context-menu"),
      positioning: "top-left",
    });
    this.olmap.addOverlay(customContextMenu);
    this.olmap.on("contextmenu", function (event) {
      event.preventDefault();
    });
    this.olmap.on("contextmenu", (e) => {
      const coordinate = e.coordinate;
      customContextMenu.setPosition(coordinate);
      document.getElementById("custom-context-menu").style.display = "block";
      document.getElementById("custom-context-menu").style.cursor = "pointer";
    });
    this.olmap.on("click", () => {
      document.getElementById("custom-context-menu").style.display = "none";
    });
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        contextMenuContainer.style.display = "none";
      }
    });
  };

  showPop = () => {
    this.olmap.on("click", (event) => {
      // this.setState({ featureDetails: {} })
      const featurevalue=[];
      this.olmap.forEachFeatureAtPixel(event.pixel, (feature) => {
        featurevalue.push(feature)
      });
        var ff = `<div>Subbasin: Tirumanimuttar
        Village: Thoppapatti
        Component: Nutri Cereals
        Sub Component: null
        Stage: null
        Name: Natarajan</div>`;
        var content = document.getElementById("markerCard");


        if (featurevalue.length> 0 && featurevalue[0].get("type") === "Point") {
          
          console.log(`feature.get("tagPoint").ID--->`,featurevalue[0].get("tagPoint").ID)
          getImage(
            this.setImg1,
            this.setImg2,
            featurevalue[0].get("tagPoint").ID,
            featurevalue[0].get("tagPoint").dept_id
          );
          this.setState({ featureDetails: featurevalue[0].get("tagPoint") });
          this.setState({showReportData:false})
          popup.show(event.coordinate, content);
        }
      
    });
  };

  updateMap() {
    if (this.olmap) this.olmap.getView(); // .setZoom(this.state.zoom);
  }
  iconList = () => {
    switch (Number(this.state.detId)) {
      case 1:
        return [
          image1,
          image69,
          image3,
          image4,
          image5,
          image6,
          image7,
          image8,
          image9,
          image10,
          image11,
          image12,
          image37,
        ];
      case 2:
        return [
          image14,
          image15,
          image36,
          image17,
          image18,
          image19,
          image20,
          image21,
          image22,
          image23,
          image24,
        ];
      case 3:
        return [
          image25,
          image26,
          image27,
          image28,
          image29,
          image30,
          image31,
          image32,
          image33,
        ];
      case 4:
        return [image34, image45, image36, image37];
      case 5:
        return [
          image38,
          image68,
          image40,
          image41,
          image42,
          image43,
          image44,
          image45,
          image46,
        ];
      case 6:
        return [
          image47,
          image48,
          image49,
          image50,
          image51,
          image52,
          image3,
          image54,
          image55,
          image56,
        ];
      case 7:
        return [image1, image70, image59, image60, image61, image62, image63];
      case 8:
        return [
          image64,
          image65,
          image3,
          image67,
          image68,
          image69,
          image14,
          image36,
          image72,
          image73,
          image74,
          image75,
          image76,
        ];
    }
  };

  componentDidUpdate() {
    enumimage = this.iconList();
    if (this.state.layer.length != 0) {
      this.layerVisibleCheckList(this.state.layer, true);
    }
    if (this.state.fullExtent) {
      setTimeout(() => {
        this.olmap
          .getView()
          .fit(
            [
              8486216.857004646, 902189.4079034224, 8944389.503375025,
              1524109.779610402,
            ],
            { duration: 500 }
          );
      }, 500);
      this.setState({ fullExtent: false });
    }
  }

  componentWillUnmount() {
    this.olmap.setTarget(undefined);
  }

  select = new Select({
    condition: doubleClick,
  });

  setImg1 = (data) => {
    this.setState({ img1: data });
  };

  setImg2 = (data) => {
    this.setState({ img2: data });
  };

  deptListData = (data) => {
    this.setState({ deptList: data });
  };

  getDetId = (data) => {
    var getDepartment = deptPathName[data - 1];
    this.setState({ deptTypePath: getDepartment });
    this.setState({});
  };

  getImage = ({ component }) => {
    switch (component) {
      case "Model Village":
        return mark;

      default:
        return markerImg1;
    }
  };

  stationarker = (locationData) => {
    console.log("locationData--->",locationData)
    locationData.length > 0 &&
      locationData.map((element) => {
        if (
          element.component != "" &&
          element.component != 0 &&
          this.state.setComponent.indexOf(element.component) != -1
        ) {
          let imageele = "";
          imageele =
            enumimage[this.state.setComponent.indexOf(element.component)];

          if (imageele) {
            var iconFeature = new Feature({
              geometry: new Point(
                fromLonLat([
                  Number(element.photo_lon),
                  Number(element.photo_lat),
                ])
              ),
              type: "Point",
              tagPoint: element,
            });

            const iconStyle = new Style({
              image: new Icon({
                width: 50,
                src: imageele,
              }),
            });

            iconFeature.setStyle(iconStyle);

            const layerSource = this.olmap.getLayers().array_[1].getSource();
            if (layerSource) {
              layerSource.addFeature(iconFeature);
            }
          }
        }
      });
  };

  componentDidMount() {
    this.configureMap();
    this.olmap.setTarget("draw-map");
    this.olmap.on("moveend", () => {
      let zoom = this.olmap.getView().getZoom();
      this.setState({ zoom });
    });
    var roleId = sessionStorage.getItem("art");
    var detId = sessionStorage.getItem("ald");
    var subbasin_id = sessionStorage.getItem("Sid");
    var userid = sessionStorage.getItem("IUid");
    var dataCount = JSON.parse(sessionStorage.getItem("dataCount"));
    this.setState({ userRole: roleId });
    const apiData = {
      land_id: "0",
      created_by: this.props.userInfo[0].SERIAL_NO
        ? this.props.userInfo[0].SERIAL_NO
        : "0",
      phase_id: "0",
      sub_basin_id: this.props.userInfo[0].SUB_BASIN_ID
        ? this.props.userInfo[0].ID
        : "0", //subbasin_id === "null" || subbasin_id === 'undefined'  ? "0":subbasin_id,
      district_id: "0",
      block_id: "0",
      village_id: "0",
      components_id: "0",
      sub_components_id: "0",
      stages_id: "0",
      off_set: "0",
      fromdate: "2008-01-01",
      todate: dateFormat(new Date(), "yyyy-mm-dd"),
    };
    switch (detId) {
      case "1":
        getReportDataList(
          this.setReportData,
          apiData,
          this.setLoadiingTime,
          "/tnau",
          "/tnau",
          this.state.counts.length > 0
            ? this.state.counts[6]?.count
            : this.state.counts
        );
        this.setState({ deptTypePath: "/TNAU", detId: "1" });
        break;
      case "2":
        getReportDataList(
          this.setReportData,
          apiData,
          this.setLoadiingTime,
          "/agri",
          "/agri",
          this.props.count.length > 0
            ? this.state.counts[1]?.count
            : this.props.count
        );
        this.setState({ deptTypePath: "/agri", detId: "2" });
        break;
      case "3":
        getReportDataList(
          this.setReportData,
          apiData,
          this.setLoadiingTime,
          "/horticulture",
          "/horticulture",
          this.props.count.length > 0
            ? this.state.counts[4]?.count
            : this.props.count
        );
        this.setState({ deptTypePath: "/Horticulture", detId: "3" });
        break;
      case "4":
        getReportDataList(
          this.setReportData,
          apiData,
          this.setLoadiingTime,
          "/tnaed",
          "/tnaed",
          this.props.count.length > 0
            ? this.state.counts[0]?.count
            : this.props.count
        );
        this.setState({ deptTypePath: "/AED", detId: "4" });
        break;
      case "5":
        getReportDataList(
          this.setReportData,
          apiData,
          this.setLoadiingTime,
          "/animalhusbandary",
          "/animalhusbandary",
          this.props.count.length > 0
            ? this.state.counts[2]?.count
            : this.props.count
        );
        this.setState({ deptTypePath: "/AnimalHusbandry", detId: "5" });
        break;
      case "6":
        getReportDataList(
          this.setReportData,
          apiData,
          this.setLoadiingTime,
          "/wrd",
          "/wrd",
          this.props.count.length > 0
            ? this.state.counts[7]?.count
            : this.props.count
        );
        this.setState({ deptTypePath: "/WRD", detId: "6" });
        break;
      case "7":
        getReportDataList(
          this.setReportData,
          apiData,
          this.setLoadiingTime,
          "/marketing",
          "/marketing",
          this.props.count.length > 0
            ? this.state.counts[5]?.count
            : this.props.count
        );
        this.setState({ deptTypePath: "/Marketing", detId: "7" });
        break;
      case "8":
        getReportDataList(
          this.setReportData,
          apiData,
          this.setLoadiingTime,
          "/fisheries",
          "/fisheries",
          this.props.count.length > 0
            ? this.state.counts[3]?.count
            : this.props.count
        );
        this.setState({ deptTypePath: "/Fisheries", detId: "8" });
        break;
      default:
        getDepartment(this.deptListData);
        getReportDataList(
          this.setReportData,
          apiData,
          this.setLoadiingTime,
          "/agri",
          "/agri",
          this.props.count.length > 0
            ? this.state.counts[1]?.count
            : this.props.count
        );
        this.setState({ deptTypePath: "/agri", detId: "2" });
    }
    getDepartmentComponents(enumdepttype[detId], "", this.handleComponent);
    if (this.state.count === 0) {
      this.state.count = 1;
      axios
        .get(
          `https://agrex-demo.farmwiseai.com/geoserver/Puvi/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Puvi%3Adistrict_boundary&outputFormat=application%2Fjson`
        )
        .then((data) => {
          this.setBoundarySourceData(data.data, 1);
          setTimeout(() => {
            this.showPop();
            popup = new Popup();
            this.olmap.addOverlay(popup);
          }, 1000);
        });
    }
  }

  setLoadiingTime = (data) => {
    this.setState({ setLoadiing: data });
  };

  setLayerlist = (data) => {
    const list = [
      "districts",
      "PhaseISubBasin",
      "PhaseIISubBasin",
      "PhaseIIISubBasin",
      "PhaseIVSubBasin",
    ];
    const a = ["districts"];
    a.push(list[data]);
    if (data != 0) {
      this.setState({ selecedLayer: a });
      this.setState({ layer: a });
    } else {
      this.setState({ selecedLayer: list });
      this.setState({ layer: list });
    }
  };

  setReportData = (data) => {
    if (!isEmpty(this.olmap.getLayers().array_[1].getSource())) {
      this.olmap.getLayers().array_[1].getSource().clear();
    }
    setTimeout(() => {
      this.stationarker(data);
    },10)
    

    this.setState({ mapMarkerDetails: data });
  };

  layerVisibleCheckList = (layerList, didcall) => {
    if (didcall != true) {
      this.setState({ selecedLayer: layerList });
      this.setState({ layer: layerList });
    }
    if (!layerList.includes("districts")) {
      this.tamilNadu.setVisible(false);
    } else {
      this.tamilNadu.setVisible(true);
    }
    if (!layerList.includes("PhaseISubBasin")) {
      this.tniampSubBasinPhase1.setVisible(false);
    } else {
      this.tniampSubBasinPhase1.setVisible(true);
    }

    if (!layerList.includes("PhaseIISubBasin")) {
      this.tniampSubBasinPhase2.setVisible(false);
    } else {
      this.tniampSubBasinPhase2.setVisible(true);
    }

    if (!layerList.includes("PhaseIIISubBasin")) {
      this.tniampSubBasinPhase3.setVisible(false);
    } else {
      this.tniampSubBasinPhase3.setVisible(true);
    }

    if (!layerList.includes("PhaseIVSubBasin")) {
      this.tniampSubBasinPhase4.setVisible(false);
    } else {
      this.tniampSubBasinPhase4.setVisible(true);
    }
  };

  setBoundarySourceData = (data, type) => {
    boundarySourceDistrict = new VectorSource({
      features: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }).readFeatures(data),
    });

    setTimeout(() => {
      this.olmap
        .getView()
        .fit(boundarySourceDistrict.getExtent(), { duration: 1000 });
    }, 1000);
  };

  rotationAction = () => {
    this.setState({ rotationVal: Math.PI / 2 });
  };

  handleComponent = (r) => {
    setTimeout(() => {
      this.olmap
        .getView()
        .fit(
          [
            8486216.857004646, 902189.4079034224, 8944389.503375025,
            1524109.779610402,
          ],
          { duration: 500 }
        );
    }, 500);
    value = [
      ...new Map(
        r
          .filter(
            (filterList) =>
              filterList.PARENT_ID === 0 || filterList.PARENT_ID === null
          )
          .map((data) => [data["Name"], data])
      ).values(),
    ].map((data) => data.Name);
    this.setState({ setComponent: value });
  };

  getReportData = (depid) => {
    switch (depid) {
      case 1:
        return {
          category: "Category",
          date: "Date of Sowing/ Planting",
          farmer_name: "Farmer Name",
          gender: "Gender",
          remarks: "Remarks",
          survey_no: "Survey No",
          tank_name: "Tank Name",
          variety: "Variety",
          yield: "Yield",
          fish_culture: "Fish Culture",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          date: "Date",
          volume_of_water:"Volume of water",
          no_of_seed_stock:"No Of Seed Stock",
          used_feed_quantaty:"Used Feed Quantaty",
          days_of_culture: "Days Of Culture",
          revenue_genrator: "Revenue Genrator",
          no_reared: "No Readed",
          fpc_name: "FPC Name",
          // ID: "ID",
          Categorys: "Category",
          serial_no: "Serial No",
          // village: "Village",
          project_name:"Project Name",
          project_cost: "Project Cost",
          release_date: "Release Date",
          tank_name: "Tank Name",
          group_name: "Group Name",
          date_account_open: "Date Account Open",
          date_revolving_fund_release: "Date Revolving Fund Release",
          participatant_no: "Participatant No",
          tr_exp_categoty: "Tr Exp Categoty",
          tr_exp_female_no: "Tr Exp Female No.", 
          tr_exp_male_no: "Tr Exp Male No.",
          Mobile_Number: "Mobile Number",
          component_name: "Component Name",
          // component_name: "Component Name",
          // incorp_date: "Incorporation Date",
          incorporation_date: "Incorporation Date",
          incorporation_num: "Incorporation number",
          total_amount: "Total grant amount",
          date_of_release_of_grant: "Date of release of grant",
          nodel_mobile_number: "Nodel Officer Mobile No.",
          name_of_the_village: "Beneficiary's village",
          farmer_mobile_number: "Farmer mobile number",
          Sluice_Number: "Sluice Number",
    length_m: "Length M",
    Name_of_WUA: "Name of WUA",
    No_of_Members: "No_of_Members",
    seed_area: "Seed farm area raised(HA)",
        };
      case 2: //date_account_open,date_revolving_fund_release,group_name,intervention_type,other_intervention,quantity_procured,seed_area
        return {
          category: "Category",
          date: "Date of Sowing/ Planting",
          date_account_open: "DSate Account Open",
          date_revolving_fund_release: "Date Revolving Fund Release",
          farmer_name: "Farmer Name",
          gender: "Gender",
          group_name: "Group Name",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          quantity_procured: "Quantity Procured",
          remarks: "Remarks",
          seed_area: "seed_area",
          survey_no: "Survey No",
          tank_name: "Tank Name",
          variety: "Variety",
          yield: "Yield",
          fish_culture: "Fish Culture",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          date: "Date",
          volume_of_water:"Volume of water",
          no_of_seed_stock:"No Of Seed Stock",
          used_feed_quantaty:"Used Feed Quantaty",
          days_of_culture: "Days Of Culture",
          revenue_genrator: "Revenue Genrator",
          no_reared: "No Readed",
          fpc_name: "FPC Name",
          // ID: "ID",
          Categorys: "Category",
          serial_no: "Serial No",
          // village: "Village",
          project_name:"Project Name",
          project_cost: "Project Cost",
          release_date: "Release Date",
          tank_name: "Tank Name",
          group_name: "Group Name",
          date_account_open: "Date Account Open",
          date_revolving_fund_release: "Date Revolving Fund Release",
          participatant_no: "Participatant No",
          tr_exp_categoty: "Tr Exp Categoty",
          tr_exp_female_no: "Tr Exp Female No.", 
          tr_exp_male_no: "Tr Exp Male No.",
          Mobile_Number: "Mobile Number",
          // component_name: "Component Name"
          component_name: "Component Name",
          // incorp_date: "Incorporation Date",
          incorporation_date: "Incorporation Date",
          incorporation_num: "Incorporation number",
          total_amount: "Total grant amount",
          date_of_release_of_grant: "Date of release of grant",
          nodel_mobile_number: "Nodel Officer Mobile No.",
          name_of_the_village: "Beneficiary's village",
          farmer_mobile_number: "Farmer mobile number",
          Sluice_Number: "Sluice Number",
    length_m: "Length M",
    Name_of_WUA: "Name of WUA",
    No_of_Members: "No_of_Members",
    seed_area: "Seed farm area raised(HA)",
        };
      case 3: //intervention_type,other_intervention,tr_exp_categoty,tr_exp_female_no,tr_exp_male_no
        return {
          category: "Category",
          date: "Date of Sowing/ Planting",
          farmer_name: "Farmer Name",
          gender: "Gender",
          remarks: "Remarks",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          others_female_no: "Others Female No.",
          others_male_no: "Others Male No",
          participatant_no: "Participatant No",
          sc_st_female_no: "SC ST Female No",
          sc_st_female_no: "SC ST Male No",
          survey_no: "Survey No",
          tank_name: "Tank Name",
          tr_exp_categoty: "Tr_exp_categoty",
          tr_exp_female_no: "Tr_exp_female_no",
          tr_exp_male_no: "Tr_exp_male_no",
          variety: "Variety",
          yield: "Yield",
          fish_culture: "Fish Culture",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          date: "Date",
          volume_of_water:"Volume of water",
          no_of_seed_stock:"No Of Seed Stock",
          used_feed_quantaty:"Used Feed Quantaty",
          days_of_culture: "Days Of Culture",
          revenue_genrator: "Revenue Genrator",
          no_reared: "No Readed",
          fpc_name: "FPC Name",
          // ID: "ID",
          Categorys: "Category",
          serial_no: "Serial No",
          // village: "Village",
          project_name:"Project Name",
          project_cost: "Project Cost",
          release_date: "Release Date",
          tank_name: "Tank Name",
          group_name: "Group Name",
          date_account_open: "Date Account Open",
          date_revolving_fund_release: "Date Revolving Fund Release",
          participatant_no: "Participatant No",
          tr_exp_categoty: "Tr Exp Categoty",
          tr_exp_female_no: "Tr Exp Female No.", 
          tr_exp_male_no: "Tr Exp Male No.",
          Mobile_Number: "Mobile Number",
          // component_name: "Component Name"
          component_name: "Component Name",
          // incorp_date: "Incorporation Date",
          incorporation_date: "Incorporation Date",
          incorporation_num: "Incorporation number",
          total_amount: "Total grant amount",
          date_of_release_of_grant: "Date of release of grant",
          nodel_mobile_number: "Nodel Officer Mobile No.",
          name_of_the_village: "Beneficiary's village",
          farmer_mobile_number: "Farmer mobile number",
          Sluice_Number: "Sluice Number",
    length_m: "Length M",
    Name_of_WUA: "Name of WUA",
    No_of_Members: "No_of_Members",
    seed_area: "Seed farm area raised(HA)",
        };
      case 4:
        return {
          category: "Category",
          farmer_name: "Farmer Name",
          fish_culture: "Fish Culture",
          gender: "Gender",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          remarks: "Remarks",
          survey_no: "Survey No",
          tank_name: "Tank Name",
          variety: "Variety",
          yield: "Yield",
          fish_culture: "Fish Culture",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          date: "Date",
          volume_of_water:"Volume of water",
          no_of_seed_stock:"No Of Seed Stock",
          used_feed_quantaty:"Used Feed Quantaty",
          days_of_culture: "Days Of Culture",
          revenue_genrator: "Revenue Genrator",
          no_reared: "No Readed",
          fpc_name: "FPC Name",
          // ID: "ID",
          Categorys: "Category",
          serial_no: "Serial No",
          // village: "Village",
          project_name:"Project Name",
          project_cost: "Project Cost",
          release_date: "Release Date",
          tank_name: "Tank Name",
          group_name: "Group Name",
          date_account_open: "Date Account Open",
          date_revolving_fund_release: "Date Revolving Fund Release",
          participatant_no: "Participatant No",
          tr_exp_categoty: "Tr Exp Categoty",
          tr_exp_female_no: "Tr Exp Female No.", 
          tr_exp_male_no: "Tr Exp Male No.",
          Mobile_Number: "Mobile Number",
          // component_name: "Component Name"
          component_name: "Component Name",
          // incorp_date: "Incorporation Date",
          incorporation_date: "Incorporation Date",
          incorporation_num: "Incorporation number",
          total_amount: "Total grant amount",
          date_of_release_of_grant: "Date of release of grant",
          nodel_mobile_number: "Nodel Officer Mobile No.",
          name_of_the_village: "Beneficiary's village",
          farmer_mobile_number: "Farmer mobile number",
          Sluice_Number: "Sluice Number",
    length_m: "Length M",
    Name_of_WUA: "Name of WUA",
    No_of_Members: "No_of_Members",
    seed_area: "Seed farm area raised(HA)",
        };
      case 5: //female_no1,no_of_cows
        return {
          category: "Category",
          categorys: "Categorys",
          date: "Date of Sowing/ Planting",
          farmer_name: "Farmer Name",
          female_no1: "Female No",
          fish_culture: "Fish Culture",
          gender: "Gender",
          intervention_type: "Intervention Type",
          remarks: "Remarks",
          male_no1: "Male No.",
          no_of_calves: "No. Of Calves",
          no_of_cows: "No. of Cows",
          no_of_farmers: "No. Of Farmers",
          other_intervention: "Other Intervention",
          others_female_no: "Others Female No.",
          others_male_no: "Others Male No",
          survey_no: "Survey No",
          tank_name: "Tank Name",
          variety: "Variety",
          yield: "Yield",
          sc_st_female_no: "SC ST Female No",
          sc_st_female_no: "SC ST Male No",
          venue: "Venue",
          fish_culture: "Fish Culture",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          date: "Date",
          volume_of_water:"Volume of water",
          no_of_seed_stock:"No Of Seed Stock",
          used_feed_quantaty:"Used Feed Quantaty",
          days_of_culture: "Days Of Culture",
          revenue_genrator: "Revenue Genrator",
          no_reared: "No Readed",
          fpc_name: "FPC Name",
          // ID: "ID",
          Categorys: "Category",
          serial_no: "Serial No",
          // village: "Village",
          project_name:"Project Name",
          project_cost: "Project Cost",
          release_date: "Release Date",
          tank_name: "Tank Name",
          group_name: "Group Name",
          date_account_open: "Date Account Open",
          date_revolving_fund_release: "Date Revolving Fund Release",
          participatant_no: "Participatant No",
          tr_exp_categoty: "Tr Exp Categoty",
          tr_exp_female_no: "Tr Exp Female No.", 
          tr_exp_male_no: "Tr Exp Male No.",
          Mobile_Number: "Mobile Number",
          component_name: "Component Name",
          component_name: "Component Name",
          // incorp_date: "Incorporation Date",
          incorporation_date: "Incorporation Date",
          incorporation_num: "Incorporation number",
          total_amount: "Total grant amount",
          date_of_release_of_grant: "Date of release of grant",
          nodel_mobile_number: "Nodel Officer Mobile No.",
          name_of_the_village: "Beneficiary's village",
          farmer_mobile_number: "Farmer mobile number",
          Sluice_Number: "Sluice Number",
    length_m: "Length M",
    Name_of_WUA: "Name of WUA",
    No_of_Members: "No_of_Members",
    seed_area: "Seed farm area raised(HA)",
        };
      case 6:
        return {
          Name_of_WUA: "Name of WUA",
          No_of_Members: "No. of Members",
          Remarks: "Remarks",
          Sluice_Number: "Sluice Number",
          intervention_type: "Intervention Type",
          length_m: "Length_m",
          ls_point: "ls Point",
          other_intervention: "Other Intervention",
          fish_culture: "Fish Culture",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          date: "Date",
          volume_of_water:"Volume of water",
          no_of_seed_stock:"No Of Seed Stock",
          used_feed_quantaty:"Used Feed Quantaty",
          days_of_culture: "Days Of Culture",
          revenue_genrator: "Revenue Genrator",
          no_reared: "No Readed",
          fpc_name: "FPC Name",
          // ID: "ID",
          Categorys: "Category",
          serial_no: "Serial No",
          // village: "Village",
          project_name:"Project Name",
          project_cost: "Project Cost",
          release_date: "Release Date",
          tank_name: "Tank Name",
          group_name: "Group Name",
          date_account_open: "Date Account Open",
          date_revolving_fund_release: "Date Revolving Fund Release",
          participatant_no: "Participatant No",
          tr_exp_categoty: "Tr Exp Categoty",
          tr_exp_female_no: "Tr Exp Female No.", 
          tr_exp_male_no: "Tr Exp Male No.",
          Mobile_Number: "Mobile Number",
          // component_name: "Component Name",
          component_name: "Component Name",
          // incorp_date: "Incorporation Date",
          incorporation_date: "Incorporation Date",
          incorporation_num: "Incorporation number",
          total_amount: "Total grant amount",
          date_of_release_of_grant: "Date of release of grant",
          nodel_mobile_number: "Nodel Officer Mobile No.",
          name_of_the_village: "Beneficiary's village",
          farmer_mobile_number: "Farmer mobile number",
          Sluice_Number: "Sluice Number",
    length_m: "Length M",
    Name_of_WUA: "Name of WUA",
    No_of_Members: "No_of_Members",
    seed_area: "Seed farm area raised(HA)",
        };
      case 7:
        return {
          Capacity_Mts: "Capacity Mts",
          Category_of_Godown: "Category of Godown",
          Categorys: "Categorys",
          Contribution: "Contribution",
          Country_and_State_Name: "Country and State Name",
          Date_From: "Date From",
          Date_To: "Date To",
          Date_of_Completion: "Date of Completion",
          Duration_of_Visit: "Duration of Visit",
          Duration_of_training: "Duration of Training",
          Email_Id: "Email_Id",
          Government_share: "Government Share",
          Infrastructure_available: "Infrastructure Available",
          Location_of_the_Consulting_agency:
            "Location of the Consulting Agency",
          Name_HQandFPC: "Name HQandFPC",
          Name_of_the_consulting_agency: "Name of the Consulting Agency",
          Name_of_training: "Name of Training",
          No_of_beneficieries: "No of Beneficieries",
          Number_of_traineesm: "Number of Traineesm",
          Numbers: "Numbers",
          Places_visited: "Places Visited",
          Quantity_stored_Mts: "Quantity Stored Mts",
          Remarks: "Remarks",
          category: "Category",
          category2: "Category",
          crop: "Crop",
          date: "Date of Sowing/ Planting",
          female_no1: "Female No",
          female_no2: "Female No",
          fpc_name: "FPC Name",
          incorp_date: "Incorporation Date",
          incorporation_date: "Incorporation Date",
          incorporation_num: "Incorporation Number",
          male_no1: "Male No.",
          male_no2: "Male No.",
          name_of_ceo: "Name of CEO",
          names: "Names",
          no_of_village: "No of Village",
          nof_category: "No of Category",
          nof_female: "No of Female",
          nof_male: "No. of Male",
          nof_mem: "No. of Members",
          nof_village: "No. of Village",
          nooffigs: "No. of Figs",
          others_female_no: "Others Female No.",
          others_male_no: "Others Male No.",
          remarks1: "Remarks",
          sc_st_female_no: "SC ST Female No",
          sc_st_male_no: "SC ST Male No",
          season: "Season",
          serial_no: "Serial No",
          venue: "Venue",
          fish_culture: "Fish Culture",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          date: "Date",
          volume_of_water:"Volume of water",
          no_of_seed_stock:"No Of Seed Stock",
          used_feed_quantaty:"Used Feed Quantaty",
          days_of_culture: "Days Of Culture",
          revenue_genrator: "Revenue Genrator",
          no_reared: "No Readed",
          fpc_name: "FPC Name",
          // ID: "ID",
          Categorys: "Category",
          serial_no: "Serial No",
          // village: "Village",
          project_name:"Project Name",
          project_cost: "Project Cost",
          release_date: "Release Date",
          tank_name: "Tank Name",
          group_name: "Group Name",
          date_account_open: "Date Account Open",
          date_revolving_fund_release: "Date Revolving Fund Release",
          participatant_no: "Participatant No",
          tr_exp_categoty: "Tr Exp Categoty",
          tr_exp_female_no: "Tr Exp Female No.", 
          tr_exp_male_no: "Tr Exp Male No.",
          Mobile_Number: "Mobile Number",
          // component_name: "Component Name"
          component_name: "Component Name",
          // incorp_date: "Incorporation Date",
          incorporation_date: "Incorporation Date",
          incorporation_num: "Incorporation number",
          total_amount: "Total grant amount",
          date_of_release_of_grant: "Date of release of grant",
          nodel_mobile_number: "Nodel Officer Mobile No.",
          name_of_the_village: "Beneficiary's village",
          farmer_mobile_number: "Farmer mobile number",
          Sluice_Number: "Sluice Number",
    length_m: "Length M",
    Name_of_WUA: "Name of WUA",
    No_of_Members: "No_of_Members",
    seed_area: "Seed farm area raised(HA)",
        };
      case 8:
        return {
          Farm_pond_constructed_by: "Farm Pond Constructed by",
          Feed_Quantity: "Feed Quantity",
          Name_of_the_tank: "Name of the Tank",
          Quanity_of_fish_harvested_Kg: "Quanity of fish harvested Kg",
          Stages: "Stages",
          Water_spread_area_EWSAHa: "Water Spread Area EWSAHa",
          beneficiary: "Beneficiary",
          beneficiary_name: "Beneficiary Name",
          category: "Category",
          gender: "Gender",
          harvested: "Harvested",
          intervention_type: "Intervention Type",
          lessee: "Lessee",
          no_of_seed: "No. of Seed",
          no_of_stocks_req: "No of Stocks Req",
          nodal_officer: "Nodal Officer",
          other_intervention: "Other Intervention",
          remarks: "Remarks",
          species_stoked: "Species Stoked",
          survey_no: "Survey No",
          fish_culture: "Fish Culture",
          intervention_type: "Intervention Type",
          other_intervention: "Other Intervention",
          date: "Date",
          volume_of_water:"Volume of water",
          no_of_seed_stock:"No Of Seed Stock",
          used_feed_quantaty:"Used Feed Quantaty",
          days_of_culture: "Days Of Culture",
          revenue_genrator: "Revenue Genrator",
          no_reared: "No Readed",
          fpc_name: "FPC Name",
          ID: "ID",
          Categorys: "Category",
          serial_no: "Serial No",
          // village: "Village",
          project_name:"Project Name",
          project_cost: "Project Cost",
          release_date: "Release Date",
          tank_name: "Tank Name",
          group_name: "Group Name",
          date_account_open: "Date Account Open",
          date_revolving_fund_release: "Date Revolving Fund Release",
          participatant_no: "Participatant No",
          tr_exp_categoty: "Tr Exp Categoty",
          tr_exp_female_no: "Tr Exp Female No.", 
          tr_exp_male_no: "Tr Exp Male No.",
          Mobile_Number: "Mobile Number",
          // component_name: "Component Name"
          component_name: "Component Name",
          // incorp_date: "Incorporation Date",
          incorporation_date: "Incorporation Date",
          incorporation_num: "Incorporation number",
          total_amount: "Total grant amount",
          date_of_release_of_grant: "Date of release of grant",
          nodel_mobile_number: "Nodel Officer Mobile No.",
          name_of_the_village: "Beneficiary's village",
          farmer_mobile_number: "Farmer mobile number",
          Sluice_Number: "Sluice Number",
    length_m: "Length M",
    Name_of_WUA: "Name of WUA",
    No_of_Members: "No_of_Members",
    seed_area: "Seed farm area raised(HA)",
        };
    }
  };

  handlePDFDownload = (value) => {
    const doc = new jsPDF();
    const startX = 10;
    const startY = 8;
    const textIndent = 140;
    doc.text("REPORTS", startX + 90, startY + 6, {
      align: "center",
    });
    doc.setTextColor(30);
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Created Date :", startX + 30, startY + 24);
    doc.text("Phase :", startX + 30, startY + 32);
    doc.text("Sub Basin :", startX + 30, startY + 40);
    doc.text("District :", startX + 30, startY + 48);
    doc.text("Block :", startX + 30, startY + 56);
    doc.text("Village :", startX + 30, startY + 64);
    doc.text("Components :", startX + 30, startY + 72);
    doc.text("Sub Components :", startX + 30, startY + 80);
    doc.text("Stages :", startX + 30, startY + 88);
    doc.text("ID :", startX + 30, startY + 96);
    doc.text("Name :", startX + 30, startY + 104);
    doc.text("Mobile No :", startX + 30, startY + 112);
    doc.text("Area(ha) :", startX + 30, startY + 120);
    doc.setFont("helvetica", "normal");
    value.created_date &&
      doc.text(value.created_date.toString(), startX + 90, startY + 24);
    value.phase != null &&
      doc.text(value.phase == "1"?"I":value.phase == "2"?"II":value.phase == "3"?"III":"IV", startX + 90, startY + 32);
    value.sub_basin_name &&
      doc.text(value.sub_basin_name.toString(), startX + 90, startY + 40);
    value.district_name &&
      doc.text(value.district_name.toString(), startX + 90, startY + 48);
    value.block_name ?
      doc.text(value.block_name.toString(), startX + 90, startY + 56)
      :value.block ? doc.text(value.block.toString(), startX + 90, startY + 56) : doc.text("", startX + 90, startY + 56);
    value.village_name &&
      doc.text(value.village_name.toString(), startX + 90, startY + 64);
    value.component &&
      doc.text(value.component.toString(), startX + 90, startY + 72);
    value.Sub_Component &&
      doc.text(value.Sub_Component.toString(), startX + 90, startY + 80);
    value.Stages && doc.text(value.Stages.toString(), startX + 90, startY + 88);
    // 
    value.ID && doc.text(value.ID.toString(), startX + 90, startY + 96);
    value.user_name &&
      doc.text(value.user_name.toString(), startX + 90, startY + 104);
    value.user_mobile_number? doc.text(value.user_mobile_number.toString(), startX + 90, startY + 112)
      : value.Mobile_Number ? doc.text(value.Mobile_Number.toString(), startX + 90, startY + 112)
      : value.mobile_number ? doc.text(value.mobile_number.toString(), startX + 90, startY + 112)
      :value.mobile ? doc.text(value.mobile.toString(), startX + 90, startY + 112)
      :doc.text("", startX + 90, startY + 112);
    value.Area != undefined
      ? doc.text(value.Area.toString(), startX + 90, startY + 120)
      : value.area && value.area != 0
      ? doc.text(value.area.toString(), startX + 90, startY + 120)
      : doc.text("0", startX + 90, startY + 120);
    const displayReportList = this.getReportData(value.dept_id);
    let index = 120;
    const filterList = Object.entries(value)
      .filter(
        (value) => value[1] != 0 && value[1] != null && value[1] != "null"
      )
      .filter((val) => {
        if (Object.keys(displayReportList).includes(val[0])) {
          index += 8;
          doc.setFont("helvetica", "bold");
          doc.text(
            displayReportList[val[0]] + " :",
            startX + 30,
            startY + index
          );
          doc.setFont("helvetica", "normal");
          doc.text(val[1].toString(), startX + 90, startY + index);
        }
      });

    doc.setFont("helvetica", "bold");
    doc.text("Latitude:", startX + 30, startY + index + 8, {
      indent: textIndent,
    });
    doc.text("Longitude:", startX + 30, startY + index + 16, {
      indent: textIndent,
    });
    value.sub_stages && doc.text("Sub Stages:", startX + 30, startY + index + 24, {
      indent: textIndent,
    })
    doc.setFont("helvetica", "normal");
    
    value.photo_lat &&
      doc.text(value.photo_lat.toString(), startX + 90, startY + index + 8);
    value.photo_lon &&
      doc.text(value.photo_lon.toString(), startX + 90, startY + index + 16);
      value.sub_stages && doc.text(value.sub_stages.toString(), startX + 90, startY + index + 24);
      
    const iconSize = 30;
    this.state.img1 && doc.addImage(
      this.state.img1,
      "PNG",
      startX + 30,
      startY + index + 32,
      iconSize,
      iconSize
    );
    this.state.img2 && doc.addImage(
      this.state.img2,
      "PNG",
      startX + 90,
      startY + index + 32,
      iconSize,
      iconSize
    );
    const todayDate =
      String(new Date().getDate()).padStart(2, 0) +
      "-" +
      String(new Date().getMonth() + 1).padStart(2, 0) +
      "-" +
      new Date().getFullYear();
    doc.text(
      "Date: " + todayDate,
      doc.internal.pageSize.width - 50,
      doc.internal.pageSize.height - 10
    );
    doc.save("Map.pdf");
  };

  captureScreenshot = () => {
    const context = this.olmap.getView().calculateExtent();
    this.olmap
      .getView()
      .fit(boundarySourceDistrict.getExtent(), { duration: 100 });
    setTimeout(() => {
      if (this.olmap) {
        const container = this.containerRef.current;

        html2canvas(container, { useCORS: true, scale: 0.98, dpi: 500 }).then(
          (canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = imgData;
            link.download = "Map_screen.png";
            link.click();
          }
        );
      }
      this.olmap.getView().fit(context, { duration: 100 });
    }, 1000);
  };

  handleShowData = () => {
    this.setState({ showReportData: !this.state.showReportData });
  };

  render() {
    this.updateMap();
    return (
      <>
        <div id="page">
          <Spin
            tip="Fetching data ..."
            spinning={this.state.setLoadiing}
            size="large"
            indicator={antIcon}
            style={{ color: "#673ab7", fontWeight: "bold" }}
          >
            <Row style={{ position: "relative" }}>
              <Col md={20} ref={this.containerRef}>
                <div
                  className="draw-map"
                  id="draw-map"
                  ref={this.element1Ref}
                  style={{
                    width: "100%",
                    height: `${this.props.height - 65}px`,
                    position: "fixed",
                  }}
                ></div>
                <div id="custom-context-menu" style={{ height: "2.35em" }}>
                  <ul>
                    <li
                      id="save-as-png"
                      onClick={() => {
                        this.captureScreenshot();
                        document.getElementById(
                          "custom-context-menu"
                        ).style.display = "none";
                      }}
                    >
                      Save as PNG
                    </li>
                  </ul>
                </div>
                <div className="extentZoom ol-control">
                  <Tooltip title="Zoom to full Extent" placement="left">
                    <button Tooltip="Zoom to full Extent">
                      <TbZoomOutArea
                        onClick={() => this.setState({ fullExtent: true })}
                      />
                    </button>
                  </Tooltip>
                </div>
                <div className="MousePosition ol-control">
                  <div id="mouse-position"></div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    maxWidth: "240px",
                  }}
                >
                  <Card>
                    <div
                      ref={this.element2Ref}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {this.state.setComponent.map((value, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div>
                              <img src={enumimage[index]} height={24}></img>
                            </div>
                            <div>{value}</div>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                </div>
              </Col>

              <Col md={4} style={{ overflow: "scroll", height: "92vh" }}>
                <Card>
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      marginBottom: "15px",
                    }}
                  >
                    Displayed Layers
                  </div>
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    value={this.state.selecedLayer}
                    onChange={this.layerVisibleCheckList}
                  >
                    <div>
                      <div>
                        <Checkbox value="districts">TN Districts</Checkbox>
                      </div>

                      <div>
                        <Checkbox
                          value="PhaseISubBasin"
                          style={{ marginTop: "15px" }}
                        >
                          Phase I Sub_Basin
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          value="PhaseIISubBasin"
                          style={{ marginTop: "15px" }}
                        >
                          Phase II Sub_Basin
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          value="PhaseIIISubBasin"
                          style={{ marginTop: "15px" }}
                        >
                          Phase III Sub_Basin
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          value="PhaseIVSubBasin"
                          style={{ marginTop: "15px" }}
                        >
                          Phase IV Sub_Basin
                        </Checkbox>
                      </div>
                    </div>
                  </Checkbox.Group>
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Filters
                  </div>
                  {this.props.userInfo[0].user_type == "1" && (
                    <DepartmentType
                      departmentList={this.state.deptList.filter((value) => {
                        if (value.NAME != "MDPU") return value;
                      })}
                      setDepartmentId={this.getDetId}
                    />
                  )}
                  <Filter
                    reportType={this.state.deptTypePath}
                    setReportData={this.setReportData}
                    setLoadiingTime={this.setLoadiingTime}
                    width={"100%"}
                    setLayer={this.setLayerlist}
                    componentData={(r) => {
                      // setTimeout(() => {
                        popup && popup.hide();
                      // },1000)
                     
                      // popup.setPosition(undefined)
                      this.setState({
                        detId:
                          deptPathName.indexOf(this.state.deptTypePath) + 1,
                      });
                      this.handleComponent(r);
                    }}
                  />
                </Card>
              </Col>
            </Row>
            <div id="markerCard" style={{ border: 0 }}>
            {console.log("this.state.featureDetails--->",this.state.featureDetails)}
              {Object.keys(this.state.featureDetails).length > 0 && (
                <>
                  <Button
                    style={{ float: "right" }}
                    onClick={() => {
                      this.handlePDFDownload(this.state.featureDetails);
                    }}
                  >
                    <Tooltip title="Download PDF">
                      <TbArrowBarToDown />
                    </Tooltip>
                  </Button>
                  <div
                    style={{
                      padding: "2em 0.8em 0.4em",
                      color: "black",
                    }}
                  >
                    <Row>
                      <Col md={12}>Created Date </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}> {this.state.featureDetails.created_date}</Col>
                      <Col md={12}>ID </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}> {this.state.featureDetails.ID}</Col>
                      <Col md={12}>Phase </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}>
                        {" "}
                        {this.state.featureDetails.phase == "1"
                          ? "I"
                          : this.state.featureDetails.phase == "2"
                          ? "II"
                          : this.state.featureDetails.phase == "3"
                          ? "III"
                          : "IV"}
                      </Col>
                      <Col md={12}>Subbasin </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}>
                        {" "}
                        {this.state.featureDetails.sub_basin_name}
                      </Col>
                      <Col md={12}>District </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}>
                        {" "}
                        {this.state.featureDetails.district_name}
                      </Col>
                      <Col md={12}>Block </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}> {this.state.featureDetails.block_name?this.state.featureDetails.block_name:this.state.featureDetails.block?this.state.featureDetails.block:""}</Col>
                      <Col md={12}>Village </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}>
                        {" "}
                        {this.state.featureDetails.village_name}
                      </Col>
                      <Col md={12}>Component </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}> {this.state.featureDetails.component}</Col>
                      <Col md={12}>Sub Component </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}>
                        {" "}
                        {this.state.featureDetails.Sub_Component == 0 ? "" : this.state.featureDetails.Sub_Component}
                      </Col>
                      <Col md={12}>Stage </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}> {this.state.featureDetails.Stages== 0 ? "" : this.state.featureDetails.Stages}</Col>
                      {this.state.featureDetails.sub_stages ?  <><Col md={12}>Sub Stage </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}> {this.state.featureDetails.sub_stages == 0 ? "" :this.state.featureDetails.sub_stages}</Col></> : ""}
                      <Col md={12}>Name </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}> {this.state.featureDetails.user_name}</Col>
                      <Col md={12}>Phone no </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}>
                        {" "}
                        {this.state.featureDetails.user_mobile_number ? this.state.featureDetails.user_mobile_number :this.state.featureDetails.mobile_number ? this.state.featureDetails.mobile_number : this.state.featureDetails.mobile}
                      </Col>
                      <Col md={12}>Area (Ha)</Col>
                      <Col md={2}>:</Col>
                      {this.state.featureDetails.Area && (
                        <Col md={10}> {this.state.featureDetails.Area}</Col>
                      )}
                      {this.state.featureDetails.area && (
                        <Col md={10}> {this.state.featureDetails.area} </Col>
                      )}

                      {this.state.showReportData &&
                        Object.entries(this.state.featureDetails)
                          .filter(
                            (value) =>
                              value[1] != 0 &&
                              value[1] != null &&
                              value[1] != "null"
                          )
                          .map((val) => {
                            if (
                              Object.keys(
                                this.getReportData(
                                  this.state.featureDetails.dept_id
                                )
                              ).includes(val[0])
                            ) {
                              return (
                                <>
                                  <Col md={12}>
                                    {
                                      this.getReportData(
                                        this.state.featureDetails.dept_id
                                      )[val[0]]
                                    }{" "}
                                  </Col>
                                  <Col md={2}>:</Col>
                                  <Col
                                    md={10}
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    {" "}
                                    {val[1]}
                                  </Col>
                                </>
                              );
                            }
                          })}
                      <Col md={12}>Lat </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}> {this.state.featureDetails.photo_lat}</Col>
                      <Col md={12}>Long </Col>
                      <Col md={2}>:</Col>
                      <Col md={10}> {this.state.featureDetails.photo_lon}</Col>
                      <Col md={24}>
                        {!this.state.showReportData ? (
                          <>
                            <button
                              style={{
                                float: "right",
                                margin: "1em",
                                backgroundColor: "#6433d7",
                                color: "white",
                                borderRadius: "10px",
                              }}
                              onClick={this.handleShowData}
                            >
                              Show More
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              style={{
                                float: "right",
                                margin: "1em",
                                backgroundColor: "#6433d7",
                                color: "white",
                                borderRadius: "10px",
                              }}
                              onClick={this.handleShowData}
                            >
                              Show Less
                            </button>
                          </>
                        )}
                      </Col>
                      <Col md={12}>
                        <Images
                          img={this.state.img1}
                          feature={this.state.featureDetails}
                        />
                      </Col>
                      <Col md={12}>
                        <Images
                          img={this.state.img2}
                          feature={this.state.featureDetails}
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </div>
          </Spin>
        </div>
      </>
    );
  }
}
GeoMap.propTypes = {
  height: PropType.number,
};

function userInfo() {
  const counter = useSelector((state) =>
    get(state, "AuthReducer.userData", [])
  );
  return <div></div>;
}

export default windowDimensions()(GeoMap);
