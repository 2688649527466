import { Tree } from "antd";
import { useState } from "react";
const treeData = [
  {
    title: "Phase I subbasin",
    key: "1",
    children: [
      {
        title: "Cauvery Delta",
        key: "Cauvery Delta",
      },

      {
        title: "Gadananadhi",
        key: "Gadananadhi",
      },

      {
        title: "Kousiganadhi",
        key: "Kousiganadhi",
      },

      {
        title: "Krishnagiri to Pambar",
        key: "Krishnagiri to Pambar",
      },

      {
        title: "Lower Vaigai",
        key: "Lower Vaigai",
      },

      {
        title: "Lower Bhavani",
        key: "Lower Bhavani",
      },

      {
        title: "Lower Palar",
        key: "Lower Palar",
      },

      {
        title: "Lower Tamiraparani",
        key: "Lower Tamiraparani",
      },

      {
        title: "Lower Vellar",
        key: "Lower Vellar",
      },

      {
        title: "Manjalar",
        key: "Manjalar",
      },

      {
        title: "Nagariyar",
        key: "Nagariyar",
      },

      {
        title: "Ponnaniyar",
        key: "Ponnaniyar",
      },

      {
        title: "Sathaiyar",
        key: "Sathaiyar",
      },

      {
        title: "Sirumalaiyar",
        key: "Sirumalaiyar",
      },

      {
        title: "Suruliyar",
        key: "Suruliyar",
      },

      {
        title: "Varahanadhi",
        key: "Varahanadhi",
      },

      {
        title: "Kallar",
        key: "Kallar",
      },

      { title: " Upper Bhavani", key: "Upper Bhavani" },
    ],
  },
  {
    title: "Phase II subbasin",
    key: "2",
    children: [
      {
        title: "Agaramaru",
        key: "Agaramaru",
      },
      {
        title: "Aliyar_Pennaiyar",
        key: "Aliyar_Pennaiyar",
      },
      {
        title: "Ayiaar",
        key: "Ayiaar",
      },
      {
        title: "Chittar_Tamiraparani",
        key: "Chittar_Tamiraparani",
      },
      {
        title: "Karaipottanar",
        key: "Karaipottanar",
      },
      {
        title: "Lower Coleroon",
        key: "Lower Coleroon",
      },
      {
        title: "Lower Pennaiyar",
        key: "Lower Pennaiyar",
      },
      {
        title: "Mettur Reservoir to Noyel confluence",
        key: "Mettur Reservoir to Noyel confluence",
      },
      {
        title: "Nandhiyar",
        key: "Nandhiyar",
      },
      {
        title: "Nandiyar_Kulaiyar",
        key: "Nandiyar_Kulaiyar",
      },
      {
        title: "Pachaiyar",
        key: "Pachaiyar",
      },
      {
        title: "Pambar",
        key: "Pambar",
      },
      {
        title: "Pungar",
        key: "Pungar",
      },
      {
        title: "Tirumanimuttar",
        key: "Tirumanimuttar",
      },
      {
        title: "Uppar",
        key: "Uppar",
      },
      {
        title: "Vaniyar",
        key: "Vaniyar",
      },
    ],
  },
  {
    title: "Phase III subbasin",
    key: "3",
    children: [
      {
        title: "Cheyyar",
        key: "Cheyyar",
      },
      {
        title: "Chinnar_Cauvery",
        key: "Chinnar_Cauvery",
      },
      {
        title: "Gummidipoondi",
        key: "Gummidipoondi",
      },
      {
        title: "Manimukdhanadhi",
        key: "Manimukdhanadhi",
      },
      {
        title: "Marudaiyar",
        key: "Marudaiyar",
      },
      {
        title: "Musukundanadhi",
        key: "Musukundanadhi",
      },
      {
        title: "Nambiyar",
        key: "Nambiyar",
      },
      {
        title: "Paravanar",
        key: "Paravanar",
      },
      {
        title: "Vegavati",
        key: "Vegavati",
      },
    ],
  },
  {
    title: "Phase IV subbasin",
    key: "4",
    children: [
      {
        title: "Aliyar_PAP",
        key: "Aliyar_PAP",
      },
      {
        title: "Cauvery Delta",
        key: "Cauvery Delta",
      },
      {
        title: "Lower Vaigai",
        key: "Lower Vaigai",
      },
      {
        title: "Lower Vellar",
        key: "Lower Vellar",
      },
      {
        title: "Nandiyar_Kulaiyar",
        key: "Nandiyar_Kulaiyar",
      },
      {
        title: "Ponnai",
        key: "Ponnai",
      },
      {
        title: "Sathaiyar",
        key: "Sathaiyar",
      },
      {
        title: "Therkar",
        key: "Therkar",
      },
      {
        title: "Thurinjalar",
        key: "Thurinjalar",
      },
    ],
  },
];
const CheckLayerData = ({ setCheckLayer }) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState(["1", "2", "3", "4"]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };
  const onCheck = (checkedKeysValue, info) => {
    setCheckLayer({
      expandedKeysValue: expandedKeys,
      setCheckKey: checkedKeysValue,
    });

    setCheckedKeys(checkedKeysValue);
  };
  const onSelect = (selectedKeysValue, info) => {
    setSelectLayer(info);

    setSelectedKeys(selectedKeysValue);
  };
  return (
    <Tree
      style={{ color: "white" }}
      checkable
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      treeData={treeData}
    />
  );
};
export default CheckLayerData;
