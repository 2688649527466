import axios from "axios";
import api from "../../constants/api";
import { message } from "antd";

export function getUserList(setUserList, setLoadiingTime, userInfo) {
  setLoadiingTime(true);
  return axios
    .get(api.userList, {
      params: {
        user_id: 0,
        dept_id: userInfo[0].line_dept,
        sub_basin_id: 0,
      },
    })
    .then(({ data }) => {
      setLoadiingTime(false);
      setUserList(data.reverse());
    })
    .catch((error) => {
      setLoadiingTime(false);
      message.error("Something went wrong please try again");
    });
}

export function addUser(setUser) {
  return axios
    .post(api.addUser, setUser)
    .then(({ data }) => {
      message.success("Added the new User");
    })
    .catch((error) => {
      message.error("Already User Exist");
    });
}

export function getAdminList(setUserList, setLoadiingTime) {
  setLoadiingTime(true);
  return axios
    .get(api.adminlist)
    .then(({ data }) => {
      setLoadiingTime(false);
      setUserList(data.reverse());
    })
    .catch((error) => {
      setLoadiingTime(false);
      message.error("Something went wrong please try again");
    });
}

export function changeDepartment(
  deptID,
  userId,
  getUserList,
  setUserList,
  setLoadiingTime,
  userInfo
) {
  return axios
    .put(
      // `https://vrlgv2akal.execute-api.ap-south-1.amazonaws.com/prod/api/changedeptbyid`,
      api.changedepartment,
      { LINE_DEPT: deptID.toString(), ID: userId }
    )
    .then((res) => {
      getUserList(setUserList, setLoadiingTime, userInfo);
    });
}
