import axios from "axios";
import api from "../../constants/api";
import { message } from "antd";

export function getMessageReort(setMessageList, setLoadiingTime) {
  setLoadiingTime(true);
  return axios
    .get(api.MessagePort, {
      params: {
        user_id: 0,
      },
    })
    .then(({ data }) => {
      setLoadiingTime(false);
      setMessageList(data.reverse());
    })
    .catch((error) => {
      message.error("Something went wrong please try again");
      setLoadiingTime(false);
    });
}

export function putMsg(apiData, handleCancel, sendMessage, apiData1) {
  return (
    axios
      // .post(api.sendMsg1, apiData)
      .get(api.sendMsg1 + `phone=${apiData.phone}&message=${apiData.message}`)
      .then(({ data }) => {
        message.success("Message sent succesfuly");
        handleCancel(false);
        // sendMessage(apiData1);
      })
      .catch((error) => {
        handleCancel(false);
        message.error("Something went wrong please try again");
      })
  );
}
export function sendMail(gmail, message){
  console.log("gmail--->",gmail)
  return (
    axios.get(api.sendmail +`?gmail=${gmail}&message=${message}`)
    .then(()=>{
      console.log("email sent");
    })
    .catch((error)=>{
      console.log("error--->",error)
    })
  )
}
export function sendMessage(apiData) {
  return (
    axios
      .post(api.sendMsg, apiData)
      .then(({ data }) => {
        // message.success("Message sent succesfuly");
      })
      .catch((error) => {
        message.error("Something went wrong please try again");
      })
  );
}

export function messageUser(setMessageUsers, setLoadiingTime) {
  setLoadiingTime(true);
  return axios
    .get(api.userList, {
      params: {
        user_id: 0,
        dept_id: 0,
        sub_basin_id: 0,
      },
    })
    .then(({ data }) => {
      setLoadiingTime(false);
      setMessageUsers(data.reverse());
    })
    .catch((error) => {
      message.error("Something went wrong please try again");
      setLoadiingTime(false);
    });
}
