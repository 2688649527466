import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Segmented,
  Select,
  Image,
  Row,
  Col,
} from "antd";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { getDepartment, getDepartmentUser, loginApi } from "./apiAction";
import images from "../../constants/image";
import QRCode from "react-qr-code";

import _ from "lodash";

import "./css/style.css";
import { getDashbordCount } from "../Dashboard/apiAction";
import DashboardCount from "../Dashboard/dashboradCount";

const { Option } = Select;

function Login() {
  const [error, setError] = useState(false);

  const [userType, setUserType] = useState("Admin");
  const [departmentList, setDeartmentList] = useState([]);
  const [departmentUserList, setDeartmentUserList] = useState([]);
  const [dashboardCount, setDashboardCount] = useState([]);

  const dispatch = useDispatch();
  const onFinish = (values) => {
    if (
      (!_.isEmpty(values.password) || !_.isEmpty(values.passwordInV)) &&
      (!_.isEmpty(values.username) || !_.isEmpty(values.usernameIn))
    ) {
      if (userType === "Admin") {
        dispatch(
          loginApi(
            {
              username: values.username.trim(),
              password: values.password.trim(),
              type_id: "1",
              user_id: 0
            },
            setError
          )
        );
        setError(false);
      } else {
        dispatch(
          loginApi(
            {
              username: values.usernameIn.split("-")[0].trim(),
              password: values.passwordInV.trim(),
              type_id: "4",
              department: values.Department,
              user_id: Number(values.usernameIn.split("-")[2])
            },
            setError
          )
        );
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getDepartmentUsers = (department) => {
    const departmentId = departmentList
      .filter((data) => data.NAME === department)
      .map((filterData) => filterData.ID);
    if (!isEmpty(departmentId)) {
      dispatch(getDepartmentUser(setDeartmentUserList, departmentId[0]));
    }
  };

  useEffect(() => {
    dispatch(getDepartment(setDeartmentList));
    getDashbordCount(0, setDashboardCount);
  }, []);
  return (
    <div>
      
      <DashboardCount dashboardCount={dashboardCount} />
      
      <div
        style={{
          height: "92.4vh",
          // height: "85vh",
          // height: "100%",
          // height:"500px",
          background: `url(${images.ic_bgBottomImg})`,
          backgroundRepeat: "no-repeat",
          paddingTop: window.innerWidth < 600 ? "" : "25px",
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        }}
      >
        <center>
          <Card
            style={{
              width: window.innerWidth < 600 ? "100vw" : "70vw",
              height:"480px",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
          >
            <Row>
              <Col md={12}>
                <img
                  src={images.ic_loginpagemap}
                  height={"450px"}
                  width={window.innerWidth < 600 ? window.innerWidth - 60 : ""}
                />
              </Col>
              <Col md={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.4em 1em",
                    marginBottom: "1em",
                  }}
                >
                  <img src={images.ic_tniamplogo} width={"80px"} />
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    TAMIL NADU IRRIGATED AGRICULTURE MODERNIZATION PROJECT
                  </p>
                </div>
                <Form
                  name="normal_login"
                  className="login-form"
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 15,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  style={{ width: window.innerWidth < 600 ? "90vw" : "25vw" }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Segmented
                    style={{
                      margin: "0px 0px 20px 140px",
                    }}
                    options={["Admin", "Individual"]}
                    value={userType}
                    onChange={setUserType}
                  />

                  {userType !== "Admin" && (
                    <Form.Item
                      hasFeedback
                      name="Department"
                      label="Line Department"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select onChange={getDepartmentUsers} showSearch>
                        {departmentList.map((data) => (
                          <Option value={data.NAME}>{data.NAME}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {userType !== "Admin" && (
                    // <>
                     <Form.Item
                      hasFeedback
                      name="usernameIn"
                      label="User"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select onChange={getDepartmentUsers} showSearch>
                        {departmentUserList
                          .filter(
                            (filterData) =>
                              filterData.user_name !== null 
                              // &&
                              // filterData.LINE_DEPT != 9
                          )
                          .map((data) => (
                            <Option value={data.user_name + " - " + data.MOBILE + " - " +data.user_id } key ={data.user_id}>
                              {data.user_name + " - " + data.MOBILE}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}

                  {userType === "Admin" && (
                    <Form.Item
                      hasFeedback
                      name="username"
                      label="User Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Username!",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          borderColor: "#32be8f",
                          borderRadius: 0,
                          boxShadow: "none",
                        }}
                        size="large"
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    hasFeedback
                    name={userType === "Admin" ? "password" : "passwordInV"}
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      style={{
                        borderColor: "#32be8f",
                        borderRadius: 0,
                        boxShadow: "none",
                      }}
                      type="password"
                    />
                  </Form.Item>
                  <Form.Item
                    labelCol={{
                      span: 10,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                  >
                    <center
                      style={{
                        margin: "0px 0px 0px 120px",
                      }}
                    >
                      <Button type="link" htmlType="submit" className="btn">
                        Log in
                      </Button>
                    </center>

                    <Row
                      style={{
                        margin: "0 auto",
                        marginTop: "2em",
                      }}
                      justify={"center"}
                      gutter={2}
                    >
                      <Col span={14}>
                        <div
                          style={{
                            // height: "80px",
                            // width: "80px",
                            // backgroundColor: "RED",
                            height: "60px",
                            width: "60px"
                          }}
                        >
                          <QRCode
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                            value="https://d39iu8i81i7ydt.cloudfront.net/tniamp-apk/TNIAMP_V_2_0_7_PROD_RELEASE.apk"
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      </Col>
                      <Col span={10}>
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button className="btn" onClick={() => {}}>
                            <a href="https://d39iu8i81i7ydt.cloudfront.net/tniamp-apk/TNIAMP_V_2_0_7_PROD_RELEASE.apk">
                              Download App
                            </a>
                          </Button>
                        </div>{" "}
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row></Row>
          </Card>
        </center>
      </div>
    </div>
  );
}

export default Login;
