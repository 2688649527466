import React from "react";
import "./style.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";

drilldown(Highcharts);
HighchartsExporting(Highcharts);

const variablepieChart = ({ title, y, dataList, widthVal, phase }) => {
  const options = {
    chart: {
      type: "pie",
      events: {
        beforePrint: function () {
          this.setSize(1000, 350, false);
          this.originalTitle = this.options.title.text;

          this.setTitle({ text: this.options.title.text + " - " + phase });
        },
        afterPrint: function () {
          this.setSize(widthVal < 600 && widthVal - 100, 350, false);
          this.setTitle({ text: this.originalTitle });
        },
        fullscreenOpen: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: false,
                },
              },
            },
            title: {
              align: "center",
              text: title + " - " + phase,
            },
          });
        },
        fullscreenClose: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: true,
                },
              },
              title: {
                align: "center",
                text: title,
              },
            },
          });
        },
      },
    },
    title: {
      text: title,
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.village}</b><br/>' +
        "Component Village Counts: <b>{point.y}</b><br/>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.village}</b>: {point.y:.0f}",
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "20%",
        zMin: 0,
        name: "countries",
        data: dataList,
      },
    ],
    exporting: {
      enabled: true,
      sourceWidth: 1000,
      chartOptions: {
        xAxis: [
          {
            max: null,
          },
        ],
        chart: {
          events: {
            load: function () {
              const chart = this;
              const currentDate = new Date().toLocaleDateString("en-GB");
              chart.renderer
                .text(
                  "Date: " + currentDate,
                  chart.plotWidth - 100,
                  chart.plotTop - 10,
                  false
                )
                .css({
                  color: "#000000",
                  fontSize: "12px",
                  textAlign: "right",
                })
                .add();
            },
          },
        },
        title: {
          text: title + " - " + phase,
          align: "center",
          y: 15,
        },
      },
    },
  };
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{
          style: { width: widthVal < 600 && widthVal - 100, height: 350 },
        }}
      />
    </>
  );
};

export default variablepieChart;
