export default {
  ic_logo: require("../screens/images/TNIAMP.png"),
  ic_bgImg: require("../screens/login/img/bgImg.png"),
  ic_bgBottomImg: require("../screens/login/img/bottombg.png"),
  ic_user: require("../screens/images/user.png"),
  ic_geoImg: require("../screens/images/geoImg.png"),
  ic_component: require("../screens/images/component.png"),
  ic_department: require("../screens/images/department.png"),
  ic_tniamplogo: require("../screens/images/TNIAMP Logo.png"),
  ic_geotag_logo: require("../screens/images/geolocation_icon.png"),
  ic_loginpagemap: require("../screens/images/login_logo.png"),
};
