import React from "react";
import "./style.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";

drilldown(Highcharts);
HighchartsExporting(Highcharts);

const BarChart = ({ title, x, categories, barChartData, widthVal }) => {
  const options = {
    chart: {
      type: "bar",
    },
    title: {
      text: title,
      align: "center",
    },

    xAxis: {
      categories: categories,
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: x,
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      shadow: true,
    },
    credits: {
      enabled: false,
    },
    series: barChartData,
  };
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{
          style: { width: widthVal < 600 && widthVal - 100, height: 370 },
        }}
      />
    </>
  );
};

export default BarChart;
