const initialState = {
  isAuthed: false,
  userData: [],
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "isLoggedin":
      return { ...state, isAuthed: !state.isAuthed };
    case "SET_USER_DATA":
      return { ...state, userData: action.data };
    default:
      return state;
  }
};

export default AuthReducer;
