// export const BASE_URL = "https://imzygu1wma.execute-api.ap-south-1.amazonaws.com/dev/api"; //dev

export const BASE_URL = "https://vrlgv2akal.execute-api.ap-south-1.amazonaws.com/prod/api"; //prod

export default {
  users: BASE_URL + "/user",
  departmentUser: BASE_URL + "/user/bydepartment",
  login: BASE_URL + "/user/login",
  lookupApi: BASE_URL + "/lookup",
  userList: BASE_URL + "/user/bydetail",
  tnauReort: BASE_URL + "/report",
  // tnauReort: BASE_URL + "/report",
  subBasin: BASE_URL + "/district/bysubbasin",
  MessagePort: BASE_URL + "/message/report",
  sendMsg: BASE_URL + "/message",
  sendMsg1: BASE_URL + "/message/sendsmsmessage?",
  dashboardUserByDept: BASE_URL + "/dashboard/userbydept",
  dashboardUserBySubbasin: BASE_URL + "/dashboard/userbysubbasin",
  dashboardGeoTagByDept: BASE_URL + "/dashboard/geotagbydept",
  dashboardGeoBySubbasin: BASE_URL + "/dashboard/geotagbysubbasin",
  dashboardCount: BASE_URL + "/dashboard/datacount",
  subBasinUserById: BASE_URL + "/get_sub_basin_count",
  addUser: BASE_URL + "/user/insert",
  changePwd: BASE_URL + "/user/updatepassword",
  getImg1: BASE_URL + "/geotag/image1",
  getImg2: BASE_URL + "/geotag/image2",
  GeoTagDashboard: BASE_URL + "/dashboard/geotagbyintervention",
  deleteGeoTag: BASE_URL + "/geotag/delete",
  interventionByuser: BASE_URL + "/dashboard/individual/interventionbyuser",
  interventionbyvillage:
    BASE_URL + "/dashboard/individual/interventionbyvillage",
  beneficary: BASE_URL + "/dashboard/beneficary",
  subbasinComponent: BASE_URL + "/dashboard/subbasin/subcomponents",
  subbasinSubcomponentStages: BASE_URL + "/dashboard/subbasin/stages",
  subbasinbeneficary: BASE_URL + "/dashboard/subbasin/benificarycount",
  subbasinworkprogress: BASE_URL + "/dashboard/getcountbyintervention",
  excelbeneficiary: BASE_URL + "/excel/benificary",
  excelgeotag: BASE_URL + "/excel/geotag",
  adminlist: BASE_URL + "/user/getadminuser",
  workcompletedgraph: BASE_URL + "/dashboard/geotagreportbyintervention",
  targetachievement: BASE_URL + "/dashboard/gettargetandachievement",
  changedepartment: BASE_URL + "/changedeptbyid",
  registerusergraph: BASE_URL + "/dashboard/getreguserbysubbasin",
  sendmail: BASE_URL + "/message/sendemail"
};
