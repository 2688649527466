import {
  Table,
  Breadcrumb,
  Modal,
  Tooltip,
  Spin,
  Button,
  Collapse,
  Input,
  Space,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { saveAs } from "file-saver";
import { Document, Page, Text } from "@react-pdf/renderer";
import { getReportDataList, getImage, deleteGeoTag } from "./apiAction";
// import { TableSerach } from "../component/tableSearch";
import { useLocation } from "react-router-dom";
import { isEmpty, get } from "lodash";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DeleteAlert } from "../../actions/customAction";
import { exportExcel } from "../../actions/exportExcelAction";
import iconImage from "../images/TNIAMP Logo.png"; // Import the icon image
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import {
  TbInfoSquare,
  TbTrash,
  TbEye,
  TbBrightness2,
  TbArrowBarToDown,
  TbFile,
} from "react-icons/tb";
import dateFormat from "dateformat";
import "./index.css";
import { CSVLink } from "react-csv";

import Timeline from "./Timeline";
import Filter from "./filter";
import Highlighter from "react-highlight-words";
import { FaSearch } from "react-icons/fa";

const { Panel } = Collapse;
const doc = new jsPDF();

const UserDetails = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [deleteoption, setDelete] = useState(false);
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    // setSearchText("");
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const TableSerach = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<FaSearch />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FaSearch
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] != null &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      // console.log("visible--->",visible,searchInput)
      if (visible) {
        // searchInput.current && searchInput.current.blur()
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const antIcon = <TbBrightness2 className="spinStyle" />;
  const selectedKeys = [
    "ID",
    "user_name",
    "created_date",
    "phase",
    "sub_basin_name",
    "village_name",
    "component",
    "Stages",
    "Sub_Component",
    "area",
    "block_name",
    "category",
    "district_name",
    "farmer_name",
    "gender",
    "photo_lat",
    "photo_lon",
    "remarks",
    "survey_no",
    "tank_name",
    "user_mobile_number",
  ];
  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      width: "5%",
      sorter: {
        compare: (a, b) => a.ID - b.ID,
      },
    },
    {
      title: "Created By",
      dataIndex: "user_name",
      key: "user_name",
      ...TableSerach("user_name"),
    },
    {
      title: "Date",
      dataIndex: "created_date",
      key: "created_date",
      ...TableSerach("created_date"),
    },
    {
      title: "Phase",
      dataIndex: "phase",
      key: "phase",
      render: (text, record) => {
        if (record.phase == 1) return <span>I</span>;
        if (record.phase == 2) return <span>II</span>;
        if (record.phase == 3) return <span>III</span>;
        else return <span>IV</span>;
      },
      filters: [
        {
          text: "Phase I",

          value: "1",
        },
        {
          text: "Phase II",

          value: "2",
        },
        {
          text: "Phase III",

          value: "3",
        },
        {
          text: "Phase IV",
          value: "4",
        },
      ],
      onFilter: (value, record) => value.includes(record.phase),
    },
    {
      title: "Sub Basin",
      dataIndex: "sub_basin_name",
      key: "sub_basin_name",
      ...TableSerach("sub_basin_name"),
    },
    {
      title: "Village",
      dataIndex: "village_name",
      key: "village_name",
      ...TableSerach("village_name"),
    },
    {
      title: "Component",
      dataIndex: "component",
      key: "component",
      ...TableSerach("component"),
    },
    {
      title: "Sub Component",
      dataIndex: "Sub_Component",
      key: "Sub_Component",
      ...TableSerach("Sub_Component"),
      // render: (c) =>
      // {
      //   if(c == 0 || c== null)
      //   return(<span>{""}</span>)
      //   else
      //   return c.Sub_Component
      // }
    },
    {
      title: "Stages",
      dataIndex: "Stages",
      key: "Stages",
      ...TableSerach("Stages"),
      // render: (c) =>
      // {
      //   if(c == 0)
      //   return(<span>{""}</span>)
      // }
    },
    {
      title: "Details",
      key: "Details",
      width: "10%",
      render: (c) => {
        return (
          <span>
            <Tooltip title="Report Details">
              <TbInfoSquare
                style={{ fontSize: "18px" }}
                className="bgColor"
                onClick={() => detailedInfo(c)}
              />
            </Tooltip>
          </span>
        );
      },
    },
  ];

  const [reportData, setReportData] = useState([]);
  const [isTimelineOen, setIsTimelineOen] = useState(false);
  const [timeLineData, setTimeLineData] = useState([]);
  const [loadingTime, setLoadiingTime] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [detId, setdeptId] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const userInfo = useSelector(
    (state) => get(state, "AuthReducer.userData", []),
    shallowEqual
  );

  const deptCouList = useSelector(
    (state) => get(state, "AppReducer.dataSet", []),
    shallowEqual
  );

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    const enumdept = {
      1: "TNAU",
      2: "Agriculture",
      3: "Horticulture ",
      4: "AED",
      5: "Animal Husbandry",
      6: "WRD",
      7: "Marketing",
      8: "Fisheries",
    };

    const headers =
      reportData[0].dept_id == "6"
        ? [
            "Name",
            "Mobile",
            "CreatedDate",
            "Phase",
            "SubBasin",
            "District",
            "Block",
            "Village",
            "Component",
            "SubComponent",
            "Stage",
            "SubStages",
          ]
        : [
            "Name",
            "Mobile",
            "CreatedDate",
            "Phase",
            "SubBasin",
            "District",
            "Block",
            "Village",
            "Component",
            "SubComponent",
            "Stage",
          ];
    console.log("reportData--->", reportData);

    const data =
      reportData[0].dept_id == "6"
        ? reportData.map((item) => ({
            UserName: item.user_name || "",
            Mobile: item.user_mobile_number
              ? item.user_mobile_number
              : item.mobile_number
              ? item.mobile_number
              : item.mobile,
            CreatedDate: item.created_date,
            Phase: item.phase,
            SubBasin: item.sub_basin_name,
            District: item.district_name,
            Block: item.block_name ? item.block_name : item.block,
            Village: item.village_name,
            Component: item.component,
            SubComponent: item.Sub_Component,
            Stage: item.Stages,
            SubStages: item.sub_stages,
          }))
        : reportData.map((item) => ({
            UserName: item.user_name || "",
            Mobile: item.user_mobile_number
              ? item.user_mobile_number
              : item.mobile_number
              ? item.mobile_number
              : item.mobile,
            CreatedDate: item.created_date,
            Phase: item.phase,
            SubBasin: item.sub_basin_name,
            District: item.district_name,
            Block: item.block_name ? item.block_name : item.block,
            Village: item.village_name,
            Component: item.component,
            SubComponent: item.Sub_Component,
            Stage: item.Stages,
          }));

    const count = data.length;

    const startX = 10;
    const startY = 10;
    const textIndent = 140;

    const iconSize = 15;
    doc.addImage(iconImage, "PNG", startX, startY, iconSize, iconSize);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text(
      "TNIAMP - Tamilnadu Irrigated " +
        `${enumdept[reportData[0].dept_id]}` +
        " Modernization Project",
      startX + iconSize + 5,
      startY + iconSize / 2,
      { align: "left" }
    );

    doc.setFontSize(12);
    doc.setTextColor(70);
    doc.text("CONSOLIDATED REPORTS", startX + 90, startY + 15, {
      align: "center",
    });

    doc.setTextColor(30);
    doc.setFontSize(10);

    doc.setFont("helvetica", "bold");
    doc.text("Report Type:", startX + 30, startY + 30);
    doc.text("Line Department:", startX + 30, startY + 40, {
      indent: textIndent,
    });
    doc.text("Subbasin:", startX + 30, startY + 50, { indent: textIndent });
    doc.text("Component:", startX + 30, startY + 60, { indent: textIndent });
    doc.text("Sub Component:", startX + 30, startY + 70, {
      indent: textIndent,
    });
    doc.text("Total Counts:", startX + 30, startY + 80, { indent: textIndent });

    doc.setFont("helvetica", "normal");
    doc.text("Registered users", startX + 70, startY + 30);
    doc.text(`${enumdept[reportData[0].dept_id]}`, startX + 70, startY + 40);
    doc.text("Phase I - All Subbasin", startX + 70, startY + 50);
    doc.text(
      reportData.filter((comp) => comp.component == reportData[0].component)
        .length == reportData.length
        ? reportData[0].component
        : "All components",
      startX + 70,
      startY + 60
    );
    doc.text(
      reportData.filter(
        (comp) => comp.Sub_Component == reportData[0].Sub_Component
      ).length == reportData.length
        ? reportData[0].Sub_Component
        : "All Subcomponents",
      startX + 70,
      startY + 70
    );
    doc.text(`${count}`, startX + 70, startY + 80);

    const tableStartY = startY + 90;
    const todayDate =
      String(new Date().getDate()).padStart(2, 0) +
      "-" +
      String(new Date().getMonth() + 1).padStart(2, 0) +
      "-" +
      new Date().getFullYear();
    doc.autoTable({
      startY: tableStartY,
      head: [headers],
      body: data.map(Object.values),
      theme: "plain",
      columnStyles:
        reportData[0].dept_id == "6"
          ? {
              0: { cellWidth: 16 },
              1: { cellWidth: 16 },
              2: { cellWidth: 16 },
              3: { cellWidth: 16 },
              4: { cellWidth: 16 },
              5: { cellWidth: 16 },
              6: { cellWidth: 16 },
              7: { cellWidth: 16 },
              8: { cellWidth: 16 },
              9: { cellWidth: 16 },
              10: { cellWidth: 16 },
              11: { cellWidth: 16 },
            }
          : {
              0: { cellWidth: 17 },
              1: { cellWidth: 17 },
              2: { cellWidth: 17 },
              3: { cellWidth: 17 },
              4: { cellWidth: 17 },
              5: { cellWidth: 17 },
              6: { cellWidth: 17 },
              7: { cellWidth: 17 },
              8: { cellWidth: 17 },
              9: { cellWidth: 17 },
              10: { cellWidth: 17 },
            },
      styles: {
        halign: "center",
        valign: "middle",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        fontStyle: "normal",
        fontSize: 10,
      },
    });

    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.text(
        "Date: " + todayDate,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10
      );
    }

    doc.save("Report.pdf");
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const currentLocation = useLocation().pathname;
  useEffect(() => {
    const apiData = {
      land_id: "0",
      created_by: userInfo[0].SERIAL_NO ? userInfo[0].SERIAL_NO : "0",
      phase_id: "0",
      sub_basin_id:
        !isEmpty(userInfo) &&
        userInfo[0].ID != null &&
        userInfo[0].user_type == 3
          ? userInfo[0].ID //SUB_BASIN_ID  //ID used
          : "0",
      district_id: "0",
      block_id: "0",
      village_id: "0",
      components_id: "0",
      sub_components_id: "0",
      stages_id: "0",
      off_set: 0,
      fromdate: "2008-01-01",
      todate: dateFormat(new Date(), "yyyy-mm-dd"),
    };
    setReportData([]);
    switch (currentLocation) {
      case "/TNAU":
        setdeptId(1);
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          currentLocation,
          "/tnau",
          // userInfo[0].SERIAL_NO ? deptCouList : deptCouList[6]?.count
          deptCouList.length > 0 ? deptCouList[6]?.count : deptCouList
        );
        break;
      case "/agri":
        setdeptId(2);
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          currentLocation,
          "/agri",
          deptCouList.length > 0 ? deptCouList[1]?.count : deptCouList
        );
        break;

      case "/Horticulture":
        setdeptId(3);
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          currentLocation,
          "/horticulture",
          deptCouList.length > 0 ? deptCouList[4]?.count : deptCouList
        );
        break;

      case "/AED":
        setdeptId(4);
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          currentLocation,
          "/tnaed",
          deptCouList.length > 0 ? deptCouList[0]?.count : deptCouList
        );
        break;

      case "/AnimalHusbandry":
        setdeptId(5);
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          currentLocation,
          "/animalhusbandary",
          deptCouList.length > 0 ? deptCouList[2]?.count : deptCouList
        );
        break;

      case "/WRD":
        setdeptId(6);
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          currentLocation,
          "/wrd",
          deptCouList.length > 0 ? deptCouList[7]?.count : deptCouList
        );
        break;
      case "/Marketing":
        setdeptId(7);
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          currentLocation,
          "/marketing",
          deptCouList.length > 0 ? deptCouList[5]?.count : deptCouList
        );
        break;

      case "/Fisheries":
        setdeptId(8);
        getReportDataList(
          setReportData,
          apiData,
          setLoadiingTime,
          currentLocation,
          "/fisheries",
          deptCouList.length > 0 ? deptCouList[3]?.count : deptCouList
        );
        break;

      default:
    }
  }, [currentLocation, deleteoption]);

  const detailedInfo = (data) => {
    setImg1("");
    setImg2("");
    const Id = data.ID;
    const dept = data.dept_id;
    setIsTimelineOen(true);
    setTimeLineData(data);
    getImage(setImg1, setImg2, Id, dept);
  };

  const handleCancel = () => {
    setIsTimelineOen(false);
  };

  return (
    <>
      <Spin
        tip="Fetching data ..."
        spinning={loadingTime}
        size="large"
        indicator={antIcon}
        style={{ color: "#673ab7", fontWeight: "bold" }}
      >
        <Breadcrumb
          separator="/"
          style={{ padding: "20px", fontSize: "large", fontWeight: "lighter" }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            {currentLocation === "/agri"
              ? "Agriculture"
              : currentLocation === "/AnimalHusbandry"
              ? "Animal Husbandry"
              : currentLocation.replace("/", "")}{" "}
            Report
          </Breadcrumb.Item>
        </Breadcrumb>
        <Collapse style={{ margin: "20px" }}>
          <Panel header="Report Filter" key="1">
            <Filter
              reportType={currentLocation}
              setReportData={setReportData}
              setLoadiingTime={setLoadiingTime}
              width={"15%"}
            />
          </Panel>
        </Collapse>

        <Button
          style={{ float: "right", margin: "5px", marginRight: "30px" }}
          disabled={reportData.length == 0 ? true : false}
          onClick={() => {
            exportExcel(reportData, detId);
          }}
        >
          <Tooltip title="Download Excel">
            <TbArrowBarToDown />
          </Tooltip>
        </Button>

        <Button
          style={{ float: "right", margin: "5px", marginRight: "5px" }}
          disabled={reportData.length == 0 ? true : false}
          onClick={handleDownloadPDF}
        >
          <Tooltip title="Download PDF File">
            <TbFile />
          </Tooltip>
        </Button>

        {!isEmpty(userInfo) && userInfo[0].line_dept === 0 && (
          <Button
            style={{ float: "right", margin: "5px", marginRight: "5px" }}
            disabled={!isEmpty(selectedRowKeys) ? false : true}
          >
            <Tooltip title="Delete">
              {!isEmpty(selectedRowKeys) ? (
                <div>
                  <TbTrash onClick={() => setDeleteShow(true)} />
                  <DeleteAlert
                    open={deleteShow}
                    onOk={() =>
                      deleteGeoTag(
                        detId,
                        selectedRowKeys,
                        deleteoption,
                        setDelete,
                        () => setDeleteShow(false)
                      )
                    }
                    onCancel={() => setDeleteShow(false)}
                  />
                </div>
              ) : (
                <TbTrash />
              )}
            </Tooltip>
          </Button>
        )}
        {reportData.length > 0 && (
          <Table
            style={{ margin: "20px" }}
            size={"small"}
            columns={columns}
            rowKey={(record) => record.ID}
            rowSelection={rowSelection}
            dataSource={reportData}
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            scroll={{ x: "80vw" }}
          />
        )}
        <Modal
          title={
            <center>
              {currentLocation === "/agri"
                ? "Agriculture Report"
                : currentLocation === "/AnimalHusbandry"
                ? "Animal Husbandry Report"
                : currentLocation.replace("/", "") + " Report"}
            </center>
          }
          style={{ top: "30px" }}
          width={1000}
          open={isTimelineOen}
          footer={null}
          onCancel={handleCancel}
        >
          <Timeline timeLineData={timeLineData} img1={img1} img2={img2} />
        </Modal>
      </Spin>
    </>
  );
};

export default UserDetails;
