import React from "react";
import Navigation from "./navigator/index";

function App() {
  const required = true;
  const disabled = false;
  return (
    <div disabled={disabled} required={required}>
      <Navigation />
    </div>
  );
}

export default App;
