import { Col, Row, Timeline, Image, Button, Tooltip } from "antd";
import { useRef, useState } from "react";
import { TbArrowBarToDown } from "react-icons/tb";
const TimeLine = ({ timeLineData, img1, img2 }) => {
  var timelinedata_array = [
    "user_name",
    "user_mobile_number",
    "created_date",
    "phase",
    "sub_basin_name",
    "village_name",
    "block_name",
    "district_name",
    "component",
    "Sub_Component",
    "Stages",
    "farmer_name",
    "gender",
    "category",
    "survey_no",
    "area",
    "variety",
    "yield",
    "remarks",
    "tank_name",
    "venue",
    "others_male_no",
    "sc_st_male_no",
    "others_female_no",
    "sc_st_female_no",
    "nodal_officer",
    "Name_of_the_tank",
    "Water_spread_area_EWSAHa",
    "species_stoked",
    "lessee",
    "Quanity_of_fish_harvested_Kg",
    "Feed_Quantity",
    "harvested",
    "Farm_pond_constructed_by",
    "no_of_seed",
    "survey_no",
    "beneficiary",
    "beneficiary_name",
    "no_of_village",
    "nooffigs",
    "nof_mem",
    "crop",
    "season",
    "Name_of_the_consulting_agency",
    "Location_of_the_Consulting_agency",
    "name_of_ceo",
    "Email_Id",
    "Infrastructure_available",
    "Category_of_Godown",
    "name",
    "Capacity_Mts",
    "Date_of_Completion",
    "Quantity_stored_Mts",
    "Date_of_Completion",
    // "Quantity_stored_Mts",
    "No_of_beneficieries",
    "Government_share",
    "Contribution",
    "Name_of_training",
    "Duration_of_training",
    "Number_of_traineesm",
    "Date_From",
    "Date_To",
    "Name_HQandFPC",
    "Duration_of_Visit",
    "Numbers",
    "Country_and_State_Name",
    "Places_visited",
    "stage",
    "ls_point",
    "sluice",
    "wua_name",
    "nof_mem",
    "photo_lat",
    "photo_lon",
    "no_of_farmers",
    "no_of_calves",
    "no_of_cows",
    "Area",
    "mobile_number",
    "block",
    "sub_stages",
    "mobile",
    "Remarks",
    "fish_culture",
    "intervention_type",
    "other_intervention",
    "date",
    "volume_of_water",
    "no_of_seed_stock",
    "used_feed_quantaty",
    "days_of_culture",
    "revenue_genrator",
    "no_reared",
    "fpc_name",
    "ID",
    "Categorys",
    "serial_no",
    // "village",
    "project_name",
    "project_cost",
    "release_date",
    "tank_name",
    "group_name",
    "date_account_open",
    "date_revolving_fund_release",
    "no_of_stocks_req",
    "participatant_no",
    "tr_exp_categoty",
    "tr_exp_female_no",
    "tr_exp_male_no",
    "Mobile_Number",
    "component_name",
    // "incorp_date",
    "incorporation_date",
    "incorporation_num",
    "total_amount",
    "date_of_release_of_grant",
    "nodel_mobile_number",
    "name_of_the_village",
    "farmer_mobile_number",
    "Sluice_Number",
    "length_m",
    "Name_of_WUA",
    "No_of_Members",
    "seed_area",
  ];
  var enum_timelinelabel = {
    user_name: "User Name",
    user_mobile_number: "Mobile Number",
    created_date: "Created Date",
    phase: "Phases",
    sub_basin_name: "Sub Basin",
    village_name: "Village",
    block_name: "Block",
    district_name: "District",
    component: "Components",
    Sub_Component: "Sub Components",
    Stages: "Stages",
    farmer_name: "Farmer Name",
    gender: "Gender",
    category: "Category",
    survey_no: "Survey NO",
    area: "Area(HA)",
    tank_name: "Nearby Tank Name",
    venue: "Venue",
    others_male_no: "Male others No.",
    sc_st_male_no: "Male SC/ST No.",
    others_female_no: "Female others No.",
    sc_st_female_no: "Female SC/ST No.",
    nodal_officer: "Nodal Officer",
    Name_of_the_tank: "Name of the Tank",
    Water_spread_area_EWSAHa: "Water Spread Area",
    species_stoked: "Species Stocked",
    lessee: "Lessee",
    Quanity_of_fish_harvested_Kg: "Quanity of Fish harvested",
    Feed_Quantity: "Feed Quantity",
    harvested: "Harvested",
    Farm_pond_constructed_by: "Farm Pond Constructed By",
    no_of_seed: "No.of.Seed stocked",
    survey_no: "Survey No",
    beneficiary: "Beneficiary",
    beneficiary_name: "Beneficiary Name",
    no_of_village: "No.of Villages",
    nooffigs: "No.of FIGs",
    nof_mem: "No. of Members",
    crop: "Crop",
    season: "Season",
    Name_of_the_consulting_agency: " Consulting Agency of Name",
    Location_of_the_Consulting_agency: "Consulting Agency Location", // "Location of the Consulting Agency"
    name_of_ceo: "Name of the CEO",
    Email_Id: "Email Id",
    Infrastructure_available: "Infrastructure Available",
    Category_of_Godown: "Category of Godown",
    name: "Name",
    Capacity_Mts: "Capacity",
    Date_of_Completion: "Date of Completion",
    Quantity_stored_Mts: "Quantity Stored",
    No_of_beneficieries: "No. of beneficieries",
    Government_share: "Government Share",
    Contribution: "Contribution",
    Name_of_training: "Name of training",
    Duration_of_training: "Duration of Training",
    Number_of_traineesm: "Number of Trainees",
    Date_From: "Date(From)",
    Date_To: "Date(To)",
    Name_HQandFPC: "Name HQandFPC",
    Duration_of_Visit: "Duration of Visit",
    Numbers: "Numbers",
    Country_and_State_Name: "Country & State Name",
    Places_visited: "Places Visited",
    stage: "Task",
    ls_point: "LS Point Number",
    sluice: "Sluice Number",
    wua_name: "Name of WUA",
    nof_mem: "No of Members",
    remarks: "Remarks",
    photo_lat: "Latitude",
    photo_lon: "Longitude",
    variety: "Variety",
    yield: "Yield (kg/ha)",
    no_of_farmers: "No of Farmers",
    no_of_calves: "No of Calves",
    no_of_cows: "No.of Cows",
    Area: "Area(ha)",
    mobile_number: "Mobile Number",
    block: "block",
    sub_stages: "Sub Stages",
    mobile: "Mobile Number",
    Remarks: "Remarks",
    fish_culture: "Fish Culture",
    intervention_type: "Intervention Type",
    other_intervention: "Other Intervention",
    date: "Date",
    volume_of_water:"Volume of water",
    no_of_seed_stock:"No Of Seed Stock",
    used_feed_quantaty:"Used Feed Quantaty",
    days_of_culture: "Days Of Culture",
    revenue_genrator: "Revenue Genrator",
    no_reared: "No Readed",
    fpc_name: "FPC Name",
    ID: "ID",
    Categorys: "Category",
    serial_no: "Serial No",
    // village: "Village",
    project_name:"Project Name",
    project_cost: "Project Cost",
    release_date: "Release Date",
    tank_name: "Tank Name",
    group_name: "Group Name",
    date_account_open: "Date Account Open",
    date_revolving_fund_release: "Date Revolving Fund Release",
    participatant_no: "Participatant No",
    tr_exp_categoty: "Tr Exp Categoty",
    tr_exp_female_no: "Tr Exp Female No.", 
    tr_exp_male_no: "Tr Exp Male No.",
    Mobile_Number: "Mobile Number",
    component_name: "Component Name",
    // incorp_date: "Incorporation Date",
    incorporation_date: "Incorporation Date",
    incorporation_num: "Incorporation number",
    total_amount: "Total grant amount",
    date_of_release_of_grant: "Date of release of grant",
    nodel_mobile_number: "Nodel Officer Mobile No.",
    name_of_the_village: "Beneficiary's village",
    farmer_mobile_number: "Farmer mobile number",
    Sluice_Number: "Sluice Number",
    length_m: "Length M",
    Name_of_WUA: "Name of WUA",
    No_of_Members: "No_of_Members",
    seed_area: "Seed farm area raised(HA)",
  };
  const canvasRef = useRef(null);
  var timeline_items = [];
  Object.entries(timeLineData).map((item) => {
    if (timelinedata_array.includes(item[0])) {
      if (
        // item[0] == "variety" ||
        // item[0] == "yield" ||
        (item[1] !== null && item[1] !== "" && item[1] != 0 && item[1] != "null")
      ) {
        var sa = {
          label: (
            <div style={{ fontWeight: "bold" }}>
              {enum_timelinelabel[item[0]]}
            </div>
          ),
          children:
            item[0] == "phase"
              ? item[1] == "1"
                ? "I"
                : item[1] == "2"
                ? "II"
                : item[1] == "3"
                ? "III"
                : "IV"
              // : 
              // item[0] == "variety" || item[0] == "yield"
              // ? 
              // item[1] == 0 || item[1] == "null" || item[1] == null
              //   ? "N/A"
              //   : item[1]
              : item[1]
              ? item[1]
              : "null",
        };
        timeline_items.push(sa);
      }
    }
  });

  const handlePhotoDownload = (src) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const image = document.createElement("img");

    image.crossOrigin = "Anonymous";

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);
      const id = "ID: " + timeLineData.ID;
      const latText = "Lat: " + timeLineData.photo_lat;
      const lonText = "Long: " + timeLineData.photo_lon;

      ctx.font = "1em Arial";
      ctx.fillStyle = "#ffffff";
      const totalWidth =
        Math.max(
          ctx.measureText(id).width,
          ctx.measureText(latText).width,
          ctx.measureText(lonText).width
        ) + 30;

      const rectX = 0;
      const rectY = canvas.height - 60;

      ctx.fillRect(rectX, rectY, totalWidth, 60);

      ctx.fillStyle = "#000000";
      ctx.textAlign = "left";
      ctx.textBaseline = "middle";

      const textX = 10;
      const idY = canvas.height - 50;
      const latY = canvas.height - 30;
      const lonY = canvas.height - 10;

      ctx.fillText(id, textX, idY);
      ctx.fillText(latText, textX, latY);
      ctx.fillText(lonText, textX, lonY);

      const dataUrl = canvas.toDataURL();

      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "photo.png";
      link.click();
    };
    image.src = src;
  };

  return (
    <>
      <Row 
      style={{ marginTop: "2em" }}
      >
        <Col md={6}>
          <div style={{ marginLeft: "30px" }}>
            <div style={{ fontWeight: "bold" }}>
              Image 1
              <canvas ref={canvasRef} style={{ display: "none" }} />
              <Button
                style={{ float: "right", margin: "5px", marginRight: "30px" }}
                onClick={() =>
                  handlePhotoDownload("data:image/png;base64, " + img1)
                }
              >
                <Tooltip title="Download">
                  <TbArrowBarToDown />
                </Tooltip>
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Image
                src={"data:image/png;base64, " + img1}
                width={180}
                height={160}
              />
            </div>
            <div style={{ fontWeight: "bold", marginTop: "10px" }}>
              Image 2
              <Button
                style={{ float: "right", margin: "5px", marginRight: "30px" }}
                onClick={() =>
                  handlePhotoDownload("data:image/png;base64, " + img2)
                }
              >
                <Tooltip title="Download">
                  <TbArrowBarToDown />
                </Tooltip>
              </Button>
            </div>

            <div style={{ marginTop: "10px" }}>
              <Image
                src={"data:image/png;base64, " + img2}
                width={180}
                height={160}
              />
            </div>
          </div>
        </Col>
        
        {timeline_items.map((item, index) =>{
            return(
              <>
              {(index==2 || index==5) && <Col md={6}></Col>}
            <Col md={9}>
              <Timeline
                mode={"left"}
                items={timeline_items.slice(
                  index == 0 ? 0 : index * 10,
                  index * 10 + 10
                )}
              />
              </Col>
              </>)
        }
               ) 
               } 
        {/* //   ) : (
        //     <>
        //     {console.log("index--->",index)}
        //     {index == 20 && <Col md={6}></Col>}
        //     <Col md={9} offset={6}>
        //       <Timeline
        //         mode={"left"}
        //         items={timeline_items.slice(
        //           index == 0 ? 0 : index * 10,
        //           index * 10 + 10
        //         )}
        //       />
        //     </Col>
        //     </>
        //   )
        // )} */}
        
      </Row>
    </>
  );
};
export default TimeLine;
