import React from "react";
import "./style.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";

drilldown(Highcharts);
HighchartsExporting(Highcharts);

const ColumnChartBeneficary = ({ title, dataList, widthVal, phase }) => {
  var options = {
    chart: {
      type: "column",
      events: {
        beforePrint: function () {
          this.setSize(1000, 370, false);
          this.originalTitle = this.options.title.text;

          this.setTitle({ text: this.options.title.text + " - " + phase });
        },
        afterPrint: function () {
          this.setSize(widthVal < 600 && widthVal - 100, 370, false);
          this.setTitle({ text: this.originalTitle });
        },
        fullscreenOpen: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: false,
                },
              },
            },
            title: {
              text: title + " - " + phase,
            },
          });
        },
        fullscreenClose: function () {
          this.update({
            exporting: {
              buttons: {
                contextButton: {
                  enabled: true,
                },
              },
            },
            title: {
              text: title,
            },
          });
        },
      },
    },

    title: {
      text: title,
    },

    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
    },

    xAxis: {
      categories: ["Male", "Female"],
      labels: {
        x: -10,
      },
    },

    yAxis: {
      title: {
        text: "No. of Beneficiaries",
      },
    },

    series: dataList,

    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          style: {
            textDecoration: "underline",
            color: "rgb(0 51 153)",
          },
          format: "{point.y}",
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    exporting: {
      enabled: true,
      sourceWidth: 1000,
      chartOptions: {
        xAxis: [
          {
            max: null,
          },
        ],
        chart: {
          events: {
            load: function () {
              const chart = this;
              const currentDate = new Date().toLocaleDateString("en-GB");
              chart.renderer
                .text(
                  "Date: " + currentDate,
                  chart.plotWidth - 45,
                  chart.plotTop - 10,
                  false
                )
                .css({
                  color: "#000000",
                  fontSize: "12px",
                  textAlign: "right",
                })
                .add();
            },
          },
        },
        title: {
          text: title + " - " + phase,
          align: "center",
          y: 15,
        },
      },
    },
  };
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{
          style: { width: widthVal < 600 && widthVal - 100, height: 350 },
        }}
        options={options}
      />
    </>
  );
};

export default ColumnChartBeneficary;
