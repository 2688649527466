import Chart from "react-apexcharts";

import React, { Component } from "react";
import { isEmpty } from "ol/extent";

class domContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    var GraphData = {
      series: [
        {
          name: "Volume",
          data: this.props.Volume,
        },
        {
          name: "Area",
          data: this.props.Area,
        },
      ],
      options: {
        chart: {
          type: "area",
          height: "650rem",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        legend: {
          show: true,

          colors: "white",
        },
        title: {
          text: this.props.Tankname + " - " + this.props.Year,
          align: "center",
          style: {
            fontSize: "14px",
          },
        },
        xaxis: {
          type: "string",
          labels: {
            style: {
              colors: "white",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            style: {
              colors: "white",
            },
            offsetY: -7,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          x: {
            format: "yyyy",
          },
          fixed: {
            enabled: false,
            position: "topRight",
          },
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30,
            },
          },
          padding: {
            left: 20,
          },
        },
      },
    };

    return (
      <Chart
        options={GraphData.options}
        series={GraphData.series}
        type="area"
        height={230}
      />
    );
  }
}

export default domContainer;
