import React, { useEffect, useState } from "react";

import {
  items,
  itemsTNAU,
  itemshorti,
  itemsAED,
  itemsWRD,
  itemsMarketing,
  itemsFisheries,
  itemsHusb,
  itemsagri,
  itemsTNAU1,
  itemshorti1,
  itemsAED1,
  itemsWRD1,
  itemsMarketing1,
  itemsFisheries1,
  itemsHusb1,
  itemsagri1,
} from "./menuItems";
import { Image, Layout, Menu, Row, Col } from "antd";
import { get, isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { setLogout } from "../../../actions/apiAction";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineUserCircle } from "react-icons/hi";

import images from "../../../constants/image";
import "./style.css";
const { Sider } = Layout;

const SidebarMenu = ({ collapsedMenu, setCollapsed }) => {
  const dispatch = useDispatch();

  const [menuitemList, setMenuItemList] = useState("");

  const currentLocation = useLocation().pathname;

  const onClick = (e) => {
    if (e.key === "/logout") {
      dispatch(setLogout());
    }
  };

  let userInfo = useSelector(
    (state) => get(state, "AuthReducer.userData", []),
    shallowEqual
  );
  let enum_dept = {
    TNAU: 1,

    Agriculture: 2,

    Horticulture: 3,

    AED: 4,

    "Animal Husbandry": 5,

    WRD: 6,

    Marketing: 7,

    Fisheries: 8,
  };

  useEffect(() => {
    if (userInfo.length > 1 && userInfo[0].user_type == 4 
      && userInfo[0].LINE_DEPT != 9
      ) {
      userInfo = userInfo.filter((data) => {
        return data.LINE_DEPT == enum_dept[userInfo[0].department];
      });
    }
    if (
      userInfo.length != 0 &&
      userInfo[0].line_dept &&
      userInfo[0].user_type == 4
    ) {
      switch (userInfo[0].line_dept) {
        case 1:
          setMenuItemList(itemsTNAU);
          break;
        case 2:
          setMenuItemList(itemsagri);
          break;
        case 3:
          setMenuItemList(itemshorti);
          break;
        case 4:
          setMenuItemList(itemsAED);
          break;
        case 5:
          setMenuItemList(itemsHusb);
          break;
        case 6:
          setMenuItemList(itemsWRD);
          break;
        case 7:
          setMenuItemList(itemsMarketing);
          break;
        case 8:
          setMenuItemList(itemsFisheries);
          break;
        case 9:
          setMenuItemList(itemsFisheries);
          break;
        default:
      }
    } else {
      switch (userInfo[0].line_dept) {
        case 1:
          setMenuItemList(itemsTNAU1);
          break;
        case 2:
          setMenuItemList(itemsagri1);
          break;
        case 3:
          setMenuItemList(itemshorti1);
          break;
        case 4:
          setMenuItemList(itemsAED1);
          break;
        case 5:
          setMenuItemList(itemsHusb1);
          break;
        case 6:
          setMenuItemList(itemsWRD1);
          break;
        case 7:
          setMenuItemList(itemsMarketing1);
          break;
        case 8:
          setMenuItemList(itemsFisheries1);
          break;
        case 9:
          setMenuItemList(itemsFisheries1);
          break;
        default:
          setMenuItemList(items);
      }
    }
  }, [userInfo]);

  return (
    <>
      <Sider
        // subMenuOpenDelay={5}
        // subMenuCloseDelay={5}
        submenuopendelay={5}
        submenuclosedelay={5}
        trigger={null}
        id={"customDrawer"}
        collapsible
        collapsed={!collapsedMenu}
        collapsedWidth="0"
        className="sidebarStyle"
      >
        <div className="sideTitle">
          <Image src={images.ic_tniamplogo} width={"40px"} preview={false} />
          <span
            className="applicationName"
            style={{ fontFamily: "sans-serif" }}
          >
            TNIAMP
          </span>
        </div>
        <div className="sidebarRole">
          <div>
            <Row style={{ fontFamily: "sans-serif" }}>
              <Col md={4}>
                <div>
                  <HiOutlineUserCircle
                    size={"45px"}
                    preview={false}
                    style={{ marginLeft: "20px" }}
                  />
                </div>
              </Col>
              <Col md={20}>
                <div style={{ marginLeft: "30px" }}>
                  <div className="userName">
                    <center>
                      {userInfo.length != 0 && userInfo[0].user_type !== "4"
                        ? userInfo[0].user_name
                        : !isEmpty(userInfo) && userInfo[0].user_type === "4"
                          ? userInfo[0].NAME
                          : ""}
                    </center>
                  </div>
                  <div className="roleType">
                    <center>
                      {userInfo.length != 0 && userInfo[0].user_type === "1"
                        ? "Admin"
                        : userInfo[0].user_type === "2"
                          ? "Department Admin"
                          : userInfo[0].user_type === "3"
                            ? "Sub Basin Admin"
                            : "Individual"}
                    </center>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Menu
          mode="inline"
          onClick={onClick}
          selectedKeys={[currentLocation]}
          className="menuStyle"
          items={menuitemList}
        ></Menu>
      </Sider>
    </>
  );
};

export default SidebarMenu;
