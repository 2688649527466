import axios from "axios";
import api from "../../constants/api";

export function getDepartment(setDeartmentList) {
     return axios
      .get(api.lookupApi, { params: { type: "department" } },)
      .then(({ data }) => {
        setDeartmentList(data)
      })
      .catch((error) => {
        // message.error('Something went wrong please try again'); 
      });
  
}

