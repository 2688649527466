import { Table, Breadcrumb, Spin, Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { getMessageReort } from "./apiAction";
import { TableSerach } from "../component/tableSearch";
import moment from "moment";
import {
  TbInfoSquare,
  TbTrash,
  TbEye,
  TbBrightness2,
  TbArrowBarToDown,
} from "react-icons/tb";
import { userLists } from "../../actions/exportExcelAction";

const columns = [
  {
    title: "Mobile User",
    dataIndex: "user_name",
    ...TableSerach("user_name"),
  },
  {
    title: "Message",
    dataIndex: "message",
    ...TableSerach("message"),
  },
  {
    title: "Created date and time",
    dataIndex: "created_date",
    ...TableSerach("created_date"),
  },
  {
    title: "Acknowldged date and time",
    dataIndex: "ack_time",
    ...TableSerach("ack_time"),
  },
  {
    title: "Created By",
    dataIndex: "created_by",
    ...TableSerach("created_by"),
  },
];

const antIcon = <TbBrightness2 className="spinStyle" />;

const MessageList = () => {
  const [MessageList, setMessageList] = useState([]);
  const [loadingTime, setLoadiingTime] = useState(false);

  useEffect(() => {
    getMessageReort(setMessageList, setLoadiingTime);
  }, []);
  return (
    <>
      <Spin
        tip="Fetching data ..."
        spinning={loadingTime}
        size="large"
        indicator={antIcon}
        style={{ color: "#673ab7", fontWeight: "bold" }}
      >
        <Breadcrumb
          separator="/"
          style={{ padding: "20px", fontSize: "large", fontWeight: "lighter" }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Message Report</Breadcrumb.Item>
        </Breadcrumb>
        <Button
          style={{ float: "right", margin: "5px", marginRight: "30px" }}
          disabled={MessageList.length == 0 ? true : false}
          onClick={() => {
            userLists(MessageList, "Message");
          }}
        >
          <Tooltip title="Download Excel">
            <TbArrowBarToDown />
          </Tooltip>
        </Button>

        <Table
          style={{ margin: "20px" }}
          size={"small"}
          columns={columns}
          scroll={{ x: "80vw" }}
          dataSource={MessageList}
          pagination={{ showSizeChanger: true, showQuickJumper: true }}
        />
      </Spin>
    </>
  );
};

export default MessageList;
