import { Link, useLocation } from "react-router-dom";

import {
  FaRegPaperPlane,
  FaRegUser,
  FaThLarge,
  FaRegShareSquare,
  FaRegFile,
  FaClipboardList,
  FaHighlighter,
  FaShareSquare,
  FaRegFileAlt,
  FaRegCommentDots,
  FaRegClone,
  FaRegAddressCard,
} from "react-icons/fa";

import { CgExtension } from "react-icons/cg";
import {
  HiOutlinePencil,
  HiOutlineNewspaper,
  HiOutlineDatabase,
  HiOutlineCollection,
  HiOutlineViewGridAdd,
} from "react-icons/hi";
import { BiPaperPlane, BiMessageSquareDots, BiUserPlus } from "react-icons/bi";
import { FiUserPlus, FiPaperclip, FiSidebar, FiServer } from "react-icons/fi";
import { HiOutlineMapPin } from "react-icons/hi2";

export const items = [
  getItem(<Link to={"/"}>Dashboard</Link>, "/", <HiOutlineViewGridAdd />, ""),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
   
    getItem(<Link to={"/AED"}>AED</Link>, "/AED", <FaRegClone />),
    getItem(<Link to={"/agri"}>Agriculture</Link>, "/agri", <FiSidebar />),
    getItem(
      <Link to={"/AnimalHusbandry"}>Animal Husbandry</Link>,
      "/AnimalHusbandry",
      <FaRegFile />
    ),
    getItem(
      <Link to={"/Fisheries"}>Fisheries</Link>,
      "/Fisheries",
      <HiOutlineCollection />
    ),
    getItem(
      <Link to={"/Horticulture"}>Horticulture</Link>,
      "/Horticulture",
      <FiServer />
    ),
    getItem(
      <Link to={"/Marketing"}>Marketing</Link>,
      "/Marketing",
      <HiOutlineDatabase />
    ),
    getItem(<Link to={"/TNAU"}>TNAU</Link>, "/TNAU", <FiPaperclip />),
    getItem(<Link to={"/WRD"}>WRD</Link>, "/WRD", <HiOutlineNewspaper />),
  ]),
  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),

  getItem(
    <Link to={"/water"}>Surface Water Analysis</Link>,
    "/water",
    <HiOutlineMapPin />,
    ""
  ),

  getItem("Message", "message", <FaRegCommentDots />, [
    getItem(
      <Link to={"/message"}>Send Message</Link>,
      "/message",
      <FaRegPaperPlane />
    ),
    getItem(
      <Link to={"/MessageReport"}>Message Report</Link>,
      "/MessageReport",
      <BiMessageSquareDots />
    ),
  ]),
  getItem("User", "user", <FaRegUser />, [
    getItem(<Link to={"/user"}>User</Link>, "/user", <FiUserPlus />),
    getItem(
      <Link to={"/userList"}>Individual User</Link>,
      "/userList",
      <FaRegAddressCard />
    ),
    getItem(
      <Link to={"/adminlist"}>Admin User</Link>,
      "/adminlist",
      <FaRegAddressCard />
    ),
  ]),
  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemshorti1 = [
  getItem(
    <Link to={"/"}>Dashboard</Link>,
    "/dashboard",
    <HiOutlineViewGridAdd />,
    ""
  ),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(
      <Link to={"/Horticulture"}>Horticulture</Link>,
      "/Horticulture",
      <FaRegClone />
    ),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),
  getItem(
    <Link to={"/water"}>Surface Water Analysis</Link>,
    "/water",
    <HiOutlineMapPin />,
    ""
  ),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemshorti = [
  getItem(
    <Link to={"/dashboard"}>Dashboard</Link>,
    "/dashboard",
    <HiOutlineViewGridAdd />,
    ""
  ),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(
      <Link to={"/Horticulture"}>Horticulture</Link>,
      "/Horticulture",
      <FaRegClone />
    ),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsAED1 = [
  getItem(<Link to={"/"}>Dashboard</Link>, "/", <HiOutlineViewGridAdd />, ""),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(<Link to={"/AED"}>AED</Link>, "/AED", <FaRegClone />),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),
  getItem(
    <Link to={"/water"}>Surface Water Analysis</Link>,
    "/water",
    <HiOutlineMapPin />,
    ""
  ),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsAED = [
  getItem(
    <Link to={"/dashboard"}>Dashboard</Link>,
    "/",
    <HiOutlineViewGridAdd />,
    ""
  ),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(<Link to={"/AED"}>AED</Link>, "/AED", <FaRegClone />),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsWRD1 = [
  getItem(<Link to={"/"}>Dashboard</Link>, "/", <HiOutlineViewGridAdd />, ""),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(<Link to={"/WRD"}>WRD</Link>, "/WRD", <FaRegClone />),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),
  getItem(
    <Link to={"/water"}>Surface Water Analysis</Link>,
    "/water",
    <HiOutlineMapPin />,
    ""
  ),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsWRD = [
  getItem(
    <Link to={"/dashboard"}>Dashboard</Link>,
    "/",
    <HiOutlineViewGridAdd />,
    ""
  ),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(<Link to={"/WRD"}>WRD</Link>, "/WRD", <FaRegClone />),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsMarketing1 = [
  getItem(<Link to={"/"}>Dashboard</Link>, "/", <HiOutlineViewGridAdd />, ""),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(
      <Link to={"/Marketing"}>Marketing</Link>,
      "/Marketing",
      <FaRegClone />
    ),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),
  getItem(
    <Link to={"/water"}>Surface Water Analysis</Link>,
    "/water",
    <HiOutlineMapPin />,
    ""
  ),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];
export const itemsMarketing = [
  getItem(
    <Link to={"/dashboard"}>Dashboard</Link>,
    "/",
    <HiOutlineViewGridAdd />,
    ""
  ),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(
      <Link to={"/Marketing"}>Marketing</Link>,
      "/Marketing",
      <FaRegClone />
    ),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsFisheries1 = [
  getItem(<Link to={"/"}>Dashboard</Link>, "/", <HiOutlineViewGridAdd />, ""),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(
      <Link to={"/Fisheries"}>Fisheries</Link>,
      "/Fisheries",
      <FaRegClone />
    ),
  ]),
  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),
  getItem(
    <Link to={"/water"}>Surface Water Analysis</Link>,
    "/water",
    <HiOutlineMapPin />,
    ""
  ),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsFisheries = [
  getItem(
    <Link to={"/dashboard"}>Dashboard</Link>,
    "/",
    <HiOutlineViewGridAdd />,
    ""
  ),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(
      <Link to={"/Fisheries"}>Fisheries</Link>,
      "/Fisheries",
      <FaRegClone />
    ),
  ]),
  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsHusb1 = [
  getItem(<Link to={"/"}>Dashboard</Link>, "/", <HiOutlineViewGridAdd />, ""),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(
      <Link to={"/AnimalHusbandry"}>Animal Husbandry</Link>,
      "/AnimalHusbandry",
      <FaRegClone />
    ),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),
  getItem(
    <Link to={"/water"}>Surface Water Analysis</Link>,
    "/water",
    <HiOutlineMapPin />,
    ""
  ),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsHusb = [
  getItem(
    <Link to={"/dashboard"}>Dashboard</Link>,
    "/",
    <HiOutlineViewGridAdd />,
    ""
  ),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(
      <Link to={"/AnimalHusbandry"}>Animal Husbandry</Link>,
      "/AnimalHusbandry",
      <FaRegClone />
    ),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsagri1 = [
  getItem(<Link to={"/"}>Dashboard</Link>, "/", <HiOutlineViewGridAdd />, ""),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(<Link to={"/agri"}>Agriculture</Link>, "/agri", <FaRegClone />),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),
  getItem(
    <Link to={"/water"}>Surface Water Analysis</Link>,
    "/water",
    <HiOutlineMapPin />,
    ""
  ),
  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsagri = [
  getItem(
    <Link to={"/dashboard"}>Dashboard</Link>,
    "/",
    <HiOutlineViewGridAdd />,
    ""
  ),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(<Link to={"/agri"}>Agriculture</Link>, "/agri", <FaRegClone />),
  ]),

  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsTNAU = [
  getItem(
    <Link to={"/dashboard"}>Dashboard</Link>,
    "/",
    <HiOutlineViewGridAdd />,
    ""
  ),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(<Link to={"/TNAU"}>TNAU</Link>, "/TNAU", <FaRegClone />),
  ]),
  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

export const itemsTNAU1 = [
  getItem(<Link to={"/"}>Dashboard</Link>, "/", <HiOutlineViewGridAdd />, ""),
  getItem("Reports", "/reports", <FaRegFileAlt />, [
    getItem(<Link to={"/TNAU"}>TNAU</Link>, "/TNAU", <FaRegClone />),
  ]),
  getItem(<Link to={"/geoMap"}>Map</Link>, "/geoMap", <HiOutlineMapPin />, ""),
  getItem(
    <Link to={"/water"}>Surface Water Analysis</Link>,
    "/water",
    <HiOutlineMapPin />,
    ""
  ),

  getItem(
    <Link to={"/changePassword"}>Change password</Link>,
    "/changePassword",
    <HiOutlinePencil />,
    ""
  ),
  getItem(<Link to={"/"}>Log Out</Link>, "/logout", <FaRegShareSquare />, ""),
];

function getItem(label, key, icon, children, path) {
  return {
    key,
    icon,
    children,
    label,
    path,
  };
}
