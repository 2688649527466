const initialState = {
  dataSet: [],
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SUMMARY_DATA":
      return { ...state, dataSet: action.data };
    default:
      return state;
  }
};

export default AppReducer;
